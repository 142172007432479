<template>
  <div>
    <v-tabs right v-model="tab">
      <v-tab key="simulation-tab">{{ $t('simulation.title') }}</v-tab>
      <v-tab key="history-tab" :disabled="currentUser.data.isGeneric">{{ $t('general.misc.history') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item key="simulation-tab" class="pa-3">
        <v-row class="pa-3 align-center">
          <v-autocomplete :items="classList" v-model="className" :label=" $t('general.misc.class')" hide-details
                          return-object clearable @change="selectClass"
                          :filter="Utilities().searchNormalizedClass">
            <template v-slot:selection="{ item }">
              <p class="font-weight-medium mb-0">
                <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
                <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
              </p>
            </template>
            <template v-slot:item="{ item }">
              <p class="font-weight-medium mb-0">
                <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
                <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
              </p>
            </template>
          </v-autocomplete>
          <v-autocomplete :items="getFilteredCharacters()" v-model="character" :label="$t('general.misc.character')"
                          hide-details return-object :disabled="className === null"
                          :filter="Utilities().searchNormalizedCharacter" class="ml-2" @change="selectCharacter"
                          item-text="display">
            <template v-slot:selection="{ item }">
              <p class="font-weight-medium mb-0">
                <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
              </p>
            </template>
            <template v-slot:item="{ item }">
              <p class="font-weight-medium mb-0">
                <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
              </p>
            </template>
          </v-autocomplete>
          <v-btn :disabled="character === null || this.characterDetailsLoading" @click="refreshFromArmory()"
                 class="ml-2 mt-2">
            <v-icon>mdi-refresh</v-icon>
            Armory Import
          </v-btn>
        </v-row>
        <v-row class="pa-3">
          <v-expansion-panels>
            <v-expansion-panel class="secondary lighten-1">
              <v-expansion-panel-header>
                <span>
                  <v-avatar size="25" class="mr-1">
                    <v-img src="../assets/img/external/sc.png"></v-img>
                  </v-avatar>
                  {{ $t('simulation.simc_addon.import') }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea :label="$t('simulation.simc_addon.script')" v-model="simAddonString" rows="10" no-resize
                            class="pa-2" hide-details></v-textarea>
                <v-btn @click="parseSimAddonStringForSimulation" class="mt-1 mr-1 float-end primary">SimulationCraft
                  Import
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="secondary lighten-1" v-if="simulationResults.length > 0">
              <v-expansion-panel-header>
                <span>
                  <v-icon class="mr-1">mdi-finance</v-icon>
                  {{ $t('simulation.results') }}
                  <v-chip color="primary" x-small class="ml-2">
                   {{ simulationResults.length }}
                  </v-chip>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table :headers="resultHeaders" :items="simulationResults" item-key="id"
                              class="mt-3 secondary lighten-1"
                              hide-default-footer>
                  <template v-slot:item.spec="{ item }">
                    <span>
                      <specialization-avatar class="mr-1" :size="20" :value="item.spec.toLowerCase()" :type="item.className.toLowerCase()"></specialization-avatar>
                      {{item.spec}}
                    </span>
                  </template>
                  <template v-slot:item.timestamp="{ item }">
                    {{ item.timestamp.toLocaleDateString() }} {{ item.timestamp.toLocaleTimeString() }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-row class="ma-2 align-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="`${baseResultUrl}/${item.id}`" target="_blank" v-bind="attrs" v-on="on">
                            <v-btn class="mr-2" color="primary" icon>
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </a>
                        </template>
                        <span>{{ $t('simulation.open_result') }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="saveResult(item)" icon :disabled="!saveAllowed || item.saved"
                                 color="primary" v-on="on">
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('simulation.save_result') }}t</span>
                      </v-tooltip>
                    </v-row>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row class="pa-3 mt-0 align-center" v-if="!readonlyMode">
          <v-btn @click="openSimulationOptions()" class="mr-2 d-inline-block">
            <v-icon>mdi-tune</v-icon>
            {{ $t('simulation.settings') }}
          </v-btn>
          <v-btn @click="clearResults()" class="mr-2 d-inline-block" v-if="simulationResults.length > 0">
            <v-icon>mdi-database</v-icon>
            {{ $t('simulation.clear_results') }}
          </v-btn>
          <v-btn @click="simulate()" class="mr-2 d-inline-block primary"
                 :disabled="simulationLoading || !character || !character.details || characterDetailsLoading || automaticSimulation">
            <v-icon>mdi-database</v-icon>
            {{ $t('general.actions.simulate') }}
          </v-btn>
          <v-checkbox :label="$t('simulation.automatic_sim')" class="mr-2" v-model="automaticSimulation"></v-checkbox>
          <v-progress-circular :size="25" color="primary" class="ma-2 d-inline-block" indeterminate
                               v-if="simulationLoading"></v-progress-circular>
        </v-row>
        <div v-if="character" class="mb-5">
          <h3 class="mt-4">
            {{ $t("general.misc.equipment") }}
            <v-chip color="gray" small label text-color="white" class="mb-1 ml-2" v-if="!simAddonImport">
              <v-icon class="mr-1" small>mdi-database</v-icon>
              Armory
            </v-chip>
            <v-chip color="gray" small label text-color="white" class="mb-1 ml-2" v-if="simAddonImport">
              <v-icon class="mr-1" small>mdi-script-text</v-icon>
              SimulationCraft Script
            </v-chip>
          </h3>
          <v-divider></v-divider>
          <v-progress-circular :size="25" color="primary" class="ma-2 d-inline-block" indeterminate
                               v-if="characterDetailsLoading"></v-progress-circular>
          <v-row v-if="!characterDetailsLoading && !simAddonImport" class="ml-1 mt-3">
            <div v-if="EquipmentHelper().filterEquippedItems(character.details.equipment).length === 0">
              <v-icon>mdi-close-circle</v-icon>
              No equipment existing
            </div>
            <div class="mr-1 ml-1" style="width: 40px; height:40px; display: inline-block; text-align: center"
                 v-for="equippedItem of EquipmentHelper().filterEquippedItems(character.details.equipment)"
                 v-bind:key="equippedItem.id" v-else>
              <a v-bind:href="equippedItem.href_wowhead" v-bind:data-wowhead="equippedItem.data_wowhead"
                 target="_blank">
                <img class="equip-image" v-bind:src="equippedItem.img_wowhead" alt="equip-image"
                     :style="EquipmentHelper().gearIconStyle(equippedItem)">
              </a><br>
            </div>
          </v-row>
          <v-row v-if="!characterDetailsLoading && simAddonImport" class="ml-1 mt-3">
            <div v-if="simAddonEquipment.length === 0">
              <v-icon>mdi-close-circle</v-icon>
              No equipment existing
            </div>
            <div class="mr-1 ml-1" style="width: 40px; height:40px; display: inline-block; text-align: center"
                 v-for="equippedItem of simAddonEquipment"
                 v-bind:key="equippedItem.id">
              <a v-bind:href="equippedItem.href_wowhead" v-bind:data-wowhead="equippedItem.data_wowhead"
                 target="_blank">
                <img class="equip-image" v-bind:src="equippedItem.img_wowhead" alt="equip-image">
              </a><br>
            </div>
          </v-row>
        </div>

        <h3 class="mt-4">{{ $t("simulation.talents") }}</h3>
        <v-divider></v-divider>
        <talent-tree v-bind:game-version="currentGameVersion" v-bind:class-data="className"
                     v-if="className !== null && className !== undefined"
                     v-bind:show-dps="this.character !== null" @hook:mounted="onTalentTreeMounted()"/>

        <div class="ma-3 mt-5" v-else>
          <v-icon>mdi-close-circle</v-icon>
          {{ $t('simulation.no_class_selected') }}
        </div>
      </v-tab-item>
      <v-tab-item key="history-tab">
        <v-row class="ma-3 align-center">
          <v-text-field :label="$t('general.misc.search')" v-model="search" clearable hide-details></v-text-field>
          <v-autocomplete class="ml-2" :label="$t('simulation.options.fight_style')" clearable hide-details
                          return-object
                          :items="historySimOptions.SETTINGS.FIGHT_STYLE_OPTIONS"
                          v-model="historyFightStyleOption"></v-autocomplete>
          <v-autocomplete :items="getHistoryCharacters()" v-model="historyCharacter"
                          :label="$t('general.misc.character')" hide-details return-object
                          :filter="Utilities().searchNormalizedCharacter"
                          item-text="display" class="ml-2" clearable>
            <template v-slot:selection="{ item }">
              <p class="font-weight-medium mb-0">
                <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
              </p>
            </template>
            <template v-slot:item="{ item }">
              <p class="font-weight-medium mb-0">
                <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
              </p>
            </template>
          </v-autocomplete>
        </v-row>
        <div v-if="playerCharacters.length > 0">
          <div class="ma-3 mt-5" v-if="filterResultHistory().length === 0">
            <v-icon>mdi-close-circle</v-icon>
            {{ $t('simulation.no_results') }}
          </div>
          <v-expansion-panels class="pa-2" v-else>
            <v-expansion-panel v-for="result in filterResultHistory()" :key="result.id">
              <v-expansion-panel-header class="pa-1 ma-0"
                                        :class="`${result.characterClass.toLowerCase().replaceAll(' ', '-')}-bg`">
                <v-list-item class="pa-0 ma-0">
                  <specialization-avatar class="mr-3" :size="25" :type="result.characterClass.toLowerCase()"
                                         :value="result.spec"></specialization-avatar>
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>
                      <b :class="result.characterClass.toLowerCase().replaceAll(' ', '-')">{{
                          result.characterName
                        }}</b>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ result.dps }} DPS
                      | {{ result.simObject?.SETTINGS?.FIGHT_STYLE?.VALUE }}
                      | {{ result.spec }}
                      | {{ new Date(result['createdAt']).toLocaleDateString() }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="ma-1">
                <div class="pa-3">
                  <v-row class="pa-3 justify-end align-center">
                    <v-btn class="mt-2 mr-2" color="primary" disabled v-if="!result.htmlResultExisting">
                      <v-icon>mdi-magnify</v-icon>
                      {{ $t('general.misc.result') }}
                    </v-btn>
                    <a :href="`${baseResultUrl}/${result.uuid}`" target="_blank" v-else>
                      <v-btn class="mt-2 mr-2" color="primary">
                        <v-icon>mdi-magnify</v-icon>
                        {{ $t('general.misc.result') }}
                      </v-btn>
                    </a>
                    <v-btn @click="openDeleteResultConfirmation(result)" class="mt-2 mr-2 d-inline-block">
                      <v-icon>mdi-delete</v-icon>
                      {{ $t('general.actions.delete') }}
                    </v-btn>
                  </v-row>
                  <sim-object-details :model="result.simObject"></sim-object-details>
                  <h3 class="mt-4">SimC Input</h3>
                  <v-divider class="mb-2"></v-divider>
                  <v-card>
                    <v-card-text v-html="result.rawInput"></v-card-text>
                  </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="ma-3 mt-5" v-else>
          <v-icon>mdi-close-circle</v-icon>
          {{ $t('user_characters.no_characters_synced') }}
        </div>
      </v-tab-item>
    </v-tabs-items>

    <confirmation-dialog identifier="deleteResult" :question="$t('simulation.result_delete_confirmation')" :confirmation-handler="deleteResult"></confirmation-dialog>
  </div>
</template>

<script>
import TalentTree from "@/components/TalentTree";
import Constants from "@/util/constants";
import CharacterService from "@/services/character.service";
import SimulationService from "@/services/simulation.service";
import SimulationObject from "@/simulation/SimulationObject";
import SimulationCharacter from "@/simulation/SimulationCharacter";
import EquipmentHelper from "@/util/EquipmentHelper";
import axios from "axios";
import SpecializationAvatar from "@/components/SpecializationAvatar.vue";
import SimulationOptions from "@/simulation/SimulationOptions";
import SimObjectDetails from "@/components/SimObjectDetails.vue";
import Utilities from "@/util/Utilities";
import ClassAvatar from "@/components/ClassAvatar.vue";
import MessageService from "@/services/message.service";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

export default {
  name: "Simulation",
  components: {ConfirmationDialog, ClassAvatar, SimObjectDetails, SpecializationAvatar, TalentTree},
  data() {
    return {
      tab: null,
      classes: this.$store.getters["data/classInfo"].list.sort(),
      className: "Death Knight",
      characters: [],
      resultHistory: [],
      search: "",
      playerCharacters: [],
      character: null,
      characterDetailsLoading: false,
      spec: "",
      loadoutSpecs: {},
      loadout: null,
      dps: 0,
      currentGameVersion: "",
      simObject: new SimulationObject(),
      automaticSimulation: false,
      simulationResults: [],
      resultToDelete: null,
      resultHeaders: [
        {text: this.$t('general.misc.spec'), value: "spec"},
        {text: this.$t('simulation.options.fight_style'), value: "mode"},
        {text: this.$t('simulation.options.target_count'), value: "targetCount"},
        {text: "DPS", value: "dps"},
        {text: this.$t('management.admin.logs.timestamp'), value: "timestamp"},
        {text: this.$t('management.user.overview.table.actions'), value: "actions", sortable: false},
      ],
      historyCharacter: null,
      historyFightStyleOption: null,
      historySimOptions: new SimulationOptions(),
      baseResultUrl: `${axios.defaults.baseURL}/service/simulation/result`,
      simulationLoading: false,
      readonlyMode: false,
      classList: this.$store.getters["data/classInfo"].list.sort(),
      classMap: this.$store.getters["data/classInfo"].map,
      simAddonImport: false,
      simAddonString: "",
      simAddonTalents: "",
      simAddonEquipment: [],
      simAddonClassMap: this.$store.getters["data/classInfo"].list.reduce((result, item) => {
        const key = item.toLowerCase().replace(/\s+/g, '');
        return Object.assign(result, {[key]: item});
      }, {}),
      armoryEquipMap: {
        "HEAD": "head",
        "NECK": "neck",
        "SHOULDER": "shoulders",
        "CHEST": "chest",
        "WAIST": "waist",
        "LEGS": "legs",
        "FEET": "feet",
        "WRIST": "wrist",
        "HANDS": "hands",
        "FINGER_1": "finger1",
        "FINGER_2": "finger2",
        "TRINKET_1": "trinket1",
        "TRINKET_2": "trinket2",
        "BACK": "back",
        "MAIN_HAND": "main_hand",
        "OFF_HAND": "off_hand",
      }
    }
  },
  mounted() {
    if (!this.currentUser?.rights.includes("ALLOW_SIM") && this.$route.path !== '/home') {
      this.$router.push('/home');
    } else {
      let appConfig = this.$store.getters["data/appConfig"];
      let currentGameVersion = appConfig.find(x => x.key === "CURRENT_TALENT_GAME_VERSION");
      if (currentGameVersion) {
        this.currentGameVersion = currentGameVersion.value;
      }

      CharacterService.getCharacterList().then(data => {
        this.characters = data;
      });

      if (!this.currentUser.data.isGeneric) {
        this.fetchResultHistory();
      }

      this.$root.$on('talents', (text) => {
        if (this.loadout && this.loadout.code !== text) {
          this.loadout = null;
        }

        this.simObject.TALENTS = text;
        if (this.character != null && !this.characterDetailsLoading && this.automaticSimulation) {
          this.simulate();
        }
      });

      this.$root.$on('spec', (text) => {
        this.spec = text;
      });

      this.$root.$on('importClass', (text) => {
        this.className = this.classes.find(x => x.toLowerCase() === text);
        if (this.character && this.character.class?.toLowerCase() !== text) {
          this.character = null;
        }
      });

      this.$root.$on('readonlyMode', (readonlyMode) => {
        this.readonlyMode = readonlyMode;
      });

      this.$root.$on('class_combo_change', (data) => {
        this.className = data.value;
        this.selectClass();
      });
    }
  },
  methods: {
    Utilities() {
      return Utilities
    },
    onTalentTreeMounted() {
      this.selectClass();
    },
    EquipmentHelper() {
      return EquipmentHelper
    },

    fetchResultHistory(){
      SimulationService.getResultHistoryForUser(this.currentUser.data.id).then(data => {
        this.playerCharacters = data["characters"];
        this.resultHistory = data["results"].map(r => ({
          ...r,
          simObject: new SimulationOptions(r.settings)
        }));
      });
    },
    saveResult(result) {
      SimulationService.saveResult({
        uuid: result.id,
        characterId: result.characterId,
        spec: result.spec,
        dps: result.dps,
        settings: result.simObject,
        userId: this.currentUser.data.id
      }).then(data => {
        if(data){
          result.saved = true;
          this.fetchResultHistory();
        }
      });
    },
    openDeleteResultConfirmation(result){
      this.resultToDelete = result;
      this.$root.$emit('deleteResult_confirmationOpen', true);
    },
    deleteResult(){
      SimulationService.deleteResult(this.resultToDelete.id).then(data => {
        if(data){
          let index = this.resultHistory.findIndex(x => x.id === this.resultToDelete.id);
          if(index !== -1){
            this.resultHistory.splice(index, 1);
          }

          this.resultToDelete = null;
          this.$root.$emit('deleteResult_confirmationOpen', false);
        }
      });
    },
    openSimulationOptions() {
      this.$root.$emit('simSettingsOpen');
    },
    updateSimObject() {
      this.simObject.OPTIONS.loadFromStorage();
      this.simObject.CHARACTER = new SimulationCharacter(this.character, this.spec.replaceAll(" ", "_"));
      if (this.simAddonImport) {
        this.simObject.EQUIPMENT = this.simAddonEquipment.map(x => x.simcEntry);
      } else {
        this.simObject.EQUIPMENT = this.parseArmoryEquipmentForSimulation();
      }
    },
    parseSimAddonStringForSimulation() {
      this.simAddonImport = true;
      this.characterDetailsLoading = true;
      const equipList = Object.values(this.armoryEquipMap);
      let splitLines = this.simAddonString.split("\n");

      let className = "";
      let characterName = "";
      let talents = "";
      let equipPromises = [];
      splitLines.forEach(line => {
        let lineResult = this.parseLine(line);

        if (lineResult.key === "talents") {
          talents = lineResult.value;
        } else if (this.simAddonClassMap[lineResult.key] !== undefined) {
          className = this.simAddonClassMap[lineResult.key];
          characterName = lineResult.value.replace(/"([^"]*)"/g, '$1')
        } else if (equipList.includes(lineResult.key)) {
          equipPromises.push(EquipmentHelper.parseSimcEquipItem(line, this.$i18n.locale));
        }
      });

      let successful = this.selectClassAndCharacterFromSimAddon(className, characterName);
      if (successful) {
        Promise.all(equipPromises).then(values => {
          this.simAddonEquipment = values;
          this.simAddonTalents = talents;
          this.$root.$emit('changeLoadout', this.simAddonTalents);
          this.characterDetailsLoading = false;
        });
      } else {
        this.characterDetailsLoading = false;
        this.simAddonEquipment = [];
        this.simAddonString = "";
        MessageService.error("Error on importing string. Character not found.");
      }
    },
    selectClassAndCharacterFromSimAddon(className, characterName) {
      if (className.length > 0 && characterName.length > 0) {
        let character = this.characters.find(x => x.name === characterName && x.class === className);
        if (character) {
          this.className = className;
          this.selectClass();
          this.character = character;
          this.selectCharacter();
          return true;
        }
      }

      return false;
    },
    parseLine(line) {
      try {
        if (line.startsWith("#")) {
          //commented line
          return {
            key: "",
            value: line
          };
        } else {
          let split = line.split("=");
          return {
            key: split[0],
            value: split[1]
          }
        }
      } catch (e) {
        return {
          key: "",
          value: ""
        }
      }

    },
    parseArmoryEquipmentForSimulation() {
      let equip = [];
      if (this.character && this.character.details) {
        EquipmentHelper.filterEquippedItems(this.character.details.equipment).forEach(item => {
          let base = `${this.armoryEquipMap[item.type]}=,id=${item.id}`;

          if (item.bonus_list && item.bonus_list.length > 0) {
            base += `,bonus_id=${item.bonus_list.join("/")}`;
          }

          if (item.sockets && item.sockets.length > 0) {
            let socketIds = item.sockets.map(s => s.id);
            base += `,gem_id=${socketIds.join("/")}`;
          }

          if (item.enchantments && item.enchantments.length > 0) {
            let enchantmentIds = item.enchantments.map(s => s.id);
            base += `,enchant_id=${enchantmentIds.join("/")}`;
          }

          if (item.crafting_stats && item.crafting_stats.length > 0) {
            let craftingStatIds = item.crafting_stats.map(s => s.id);
            base += `,crafted_stats=${craftingStatIds.join("/")}`;
          }

          equip.push(base);
        });
      }
      return equip;
    },
    clearResults() {
      this.simulationResults = [];
    },
    simulate() {
      this.simulationLoading = true;
      this.updateSimObject();

      if (this.automaticSimulation) {
        this.simObject.OPTIONS.SETTINGS.ITERATIONS.VALUE = 100;
      }

      SimulationService.executeDataSim({
        save: false,
        htmlOutput: true,
        simObject: this.simObject.toJson(),
      }).then(data => {
        this.simulationLoading = false;
        let simResult = {
          id: data.id,
          mode: this.simObject.OPTIONS.SETTINGS.FIGHT_STYLE.VALUE,
          targetCount: this.simObject.OPTIONS.SETTINGS.TARGET_COUNT.VALUE,
          spec: this.spec.replaceAll(" ", "_"),
          dps: Math.round(data.sim.sim.players[0]["collected_data"].dps.mean),
          timestamp: new Date(Date.now()),
          characterId: this.simObject.CHARACTER.ID,
          className: this.simObject.CHARACTER.CLASS_NAME,
          simObject: this.simObject.toJson(),
          saved: false
        };
        this.simulationResults.push(simResult);
        this.dps = data.sim.sim.players[0]["collected_data"].dps.mean;
        this.$root.$emit('dps', this.dps);
      })
    },
    selectClass() {
      this.$root.$emit('changeClass', this.className);
      if (this.character) {
        this.character = null;
      }
    },
    async selectCharacter() {
      this.simulationResults = [];
      if (this.character && this.character.details) {
        if (Object.keys(this.character.details).length === 0) {
          this.characterDetailsLoading = true;
          let data = await CharacterService.getCharacterDetails(this.character.id, false);

          if (data) {
            this.character.details = data;
            if (!this.simAddonImport) {
              this.resolveCharacterDetails(data);
            }
          }
        } else {
          if (!this.simAddonImport) {
            EquipmentHelper.resolveEquipVisualization(this.character.details.equipment, this.$i18n.locale);
          }
        }
      }
    },
    resolveCharacterDetails(data) {
      if (data) {
        this.character.details = data;

        EquipmentHelper.resolveEquipVisualization(this.character.details.equipment, this.$i18n.locale);
        this.spec = data.activeSpec.name.toLowerCase();

        this.loadoutSpecs = {};
        data.loadouts.forEach(spec => {
          this.loadoutSpecs[spec.name.toLowerCase()] = spec.loadouts;
        });

        let loadOuts = this.loadoutSpecs[this.spec];
        this.loadout = loadOuts.find(x => x.is_active);
        if (this.loadout) {
          this.$root.$emit('changeLoadout', this.loadout.code);
        }
      }

      this.characterDetailsLoading = false;
    },
    refreshFromArmory() {
      this.simAddonImport = false;
      this.characterDetailsLoading = true;
      CharacterService.getCharacterDetails(this.character.id, true).then(data => {
        this.resolveCharacterDetails(data);
      });
    },
    getFilteredCharacters() {
      let maxLevel = Utilities.getAppConfig(this.$store,"MAX_LEVEL");
      return this.characters
          .filter(c => c.class === this.className && c.level.toString() === maxLevel)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    getHistoryCharacters() {
      const resultHistory = this.resultHistory.filter(result => result.userId === this.currentUser.data.id);
      const historyCharacterIds = [...new Set(resultHistory.map(x => x.characterId))];
      return this.characters.filter(x => historyCharacterIds.includes(x.id));
    },
    filterResultHistory() {
      let resultHistory = this.resultHistory.filter(result => result.userId === this.currentUser.data.id);

      if (this.historyCharacter !== null) {
        resultHistory = resultHistory.filter(x => x.characterId === this.historyCharacter.id);
      }

      if (this.historyFightStyleOption !== null) {
        resultHistory = resultHistory.filter(x => this.historyFightStyleOption === x.simObject.SETTINGS.FIGHT_STYLE.VALUE);
      }

      if (this.search !== null && this.search !== "") {
        let searchNormalized = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        resultHistory = resultHistory.filter(result => {
          let dateFilter = result['createdAt'].includes(searchNormalized);
          let specFilter = result.spec.includes(searchNormalized);
          let rawInputFilter = result.rawInput.includes(searchNormalized);

          return rawInputFilter || dateFilter || specFilter;
        });
      }

      return resultHistory.sort((a, b) => new Date(b["createdAt"]) - new Date(a["createdAt"]));
    }
  }
  ,
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    saveAllowed() {
      return !this.currentUser.data.isGeneric;
    }
  },
  beforeDestroy() {
    this.$root.$off('talents');
    this.$root.$off('spec');
    this.$root.$off('importClass');
    this.$root.$off('readonlyMode');
    this.$root.$off('class_combo_change');
    this.$root.$off('character_combo_change');
  }
}
</script>

<style scoped>
.equip-image {
  height: 40px;
  width: 40px;
}
</style>