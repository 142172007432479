import FLASKS from '@/simulation/consumables/tww/flasks.json';
import POTIONS from '@/simulation/consumables/tww/potions.json';
import FOOD from '@/simulation/consumables/tww/food.json';
import WEAPON_RUNES from '@/simulation/consumables/tww/weapon_enchantments.json';
import AUGMENT_RUNES from '@/simulation/consumables/augment_runes.json';

export default class SimulationConsumables{
    USE_DEFAULT = {
        VALUE: true,
        DEFAULT: true
    };

    FLASK = {
        USE: true,
        USE_DEFAULT: true,
        VALUE:  FLASKS.ALCHEMICAL_CHAOS,
        DEFAULT:  FLASKS.ALCHEMICAL_CHAOS
    }

    POTION = {
        USE: true,
        USE_DEFAULT: true,
        VALUE:  POTIONS.TEMPERED,
        DEFAULT:  POTIONS.TEMPERED
    }

    FOOD = {
        USE: true,
        USE_DEFAULT: true,
        VALUE:  FOOD.DIVINE_DAY,
        DEFAULT:  FOOD.DIVINE_DAY
    }

    AUGMENT_RUNE = {
        USE: true,
        USE_DEFAULT: true,
        VALUE:  AUGMENT_RUNES.CRYSTALLIZED_AUGMENT_RUNE,
        DEFAULT:  AUGMENT_RUNES.CRYSTALLIZED_AUGMENT_RUNE
    }

    WEAPON_RUNE = {
        USE: false,
        USE_DEFAULT: false,
        VALUE: null,
        DEFAULT: null
    }

    constructor(){}

    toJson(){
        return {
            default: this.USE_DEFAULT.VALUE,
            options: {
                flask: {
                    use: this.FLASK.USE,
                    key: this.FLASK.VALUE?.KEY,
                    value: this.FLASK.VALUE?.VALUE
                },
                potion: {
                    use: this.POTION.USE,
                    key: this.POTION.VALUE?.KEY,
                    value: this.POTION.VALUE?.VALUE
                },
                food: {
                    use: this.FOOD.USE,
                    key: this.FOOD.VALUE?.KEY,
                    value: this.FOOD.VALUE?.VALUE
                },
                augmentRune: {
                    use: this.AUGMENT_RUNE.USE,
                    key: this.AUGMENT_RUNE.VALUE?.KEY,
                    value: this.AUGMENT_RUNE.VALUE?.VALUE
                },
                weaponRune: {
                    use: this.WEAPON_RUNE.USE,
                    key: this.WEAPON_RUNE.VALUE?.KEY,
                    value: this.WEAPON_RUNE.VALUE?.VALUE
                }
            }
        };
    }

    load(jsonObj) {
        if(jsonObj){
            this.USE_DEFAULT.VALUE = jsonObj.default !== undefined ? jsonObj.default : this.USE_DEFAULT.DEFAULT;
            this.resolveConsumable(this.FLASK, jsonObj.options.flask, FLASKS);
            this.resolveConsumable(this.POTION, jsonObj.options.potion, POTIONS);
            this.resolveConsumable(this.FOOD, jsonObj.options.food, FOOD);
            this.resolveConsumable(this.AUGMENT_RUNE, jsonObj.options.augmentRune, AUGMENT_RUNES);
            this.resolveConsumable(this.WEAPON_RUNE, jsonObj.options.weaponRune, WEAPON_RUNES);
        } else {
            this.USE_DEFAULT.VALUE = this.USE_DEFAULT.DEFAULT;
            this.FLASK.VALUE = this.FLASK.DEFAULT;
            this.POTION.VALUE = this.POTION.DEFAULT;
            this.FOOD.VALUE = this.FOOD.DEFAULT;
            this.AUGMENT_RUNE.VALUE = this.AUGMENT_RUNE.DEFAULT;
            this.WEAPON_RUNE.VALUE = this.WEAPON_RUNE.DEFAULT;
        }
    }

    resolveConsumable(context, consumable, consumableList){
        if(consumable !== undefined){
            context.USE = consumable.use;
            let foundConsumable = this.searchConsumable(consumableList, consumable.value);
            if(foundConsumable){
                context.VALUE = foundConsumable;
            } else {
                context.VALUE = context.DEFAULT;
            }
        } else {
            context.USE = context.USE_DEFAULT;
            context.VALUE = context.DEFAULT;
        }
    }

    searchConsumable(context, consumable){
        if(consumable !== undefined){
            return Object.values(context).find(x => x.VALUE === consumable)
        } else {
            return undefined;
        }
    }
}