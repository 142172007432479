import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import {VBtn, VIcon, VSnackbar} from "vuetify/lib/components";
import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

Vue.use(VuetifyToast, {
    x: 'left',
    y: 'top',
    queueable: true,
    timeout: 4000
});

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: colors.orange.darken2,
                secondary: colors.grey.lighten2,
                accent: colors.white,
                menu: colors.grey.darken4
            },
            dark: {
                primary: colors.orange.darken2,
                secondary: colors.grey.darken4,
                accent: colors.grey.darken3,
                menu: colors.grey.lighten2
            },
        }
    },
});
