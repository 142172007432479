import Axios from 'axios';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "vuetify/dist/vuetify.min.css";
import "@/assets/fonts/fonts.css";

import i18n from './i18n'
import MessageService from "@/services/message.service";

Vue.config.productionTip = false
Vue.prototype.$store = store;

// set axios settings
Axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}:${process.env.VUE_APP_BACKEND_PORT}`;

let token = store.getters["auth/token"];
if(token){
  Axios.defaults.headers.common['x-access-token'] = token;
}
Axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    router.push("/sign-in");
    store.dispatch("auth/signOut").then(() => {
      MessageService.info("Your session expired. You have been logged out.");
    });
  } else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  async beforeCreate() {
    await store.dispatch("data/battleNetClientId");
    await store.dispatch("data/getAppConfig");
    await store.dispatch("data/getRealmList");
    await store.dispatch("data/getClassList");
    await store.dispatch("data/getSpecializationList");
    await store.dispatch("data/getHeroList");
  },
  render: h => h(App)
}).$mount('#app')


