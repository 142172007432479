export default class SimulationSettings {
    MAX_TIME;
    ITERATIONS;
    FIGHT_STYLE;
    FIGHT_STYLE_OPTIONS;
    TARGET_COUNT;
    constructor() {
        this.STAT_SCALING = {
            VALUE: false,
            DEFAULT: false
        };

        this.MAX_TIME = { //sek
            VALUE: 300,
            DEFAULT: 300,
            MIN: 10,
            MAX: 800
        };

        this.ITERATIONS = {
            VALUE: 2000,
            DEFAULT: 2000,
            MIN: 1,
            MAX: 20000
        };

        this.FIGHT_STYLE_OPTIONS = [
            "Patchwerk", "DungeonSlice", "HecticAddCleave"
        ];
        this.FIGHT_STYLE = {
            VALUE: "Patchwerk",
            DEFAULT: "Patchwerk"
        };

        this.TARGET_COUNT = {
            VALUE: 1,
            DEFAULT: 1,
            MIN: 1,
            MAX: 10
        };
    }

    toJson() {
        let jsonObj = {
            max_time: this.MAX_TIME.VALUE,
            iterations: this.ITERATIONS.VALUE,
            fight_style: this.FIGHT_STYLE.VALUE,
            calculate_scale_factors: this.STAT_SCALING.VALUE ? 1 : 0
        };

        if(this.FIGHT_STYLE.VALUE === "Patchwerk"){
            jsonObj.desired_targets = this.TARGET_COUNT.VALUE;
        }

        return jsonObj;
    }

    load(jsonObj) {
        if (jsonObj) {
            this.resolveSetting(this.MAX_TIME, jsonObj.max_time);
            this.resolveSetting(this.ITERATIONS, jsonObj.iterations);
            this.resolveSetting(this.FIGHT_STYLE, jsonObj.fight_style);
            this.resolveSetting(this.TARGET_COUNT, jsonObj.desired_targets);

            if(jsonObj.calculate_scale_factors){
                this.STAT_SCALING.VALUE = jsonObj.calculate_scale_factors === 1;
            } else {
                this.STAT_SCALING.VALUE = this.STAT_SCALING.DEFAULT;
            }
        } else {
            this.MAX_TIME.VALUE = this.MAX_TIME.DEFAULT;
            this.ITERATIONS.VALUE = this.ITERATIONS.DEFAULT;
            this.FIGHT_STYLE.VALUE = this.FIGHT_STYLE.DEFAULT;
            this.TARGET_COUNT.VALUE = this.TARGET_COUNT.DEFAULT;
            this.STAT_SCALING.VALUE = this.STAT_SCALING.DEFAULT;
        }
    }

    resolveSetting(context, value){
        if(value !== undefined){
            context.VALUE = value;
        } else {
            context.VALUE = context.DEFAULT;
        }
    }
}