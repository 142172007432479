<template>
<h4>{{ $t('general.page_not_existing') }}</h4>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>