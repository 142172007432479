<template>
  <div class="ml-2">
    <h2 class="mb-4">{{ $t('home.subtitle') }}</h2>
    <p>{{ $t('home.description_1') }}</p>
    <p>{{ $t('home.description_2') }}</p>
    <p>{{ $t('home.description_3') }}</p>
    <v-carousel v-if="images.length">
      <v-carousel-item v-for="img in images" :key="img.id" :src="img.content">
        <v-container fill-height class="align-end">
          <v-row fill-height class="align-end mb-8">
            <v-col cols="12">
              <v-card color="primary" class="pa-1 text-center">
              <span class="headline white--text">
                <v-icon>mdi-skull</v-icon>
                {{ img.description }}
              </span>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>

  </div>
</template>

<script>
import DataService from "@/services/data.service";

export default {
  name: "Home",
  data() {
    return {
      tab: null,
      images: []
    }
  },
  mounted() {
    DataService.getImages(true).then(data => {
      this.images = data.sort((a,b)=>b.id - a.id);
    });
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    }
  }
}
</script>


<style scoped>

</style>
