import AuthService from '../services/auth.service';
import Axios from "axios";
import {jwtDecode} from "jwt-decode";
import store from "../store/index"

const getDefaultState = () => {
    return {
        token: null,
        user: null,
    };
};

export const auth = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        token: state => state.token,
        user(state) {
            if(state.user !== null && state.user.data === undefined){
                state.user = null;
            }

            return state.user;
        }
    },
    actions: {
        signIn({commit}, credentials) {
            return AuthService.signIn(credentials).then(res => {
                    commit('SET_TOKEN', res["token"]);
                    Axios.defaults.headers.common['x-access-token'] = res["token"];
                }, () => { //error
                    commit('RESET');
                    return null;
                }
            );
        },
        signInAs({commit}, userId) {
            return AuthService.signInAs(userId).then(res => {
                    commit('SET_TOKEN', res["token"]);
                    Axios.defaults.headers.common['x-access-token'] = res["token"];

                    return jwtDecode(res["token"], {header: false});
                }, () => { //error
                    commit('RESET');
                    return null;
                }
            );
        },
        signInAsRestore({commit}, userId) {
            return AuthService.signInAsRestore(userId).then(res => {
                    commit('SET_TOKEN', res["token"]);
                    Axios.defaults.headers.common['x-access-token'] = res["token"];

                    return jwtDecode(res["token"], {header: false});
                }, () => { //error
                    commit('RESET');
                    return null;
                }
            );
        },
        signOut({commit}) {
            commit('RESET');
        },
        register({commit}, user) {
            return AuthService.register(user);
        },
        refreshToken({commit}) {
            let token = store.getters["auth/token"];
            return AuthService.refreshToken(token).then(res => {
                commit('SET_TOKEN', res["token"]);
                Axios.defaults.headers.common['x-access-token'] = res["token"];

                return jwtDecode(res["token"], {header: false});
            },() => { //error
                return store.getters["auth/user"];
            });
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            state.user = jwtDecode(token, {header: false});
        },
        RESET: state => {
            Object.assign(state, getDefaultState());
            delete Axios.defaults.headers.common["x-access-token"];
        }
    }
};
