let classMap;

class Utilities {
    initialize(store){
        classMap = store.getters["data/classInfo"].map;
    }
    searchNormalized(value, search){
        if (value && search) {
            let valueNormalized = value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let searchNormalized = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            let searchValues = searchNormalized.split(" ");
            let found = false;
            searchValues.forEach(searchValue => {
                found = found || valueNormalized.indexOf(searchValue) !== -1
            });
            return found;
        } else {
            return false;
        }
    }
    searchNormalizedCharacter(item, search){
        if (item?.display && search) {
            let valueNormalized = item.display.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let searchNormalized = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            let searchValues = searchNormalized.split(" ");
            let found = false;
            searchValues.forEach(searchValue => {
                found = found || valueNormalized.indexOf(searchValue) !== -1
            });
            return found;
        } else {
            return false;
        }
    }

    searchNormalizedClass(item, search){
        let mappedItem = classMap[item];
        let language = this.$i18n.locale;

        if(mappedItem && language && search){
            return  mappedItem[language] && mappedItem[language].toLowerCase().includes(search.toLowerCase());
        }
    }

    getAppConfig(store, key){
        let appConfig = store.getters["data/appConfig"];
        let config = appConfig.find(x => x.key === key);
        return config?.value;
    }
}

export default new Utilities();