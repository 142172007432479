import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import PageNotFound from "@/views/PageNotFound";
import Home from "@/views/Home";
import Characters from "@/views/Characters";
import Simulation from "@/views/Simulation";
import LootManagement from "@/views/LootManagement";
import Raids from "@/views/Raids";
import UserAccount from "@/views/user-context/UserAccount.vue";

import UserManagement from "@/views/management/UserManagement.vue";
import Admin from "@/views/management/Admin.vue";

import Register from "@/views/user-context/Register.vue";
import SignIn from "@/views/user-context/Signin.vue";
import UserCharacters from "@/views/user-context/UserCharacters.vue";
import AuthCallback from "@/views/AuthCallback.vue";

import Minecraft from "@/views/static/Minecraft.vue";
import CharacterManagement from "@/views/management/CharacterManagement.vue";
import ResetPassword from "@/views/user-context/ResetPassword.vue";
import Information from "@/views/Information.vue";
import BehaviourLogManagement from "@/views/management/BehaviourLogManagement.vue";

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/home",
      component: Home,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/information",
      component: Information,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/sign-in",
      component: SignIn,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/password-reset",
      component: ResetPassword,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/register",
      component: Register,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/user-account",
      component: UserAccount,
      name: "UserAccount",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user-characters",
      component: UserCharacters,
      name: "UserCharacters",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/characters",
      component: Characters,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user-management",
      component: UserManagement,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/character-management",
      component: CharacterManagement,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/behaviour-log-management",
      component: BehaviourLogManagement,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/raids",
      component: Raids,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/loot-management",
      component: LootManagement,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/simulation",
      component: Simulation,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin",
      component: Admin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/minecraft",
      component: Minecraft,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/auth-callback",
      component: AuthCallback,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "*",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      }
    }
  ]
});

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    if(Vue)
    return Promise.reject(err)
  })
}

router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta["requiresAuth"])) {
    if (router.app.$store.getters["auth/token"]) {
      next()
      return
    }
    next('/sign-in')
  } else {
    next()
  }
})

export default router
