import MessageService from "@/services/message.service";

import axios from 'axios';
const API_URL = '/service/users';

class AuthService {
    signIn(user) {
        return axios
            .post(API_URL + '/sign-in', {
                username: user.username,
                password: user.password
            }).then(response => {
                if(response.status !== 200){
                    MessageService.error(`Sign in failed. Error: '${response.statusText}'`);
                }
                return response.data;
            }).catch((error) => {
                MessageService.error(`Sign in failed. Error: '${error.response.statusText}'`);
            });
    }

    signInAs(userId) {
        return axios
            .post(API_URL + '/sign-in-as', userId)
            .then(response => {
                return response.data;
            }).catch((error) => {
                MessageService.error(`Sign in as failed. Error: '${error.response.statusText}'`);
            });
    }

    signInAsRestore(originalId) {
        return axios
            .post(API_URL + '/resolve-sign-in-as', {
                originalId: originalId
            })
            .then(response => {
                if(response.status !== 200){
                    MessageService.error(`Sign in as restore failed. Error: '${response.statusText}'`);
                }
                return response.data;
            }).catch((error) => {
                MessageService.error(`Sign in as restore failed. Error: '${error.response.statusText}'`);
            });
    }

    register(user) {
        return axios.post(API_URL + '/sign-up', {
            username: user.username,
            email: user.email,
            password: user.password
        }).then(() => {
            MessageService.success("Successfully created user account. Account must be activated by an administrator.");
        }).catch((error) => {
            MessageService.error(`Register failed. Error: '${error.response.statusText}'`);
        });
    }

    refreshToken(token) {
        return axios
            .post(API_URL + '/refresh-token', token).then(response => {
                return response.data;
            }).catch((error) => {
                MessageService.error(`Refresh failed. Error: '${error.response.statusText}'`);
                return null;
            });
    }
}

export default new AuthService();
