<template>
    <span>
      <v-avatar :size="size" v-if="type === 'death knight' || type === 'todesritter'">
        <img alt="blood" src="../assets/img/spec/deathknight/blood.jpg" v-if="value === 'blood' || value === 'blut'">
        <img alt="frost" src="../assets/img/spec/deathknight/frost.jpg" v-if="value === 'frost'">
        <img alt="unholy" src="../assets/img/spec/deathknight/unholy.jpg" v-if="value === 'unholy' || value === 'unheilig'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'druid' || type === 'druide'">
        <img alt="restoration" src="../assets/img/spec/druid/restoration.jpg" v-if="value === 'restoration' || value === 'wiederherstellung'">
        <img alt="balance" src="../assets/img/spec/druid/balance.jpg" v-if="value === 'balance' || value === 'gleichgewicht'">
        <img alt="feral" src="../assets/img/spec/druid/feral.jpg" v-if="value === 'feral' || value === 'wildheit'">
        <img alt="guardian" src="../assets/img/spec/druid/guardian.jpg" v-if="value === 'guardian' || value === 'wächter'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'hunter' || type === 'jäger'">
        <img alt="marksmanship" src="../assets/img/spec/hunter/marksmanship.jpg" v-if="value === 'marksmanship' || value === 'treffsicherheit'">
        <img alt="beastmastery" src="../assets/img/spec/hunter/beastmastery.jpg" v-if="value === 'beast mastery' || value === 'tierherrschaft'">
        <img alt="survival" src="../assets/img/spec/hunter/survival.jpg" v-if="value === 'survival' || value === 'überleben'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'mage' || type === 'magier'">
        <img alt="arcane" src="../assets/img/spec/mage/arcane.jpg" v-if="value === 'arcane' || value === 'arkan'">
        <img alt="fire" src="../assets/img/spec/mage/fire.jpg" v-if="value === 'fire' || value === 'feuer'">
        <img alt="frost" src="../assets/img/spec/mage/frost.jpg" v-if="value === 'frost'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'monk' || type === 'mönch'">
        <img alt="brewmaster" src="../assets/img/spec/monk/brewmaster.jpg" v-if="value === 'brewmaster' || value === 'braumeister'">
        <img alt="windwalker" src="../assets/img/spec/monk/windwalker.jpg" v-if="value === 'windwalker' || value === 'windläufer'">
        <img alt="mistweaver" src="../assets/img/spec/monk/mistweaver.jpg" v-if="value === 'mistweaver' || value === 'nebelwirker'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'paladin'">
        <img alt="protection" src="../assets/img/spec/paladin/protection.jpg" v-if="value === 'protection' || value === 'schutz'">
        <img alt="retribution" src="../assets/img/spec/paladin/retribution.jpg" v-if="value === 'retribution' || value === 'vergeltung'">
        <img alt="holy" src="../assets/img/spec/paladin/holy.jpg" v-if="value === 'holy' || value === 'heilig'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'priest' || type === 'priester'">
        <img alt="shadow" src="../assets/img/spec/priest/shadow.jpg" v-if="value === 'shadow' || value === 'schatten'">
        <img alt="discipline" src="../assets/img/spec/priest/discipline.jpg" v-if="value === 'discipline' || value === 'disziplin'">
        <img alt="holy" src="../assets/img/spec/priest/holy.jpg" v-if="value === 'holy' || value === 'heilig'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'rogue' || type === 'schurke'">
        <img alt="subtlety" src="../assets/img/spec/rogue/subtlety.jpg" v-if="value === 'subtlety' || value === 'täuschung'">
        <img alt="assassination" src="../assets/img/spec/rogue/assassination.jpg" v-if="value === 'assassination' || value === 'meucheln'">
        <img alt="outlaw" src="../assets/img/spec/rogue/outlaw.jpg" v-if="value === 'outlaw' || value === 'gesetzlosigkeit'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'shaman' || type === 'schamane'">
        <img alt="elemental" src="../assets/img/spec/shaman/elemental.jpg" v-if="value === 'elemental' || value === 'elementar'">
        <img alt="restoration" src="../assets/img/spec/shaman/restoration.jpg" v-if="value === 'restoration' || value === 'wiederherstellung'">
        <img alt="enhancement" src="../assets/img/spec/shaman/enhancement.jpg" v-if="value === 'enhancement' || value === 'verstärkung'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'warlock' || type === 'hexenmeister'">
        <img alt="demonology" src="../assets/img/spec/warlock/demonology.jpg" v-if="value === 'demonology' || value === 'dämonologie'">
        <img alt="destruction" src="../assets/img/spec/warlock/destruction.jpg" v-if="value === 'destruction' || value === 'zerstörung'">
        <img alt="affliction" src="../assets/img/spec/warlock/affliction.jpg" v-if="value === 'affliction' || value === 'gebrechen'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'warrior' || type === 'krieger'">
        <img alt="protection" src="../assets/img/spec/warrior/protection.jpg" v-if="value === 'protection' || value === 'schutz'">
        <img alt="fury" src="../assets/img/spec/warrior/fury.jpg" v-if="value === 'fury' || value === 'furor'">
        <img alt="arms" src="../assets/img/spec/warrior/arms.jpg" v-if="value === 'arms' || value === 'waffen'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'demon hunter' || type === 'dämonenjäger'">
        <img alt="vengeance" src="../assets/img/spec/demonhunter/vengeance.jpg" v-if="value === 'vengeance' || value === 'rachsucht'">
        <img alt="havoc" src="../assets/img/spec/demonhunter/havoc.jpg" v-if="value === 'havoc' || value === 'verwüstung'">
      </v-avatar>
      <v-avatar :size="size" v-if="type === 'evoker' || type === 'rufer'">
        <img alt="preservation" src="../assets/img/spec/evoker/preservation.jpg" v-if="value === 'preservation' || value === 'bewahrung'">
        <img alt="devastation" src="../assets/img/spec/evoker/devastation.jpg" v-if="value === 'devastation' || value === 'verheerung'">
        <img alt="augmentation" src="../assets/img/spec/evoker/augmentation.jpg" v-if="value === 'augmentation' || value === 'verstärkung'">
      </v-avatar>
    </span>
</template>

<script>
export default {
  name: "SpecializationAvatar",
  props: ['type', 'value', 'size']
}
</script>

<style scoped>

</style>
