<template>
  <v-autocomplete :label="label" :items="items" v-model="vModel" :disabled="disabled" item-value="VALUE" hide-details @change="changeAction" return-object>
    <template v-slot:selection="{ item }">
      <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
        <a :href="resolveWowHeadUrl(item, 'ID')" v-bind:data-wowhead="resolveWowHeadData(item)" target="_blank">
          <img :src="`https://wow.zamimg.com/images/wow/icons/large/${item.MEDIA}.jpg`" style="width: 25px; height:25px;" alt="wow-icon">
        </a><br>
      </div>
      <span>{{$t(`simulation.${languageCategory}.${item.LANGUAGE}`)}}</span>
      <img src="../../assets/img/quality/1.png" v-if="item.QUALITY === 1" class="ml-1" alt="quality-1">
      <img src="../../assets/img/quality/2.png" v-if="item.QUALITY === 2" class="ml-1" alt="quality-2">
      <img src="../../assets/img/quality/3.png" v-if="item.QUALITY === 3" class="ml-1" alt="quality-3">
    </template>
    <template v-slot:item="{ item }">
      <div v-if="!item.TWO_ICONS">
        <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
          <a :href="resolveWowHeadUrl(item, 'ID')" v-bind:data-wowhead="resolveWowHeadData(item)" target="_blank">
            <img :src="`https://wow.zamimg.com/images/wow/icons/large/${item.MEDIA}.jpg`" style="width: 25px; height:25px;" alt="wow-icon">
          </a><br>
        </div>
      </div>
      <div v-else>
        <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
          <a :href="resolveWowHeadUrl(item, 'ID1')" v-bind:data-wowhead="resolveWowHeadData(item)" target="_blank">
            <img :src="`https://wow.zamimg.com/images/wow/icons/large/${item.MEDIA1}.jpg`" style="width: 25px; height:25px;" alt="wow-icon">
          </a><br>
        </div>
        <div class="mt-2 mb-2 mr-2 ml-0" style="width: 25px; height:25px; display: inline-block; text-align: center">
          <a :href="resolveWowHeadUrl(item, 'ID2')" v-bind:data-wowhead="resolveWowHeadData(item)" target="_blank">
            <img :src="`https://wow.zamimg.com/images/wow/icons/large/${item.MEDIA2}.jpg`" style="width: 25px; height:25px;" alt="wow-icon">
          </a><br>
        </div>
      </div>
      <span>{{$t(`simulation.${languageCategory}.${item.LANGUAGE}`)}}</span>
      <img src="../../assets/img/quality/1.png" v-if="item.QUALITY === 1" class="ml-1" alt="quality-1">
      <img src="../../assets/img/quality/2.png" v-if="item.QUALITY === 2" class="ml-1" alt="quality-2">
      <img src="../../assets/img/quality/3.png" v-if="item.QUALITY === 3" class="ml-1" alt="quality-3">
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "SimulationCombobox",
  props: ["identifier", "label", "items", "model", "disabled", "category", "languageCategory", "iconType"],
  data() {
    return {
      vModel: this.model,
      wowHeadUrl: "",
      wowHeadData: ""
    }
  },
  methods: {
    resolveWowHeadData(item){
      if(item){
        if(this.iconType === "spell"){
          return `domain=${this.$i18n.locale}`;
        } else {
          return `domain=${this.$i18n.locale}&crafting-quality=${item.QUALITY}`;
        }
      } else {
        return "";
      }
    },
    resolveWowHeadUrl(item, idProperty){
      if(item && item[idProperty]){
        if(this.iconType === "spell"){
          return `https://www.wowhead.com/spell=${item[idProperty]}`;
        } else {
          return `https://www.wowhead.com/item=${item[idProperty]}`;
        }
      } else {
        return "";
      }
    },
    changeAction(){
      this.$root.$emit("sim_combo_change", {
        identifier: this.identifier,
        category: this.category,
        value: this.vModel
      });
    }
  },
  watch: {
    model: function() {
      this.vModel = this.model;
    }
  }
}
</script>

<style scoped>

</style>