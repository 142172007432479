<template>
  <div>
    <h3>
      {{ $t('character_management.title') }}
    </h3>
    <v-divider></v-divider>
    <v-row class="mx-2 mt-2 pa-2 align-center">
      <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.misc.search')" class="mr-2"
                    clearable hide-details></v-text-field>
      <v-autocomplete :items="classList" v-model="filterOnClass" :label=" $t('general.misc.class')" hide-details return-object clearable
                      :filter="Utilities().searchNormalizedClass">
        <template v-slot:selection="{ item }">
          <p class="font-weight-medium mb-0">
            <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
            <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
          </p>
        </template>
        <template v-slot:item="{ item }">
          <p class="font-weight-medium mb-0">
            <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
            <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
          </p>
        </template>
      </v-autocomplete>
      <v-checkbox :label="$t('characters.roster') + ' Feuer'" v-model="filterOnRosterFire"
                  class="ml-2 mr-2" hide-details></v-checkbox>
      <v-checkbox :label="$t('characters.roster') + ' Flamme'" v-model="filterOnRosterFlame"
                  class="ml-2  mr-2" hide-details></v-checkbox>
      <v-checkbox :label="$t('character_management.guests')" v-model="filterOnGuests"
                  class="ml-2  mr-2" hide-details></v-checkbox>
    </v-row>
    <v-row class="ma-2 mt-0 pa-2 align-center">
      <v-checkbox :label="$t('characters.show_details')" v-model="showDetails" class="ml-2 mr-2" hide-details></v-checkbox>
      <v-chip :color="!filterOnActive ? 'grey' : activeFilter ? 'green' : 'red'" text-color="white" class="mt-5 ml-2" @contextmenu="$event.preventDefault(); activeFilter = !activeFilter" @click="filterOnActive = !filterOnActive">
        <v-avatar left>
          <v-icon>{{ activeFilter ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </v-avatar>
        <span v-if="activeFilter">
                {{ $t('management.user.active') }}
              </span>
        <span v-else>
                {{ $t('management.user.inactive') }}
              </span>
      </v-chip>
      <v-chip :color="!filterOnSyncedToUser ? 'grey' : syncedToUserFilter ? 'green' : 'red'" text-color="white" class="mt-5 ml-2" @contextmenu="$event.preventDefault(); syncedToUserFilter = !syncedToUserFilter" @click="filterOnSyncedToUser = !filterOnSyncedToUser">
        <v-avatar left>
          <v-icon>{{ syncedToUserFilter ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </v-avatar>
        <span v-if="syncedToUserFilter">
                {{ $t('management.character.assigned') }}
              </span>
        <span v-else>
                {{ $t('management.character.unassigned') }}
              </span>
      </v-chip>
      <v-chip :color="!filterOnManualAdded ? 'grey' : manualAddedFilter ? 'green' : 'red'" text-color="white" class="mt-5 ml-2" @contextmenu="$event.preventDefault(); manualAddedFilter = !manualAddedFilter" @click="filterOnManualAdded = !filterOnManualAdded">
        <v-avatar left>
          <v-icon>{{ manualAddedFilter ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </v-avatar>
        <span v-if="manualAddedFilter">
                {{ $t('management.character.manual_added') }}
              </span>
        <span v-else>
                {{ $t('management.character.not_manual_added') }}
              </span>
      </v-chip>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2 mt-4" icon @click="isOpenAddCharacter = true"  v-bind="attrs" v-on="on">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('characters.add_character') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mt-4 ml-2" icon @click="syncCharacters" v-bind="attrs" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('management.admin.utility.sync.sync_characters') }}
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mt-4 ml-2" icon :disabled="updatedCharacters.length === 0" @click="saveAllCharacters" v-bind="attrs" v-on="on">
            <v-icon :color="updatedCharacters.length > 0 ? 'primary': ''">mdi-content-save-all</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('character_management.save_all') }}
        </span>
      </v-tooltip>
    </v-row>
    <v-data-table :headers="getHeaders" :items="characters" item-key="id"
                  :search="search" :loading="charactersLoading"
                  :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer
                  @page-count="pageCount = $event" dense
                  :custom-filter="Utilities().searchNormalized">
      <template v-slot:[`header.${head.value}`]=" { }" v-for="head in getHeaders">
        <v-tooltip top v-if="head.tooltip" v-bind:key="head.value">
          <template v-slot:activator="{ on, attrs }">
            {{ head.text }}
            <v-icon small class="ml-2 mb-1" v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ head.tooltip }}</span>
        </v-tooltip>
        <span v-else v-bind:key="head.value">{{ head.text }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <p class="pa-2 pb-0 mb-1">
          <span :class="item.class.toLowerCase().replaceAll(' ', '-')"><b>{{ item.name }}</b></span><br>
          <span>{{ item.realm }}</span>
        </p>
      </template>
      <template v-slot:item.state="{ item }">
        <v-autocomplete :items="states" v-model="item.state" hide-details class="pa-2" dense return-object
                    @change="rowChange(item.id)"></v-autocomplete>
      </template>
      <template v-slot:item.rosterInformation="{ item }">
        <v-row class="ma-1 align-center">
          <v-chip class="ma-1" small color="grey" label text-color="white" v-if="!item.inRoster">
            n/a
          </v-chip>
          <v-chip class="ma-1" small color="primary" label text-color="white" v-if="item.rosterFire">
            <v-icon left small>mdi-account</v-icon>
            Feuer
          </v-chip>
          <v-chip class="ma-1" small color="#298F85" label text-color="white" v-if="item.rosterFlame && !item.guestFlame">
            <v-icon left small>mdi-account</v-icon>
            Flamme
          </v-chip>
          <v-chip class="ma-1" small color="#298F85" label text-color="white" v-if="item.rosterFlame && item.guestFlame">
            <v-icon left small>mdi-briefcase-account</v-icon>
            {{ $t('character_management.guest') }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:item.defaultRole="{ item }">
        <v-autocomplete :items="defaultRoles" v-model="item.defaultRole" hide-details return-object
                        class="pa-2" dense
                        @change="rowChange(item.id)">
          <template v-slot:selection="{ item }">
            <p class="font-weight-medium mb-0">
              <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
              <span>{{ item }}</span>
            </p>
          </template>
          <template v-slot:item="{ item }">
            <p class="font-weight-medium mb-0">
              <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
              <span>{{ item }}</span>
            </p>
          </template>
        </v-autocomplete>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-text-field v-model="item.comment" hide-details class="pa-2" dense disabled clearable></v-text-field>
      </template>
      <template v-slot:item.isSyncedToUser="{ item }">
        <v-chip small :color="item.isSyncedToUser ? 'green' : 'red'" text-color="white" class="mr-1 mb-1">
          <v-avatar left>
            <v-icon small>{{ item.isSyncedToUser ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
          </v-avatar>
          <span v-if="item.isSyncedToUser">
                {{ $t('management.character.assigned') }}
              </span>
          <span v-else>
                {{ $t('management.character.unassigned') }}
              </span>
        </v-chip>
      </template>
      <template v-slot:item.isManualAdded="{ item }">
        <v-chip small :color="item.isManualAdded ? 'green' : 'red'" text-color="white" class="mr-1 mb-1" @click="item.isManualAdded = !item.isManualAdded; rowChange(item.id)">
          <v-avatar left>
            <v-icon small>{{ item.isManualAdded ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
          </v-avatar>
          <span v-if="item.isManualAdded">
                {{ $t('management.character.manual_added') }}
              </span>
          <span v-else>
                {{ $t('management.character.not_manual_added') }}
              </span>
        </v-chip>
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-chip small :color="item.isActive ? 'green' : 'red'" text-color="white" class="mr-1 mb-1"  @click="item.isActive = !item.isActive; rowChange(item.id)">
          <v-avatar left>
            <v-icon small>{{ item.isActive ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
          </v-avatar>
          <span v-if="item.isActive">
                {{ $t('management.user.active') }}
              </span>
          <span v-else>
                {{ $t('management.user.inactive') }}
              </span>
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row class="ma-2 align-center">
          <v-tooltip top v-if="item.guestFlame">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="removeCharacterFromGuests(item)" v-bind="attrs" v-on="on" class="mr-1">
                <v-icon>mdi-briefcase-minus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('character_management.remove_guest') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="saveCharacter(item)" v-bind="attrs" v-on="on"
                     :class="updatedCharacters.includes(item.id) ? 'primary' : ''">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('character_management.save_one') }}</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-if="characters.length > 0" v-model="page" :length="pageCount"></v-pagination>
    </div>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpenAddCharacter">
      <v-card>
        <v-toolbar flat class="font-weight-bold" color="primary">
          {{ $t('characters.add_character') }}
        </v-toolbar>
        <v-card-text>
          <form class="pa-2 mt-4" v-on:submit.prevent="addCharacter()">
            <v-layout column>
              <v-flex>
                <v-autocomplete label="Realm" :items="realmList" item-text="name" v-model="newCharacter.realm" return-object></v-autocomplete>
              </v-flex>
              <v-flex>
                <v-text-field :label="$t('general.misc.name')" v-model="newCharacter.name"></v-text-field>
              </v-flex>
              <v-flex>
                <v-autocomplete :label="$t('general.misc.state')" :items="states" item-text="name"
                            v-model="newCharacter.state" return-object></v-autocomplete>
              </v-flex>
              <v-flex>
                <v-autocomplete :label="$t('general.misc.default_role')" :items="defaultRoles" v-model="newCharacter.defaultRole" hide-details return-object>
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                      <span>{{ item }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                      <span>{{ item }}</span>
                    </p>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex class="text-end" mt-5>
                <v-btn text @click="isOpenAddCharacter = false">{{ $t('general.actions.cancel') }}</v-btn>
                <v-btn text color="primary" @click="addCharacter"
                       :disabled="newCharacter.state === null || newCharacter.defaultRole === null || newCharacter.realm === null || newCharacter.name.length === 0">
                  {{ $t('general.actions.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CharacterService from "@/services/character.service";
import Constants from "@/util/constants";
import SyncService from "@/services/sync.service";
import Utilities from "@/util/Utilities";
import ClassAvatar from "@/components/ClassAvatar.vue";
import RoleAvatar from "@/components/RoleAvatar.vue";
import WoWAuditService from "@/services/wow_audit.service";
import MessageService from "@/services/message.service";

export default {
  name: "CharacterManagement",
  components: {RoleAvatar, ClassAvatar},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: '',
      showDetails: false,
      headers: [
        {text: 'ID', value: "id"},
        {
          text: '', value: "class", width: "20px", align: " d-none",
          filter: value => {
            return this.filterOnClass === null || value === this.filterOnClass;
          }
        },
        {text: this.$t('general.misc.name'), value: "name"},
        {text: this.$t('general.misc.realm'), value: "realm", align: " d-none"},
        {text: this.$t('general.misc.state'), value: "state", width: '150px'},
        {text: this.$t('general.misc.roster'), value: "rosterInformation"},
        {text: this.$t('general.misc.default_role'), value: "defaultRole", width: '160px'},
        {text: this.$t('general.misc.comment'), value: "comment"},
        {
          text: this.$t('characters.synced_to_user'), value: "isSyncedToUser", extended: true,
          tooltip: this.$t('characters.synced_to_user_tooltip'),
          filter: value => {
            return !this.filterOnSyncedToUser || value === this.syncedToUserFilter;
          }
        },
        {
          text: this.$t('characters.manual_added'), value: "isManualAdded", extended: true,
          tooltip: this.$t('characters.manual_added_tooltip'),
          filter: value => {
            return !this.filterOnManualAdded || value === this.manualAddedFilter;
          }
        },
        {
          text: this.$t('management.user.overview.table.active'), value: "isActive",
          tooltip: this.$t('loot.table.attendance_relative_tooltip'),
          filter: value => {
            return !this.filterOnActive || value === this.activeFilter;
          }
        },
        {
          text: 'Feuer', value: "rosterFire", align: " d-none",
          filter: value => {
            return !this.filterOnRosterFire || value === this.filterOnRosterFire;
          }
        },
        {
          text: 'Flamme', value: "rosterFlame", align: " d-none",
          filter: value => {
            return !this.filterOnRosterFlame || value === this.filterOnRosterFlame;
          }
        },
        {
          text: 'Guest', value: "guestFlame", align: " d-none",
          filter: value => {
            return !this.filterOnGuests || value === this.filterOnGuests;
          }
        },
        {text: this.$t('general.misc.actions'), value: 'actions', sortable: false}
      ],
      charactersLoading: true,
      characters: [],
      guests: [],
      updatedCharacters: [],
      filterOnRosterFlame: false,
      filterOnRosterFire: false,
      filterOnGuests: false,
      filterOnClass: null,
      filterOnActive: true,
      activeFilter: true,
      filterOnSyncedToUser: false,
      syncedToUserFilter: true,
      filterOnManualAdded: false,
      manualAddedFilter: true,
      realmList: this.$store.getters["data/realmList"],
      states: Constants.STATES,
      defaultRoles: Constants.COMPLETE_ROLES,
      isOpenAddCharacter: false,
      newCharacter: {
        name: "",
        realm: null,
        state: "Gast",
        defaultRole: "None"
      },
      classList: this.$store.getters["data/classInfo"].list.sort(),
      classMap: this.$store.getters["data/classInfo"].map
    }
  },
  mounted() {
    this.loadCharacters();

    this.$root.$on("role_combo_changed", data => {
      this.rowChange(data.identifier);
    });
  },
  methods: {
    Utilities() {
      return Utilities
    },
    addCharacter() {
      CharacterService.addCharacter({
        name: this.newCharacter.name.toLowerCase(),
        realm: this.newCharacter.realm["slug"],
        state: this.newCharacter.state,
        defaultRole: this.newCharacter.defaultRole,
        isManualAdded: true
      }).then(data => {
        if (data) {
          this.characters.push({
            ...data,
            display: `${data["id"]} - ${data["name"]} - ${data["realm"]}`,
            rank: data["rank"] === -1 ? "n/a" : data["rank"],
            rosterInformation: {},
            inRoster: false,
            rosterFire: false,
            rosterFlame: false,
            guestFlame: false,
            isSyncedToUser: false,
            dps: 0,
            details: {},
            detailsLoaded: false,
            onDpsLoading: false,
            simResult: null,
            executionTime: 0,
          });
          this.newCharacter.name = "";
          this.newCharacter.realm = null;
          this.newCharacter.state = "Gast";
          this.newCharacter.defaultRole = "None";
          this.isOpenAddCharacter = false;
        }
      });
    },

    removeCharacterFromGuests(character){
      let guest = this.guests.find(x => x["blizzard_id"] === character.blizzardId);
      if(guest){
        WoWAuditService.removeCharacterFromGuests(Constants.ROSTER_INFO.flame.value, character, guest.id).then(success => {
          if(success){
            character.guestFlame = false;
            character.rosterFlame = false;
            character.inRoster = character.rosterFlame && character.rosterFire;
            character.state = character.rank > -1 ? "Member" : "Gast";
            this.$forceUpdate();
          }
        });
      } else {
        MessageService.error("Could not retrieve guest id. Try again later.");
      }
    },

    async loadCharacters() {
      this.charactersLoading = true;
      this.characters = await CharacterService.getCharacterList(true);
      this.guests = await WoWAuditService.getWoWAuditGuestsForRoster(Constants.ROSTER_INFO.flame.value);
      this.charactersLoading = false;
    },

    async syncCharacters() {
      await SyncService.syncCharacters().then(async () => {
        await this.loadCharacters();
      });
    },

    rowChange(characterId) {
      this.updatedCharacters.push(characterId);
    },
    saveCharacter(characterToSave) {
      CharacterService.updateCharacter(characterToSave.id, {
        defaultRole: characterToSave.defaultRole,
        state: characterToSave.state,
        comment: characterToSave.comment === null ? "" : characterToSave.comment,
        isManualAdded: characterToSave.isManualAdded,
        isActive: characterToSave.isActive
      }).then(data => {
        if (data != null) {
          let index = this.updatedCharacters.indexOf(characterToSave.id);
          if (index !== -1) {
            this.updatedCharacters.splice(index, 1);
          }
        }
      });
    },
    saveAllCharacters() {
      let uniqueIds = [...new Set(this.updatedCharacters)];
      let charactersToSave = this.characters.filter(x => uniqueIds.includes(x.id));

      charactersToSave.forEach(characterToSave => {
        CharacterService.updateCharacter(characterToSave.id, {
          defaultRole: characterToSave.defaultRole,
          state: characterToSave.state,
          comment: characterToSave.comment === null ? "" : characterToSave.comment,
          isManualAdded: characterToSave.isManualAdded,
          isActive: characterToSave.isActive
        }).then(data => {
          if (data != null) {
            this.updatedCharacters = [];
          }
        });
      });
    }
  },
  computed: {
    getHeaders(){
      if(this.showDetails){
        return this.headers;
      } else {
        return this.headers.filter(x => !x.extended);
      }
    },
    currentUser() {
      return this.$store.getters["auth/user"];
    },
  }
}
</script>

<style scoped lang="scss">
.small-chip {
  padding: 5px;
}
</style>