export default {
    BASE_64_TABLE: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '/'],
    REALM: "Blackrock",
    SIM_REFRESH_INTERVAL_IN_MS: 100,
    ROLES: ['Tank', 'Melee', 'Ranged', 'Heal'],
    COMPLETE_ROLES: ['Tank', 'Melee', 'Ranged', 'Heal', 'None'],
    STATES: ["None", "Raider", "Backup", "Bewerber", "Twink", "Member", "Gast"],
    ROSTER_LIST: ["fire", "flame"],
    ROSTER_INFO: {
        fire: {
            description: "Feuer",
            value: "fire",
            baseUrl: "https://wowaudit.com/eu/blackrock/zirkel-des-phoenix/feuer",
            color: "primary"
        },
        flame: {
            description: "Flamme",
            value: "flame",
            baseUrl: "https://wowaudit.com/eu/blackrock/zirkel-des-phoenix/flamme",
            color: "#298F85"
        }
    }
};