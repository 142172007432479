<template>
  <div v-if="vModel !== null">
    <h3>{{ $t('simulation.settings') }}</h3>
    <v-divider class="mb-2"></v-divider>
    <p><b>Fight Style:</b> {{vModel.SETTINGS.FIGHT_STYLE.VALUE}}</p>
    <p><b>Target Count:</b> {{vModel.SETTINGS.TARGET_COUNT.VALUE}}</p>
    <p><b>Iterations:</b> {{vModel.SETTINGS.ITERATIONS.VALUE}}</p>
    <p><b>Max Time:</b> {{vModel.SETTINGS.MAX_TIME.VALUE}}</p>

    <h3 class="mt-4">{{ $t('simulation.consumables.title') }}</h3>
    <v-divider class="mb-2"></v-divider>
    <div v-for="(consumable, index) in consumables" :key="index" class="detail-item-wrapper">
      <span v-if="consumable.value !== null">
        <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
          <a :href="`https://www.wowhead.com/item=${consumable.value.ID}`" v-bind:data-wowhead="`crafting-quality=${consumable.value.QUALITY}`" target="_blank">
            <img :src="`https://wow.zamimg.com/images/wow/icons/large/${consumable.value.MEDIA}.jpg`" style="width: 25px; height:25px;" alt="wow-icon">
          </a><br>
        </div>
        <span class="detail-item-text"><b>{{$t(`simulation.consumables.${consumable.category}.${consumable.value.LANGUAGE}`)}}</b></span>
        <img src="../assets/img/quality/1.png" v-if="consumable.value.QUALITY === 1" class="ml-1" alt="quality-1">
        <img src="../assets/img/quality/2.png" v-if="consumable.value.QUALITY === 2" class="ml-1" alt="quality-2">
        <img src="../assets/img/quality/3.png" v-if="consumable.value.QUALITY === 3" class="ml-1" alt="quality-3">
      </span>
    </div>

    <h3 class="mt-4">{{ $t('simulation.trinkets.title') }}</h3>
    <v-divider class="mb-2"></v-divider>
    <div class="detail-item-wrapper">
      <div class="detail-item-text">
        <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
          <a v-bind:href="`https://www.wowhead.com/spell=${vModel.TRINKETS.CHROMATIC_ESSENCE.VALUE.ID}`" :data-wowhead="`domain=${$i18n.locale}`" target="_blank">
            <img v-bind:src="`https://wow.zamimg.com/images/wow/icons/large/${vModel.TRINKETS.CHROMATIC_ESSENCE.VALUE.MEDIA}.jpg`" alt="wow-icon"
                 style="width: 25px; height:25px;">
          </a>
        </div>
      </div>
      <span >
        <b>{{ $t('simulation.trinkets.chromatic_essence.title') }}</b><br>
        {{$t(`simulation.trinkets.chromatic_essence.${vModel.TRINKETS.CHROMATIC_ESSENCE.VALUE.LANGUAGE}`)}}
        <br>
        {{ $t('simulation.trinkets.chromatic_essence.ally_buffs') }} = {{vModel.TRINKETS.CHROMATIC_ESSENCE.USE_ALLY_BUFFS ? $t('general.true') : $t('general.false')}}
      </span>
    </div>

    <h3 class="mt-4">{{ $t('simulation.buffs.title') }}</h3>
    <v-divider class="mb-2"></v-divider>
    <div v-for="buff in buffs" :key="buff.id" class="detail-item-wrapper">
      <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
        <a v-bind:href="`https://www.wowhead.com/spell=${buff.ID}`" :data-wowhead="`domain=${$i18n.locale}`" target="_blank">
          <img v-bind:src="`https://wow.zamimg.com/images/wow/icons/large/${buff.MEDIA}.jpg`" alt="wow-icon"
               style="width: 25px; height:25px;">
        </a>
      </div>
      <span class="detail-item-text"><b>{{$t(`simulation.buffs.${buff.KEY}`)}}</b></span>
    </div>
  </div>
  <div v-else>
    <p>No sim details available</p>
  </div>
</template>

<script>
export default {
  name: "SimObjectDetails",
  props: ["model"],
  data(){
    return {
      vModel: null,
      buffs: [],
      consumables: []
    }
  },
  mounted() {
    this.vModel = this.model;
    Object.keys(this.vModel.BUFFS).forEach(key => {
      if(this.vModel.BUFFS[key].VALUE){
        this.buffs.push(this.vModel.BUFFS[key]);
      }
    });

    this.consumables.push({
      key: "Flask",
      category: "flasks",
      value: this.vModel.CONSUMABLES.FLASK.VALUE
    });
    this.consumables.push({
      key: "Potion",
      category: "potions",
      value: this.vModel.CONSUMABLES.POTION.VALUE
    });
    this.consumables.push({
      key: "Food",
      category: "food",
      value: this.vModel.CONSUMABLES.FOOD.VALUE
    });
    this.consumables.push({
      key: "Augment Rune",
      category: "augment_runes",
      value: this.vModel.CONSUMABLES.AUGMENT_RUNE.VALUE
    });
    this.consumables.push({
      key: "Weapon Rune",
      category: "weapon_runes",
      value: this.vModel.CONSUMABLES.WEAPON_RUNE.VALUE
    });
  }
}
</script>


<style scoped>
.detail-item-wrapper {
  display: table;
}

.detail-item-text{
  display: table-cell;
  vertical-align: middle;
}
</style>