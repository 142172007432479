const specs = {
    62: {
        name: "arcane",
        type: "range",
        main: "int"
    },
    63: {
        name: "fire",
        type: "range",
        main: "int"
    },
    64: {
        name: "frost",
        type: "range",
        main: "int"
    },
    65: {
        name: "holy",
        type: "range",
        main: "int"
    },
    66: {
        name: "protection",
        type: "melee",
        main: "str"
    },
    70: {
        name: "retribution",
        type: "melee",
        main: "str"
    },
    71: {
        name: "arms",
        type: "melee",
        main: "str"
    },
    72: {
        name: "fury",
        type: "melee",
        main: "str"
    },
    73: {
        name: "protection",
        type: "melee",
        main: "str"
    },
    102: {
        name: "balance",
        type: "range",
        main: "int"
    },
    103: {
        name: "feral",
        type: "melee",
        main: "agi"
    },
    104: {
        name: "guardian",
        type: "melee",
        main: "agi"
    },
    105: {
        name: "restoration",
        type: "heal",
        main: "int"
    },
    250: {
        name: "blood",
        type: "melee",
        main: "str"
    },
    251: {
        name: "blood",
        type: "melee",
        main: "str"
    },
    252: {
        name: "unholy",
        type: "melee",
        main: "str"
    },
    253: {
        name: "beast mastery",
        type: "range",
        main: "agi"
    },
    254: {
        name: "marksmanship",
        type: "range",
        main: "agi"
    },
    255: {
        name: "survival",
        type: "range",
        main: "agi"
    },
    256: {
        name: "discipline",
        type: "heal",
        main: "int"
    },
    257: {
        name: "holy",
        type: "heal",
        main: "int"
    },
    258: {
        name: "shadow",
        type: "range",
        main: "int"
    },
    259: {
        name: "assassination",
        type: "melee",
        main: "agi"
    },
    260: {
        name: "outlaw",
        type: "melee",
        main: "agi"
    },
    261: {
        name: "subtlety",
        type: "melee",
        main: "agi"
    },
    262: {
        name: "elemental",
        type: "range",
        main: "int"
    },
    263: {
        name: "enhancement",
        type: "melee",
        main: "agi"
    },
    264: {
        name: "restoration",
        type: "heal",
        main: "int"
    },
    265: {
        name: "affliction",
        type: "range",
        main: "int"
    },
    266: {
        name: "demonology",
        type: "range",
        main: "int"
    },
    267: {
        name: "destruction",
        type: "range",
        main: "int"
    },
    268: {
        name: "brewmaster",
        type: "melee",
        main: "agi"
    },
    270: {
        name: "mistweaver",
        type: "heal",
        main: "int"
    },
    269: {
        name: "windwalker",
        type: "melee",
        main: "agi"
    },
    577: {
        name: "havoc",
        type: "melee",
        main: "agi"
    },
    581: {
        name: "vengeance",
        type: "melee",
        main: "agi"
    },
    1467: {
        name: "devastation",
        type: "range",
        main: "int"
    },
    1468: {
        name: "preservation",
        type: "heal",
        main: "int"
    },
    1473: {
        name: "augmentation",
        type: "range",
        main: "int"
    },
}

class ClassUtility {
    resolveStatsForSpecType(character){
        let specId = character.details.activeSpec.id;

        if(specs[specId]){
            let type = specs[specId].type;

            character.details.stats.main = specs[specId].main;
            if(type === "melee"){
                character.details.stats.haste = character.details.stats.melee_haste;
                character.details.stats.crit = character.details.stats.melee_crit;
            } else {
                character.details.stats.haste = character.details.stats.spell_haste;
                character.details.stats.crit = character.details.stats.spell_crit;
            }
        } else {
            character.details.stats.main = 0;
            character.details.stats.haste = 0;
            character.details.stats.haste = 0;
        }
    }
}

export default new ClassUtility();
