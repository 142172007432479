<template>
  <div>
    <h3>
      {{ $t('management.user.title') }}
    </h3>
    <v-divider></v-divider>
    <v-tabs right v-model="tab" class="mt-2">
      <v-tab key="users-tab">{{ $t('management.user.overview.title') }}</v-tab>
      <v-tab key="roles-tab">{{ $t('management.user.roles.title') }}</v-tab>
      <v-tab key="characters-tab">{{ $t('management.character_assign.title') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item key="users-tab" class="ma-2">
        <v-row class="pa-3 align-center">
          <v-col>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.misc.search')" clearable></v-text-field>
          </v-col>
          <v-col>
            <v-row class="align-center">
              <v-chip :color="!filterOnGeneric ? 'grey' : genericFilter ? 'green' : 'red'" text-color="white" class="ml-2" @contextmenu="$event.preventDefault(); genericFilter = !genericFilter" @click="filterOnGeneric = !filterOnGeneric">
                <v-avatar left>
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>
                <span v-if="genericFilter">
                {{ $t('management.user.generic') }}
              </span>
                <span v-else>
                {{ $t('management.user.not_generic') }}
              </span>
              </v-chip>
              <v-chip :color="!filterOnActive ? 'grey' : activeFilter ? 'green' : 'red'" text-color="white" class="ml-2" @contextmenu="$event.preventDefault(); activeFilter = !activeFilter" @click="filterOnActive = !filterOnActive">
                <v-avatar left>
                  <v-icon>{{ activeFilter ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
                </v-avatar>
                <span v-if="activeFilter">
                {{ $t('management.user.active') }}
              </span>
                <span v-else>
                {{ $t('management.user.inactive') }}
              </span>
              </v-chip>
              <v-chip :color="!filterOnTemporary ? 'grey' : temporaryFilter ? 'green' : 'red'" text-color="white" class="ml-2" @contextmenu="$event.preventDefault(); temporaryFilter = !temporaryFilter" @click="filterOnTemporary = !filterOnTemporary">
                <v-avatar left>
                  <v-icon>mdi-clock-outline</v-icon>
                </v-avatar>
                <span v-if="temporaryFilter">
                {{ $t('management.user.temporary') }}
              </span>
                <span v-else>
                {{ $t('management.user.not_temporary') }}
              </span>
              </v-chip>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table :headers="headers.user" :items="users" show-expand :expanded.sync="expanded" item-key="id" class="mt-3"
                       :page.sync="userPage" :items-per-page="itemsPerPage" hide-default-footer
                      @page-count="pageCount = $event" :search="search" :custom-filter="Utilities().searchNormalized">
          <template v-slot:item.characterCount="{ item }">
            {{item.characters.length}}
          </template>
          <template v-slot:item.tags="{ item }">
            <v-chip small :color="item.isActive ? 'green' : 'red'" text-color="white" class="mr-1 mb-1">
              <v-avatar left>
                <v-icon small>{{ item.isActive ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
              </v-avatar>
              <span v-if="item.isActive">
                {{ $t('management.user.active') }}
              </span>
              <span v-else>
                {{ $t('management.user.inactive') }}
              </span>
            </v-chip>
            <v-chip small color="orange" text-color="white" v-if="item.isGeneric" class="mr-1 mb-1">
              <v-avatar left>
                <v-icon small>mdi-checkbox-marked-circle</v-icon>
              </v-avatar>
              {{ $t('management.user.generic') }}
            </v-chip>
            <v-chip small color="blue" text-color="white" v-if="item.isTemporary" class="mr-1 mb-1">
              <v-avatar left>
                <v-icon small>mdi-clock-outline</v-icon>
              </v-avatar>
              {{ $t('management.user.temporary') }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top v-if="enableSignInAs && item.id !== currentUser.data.id">
              <template v-slot:activator="{ on }">
                <v-btn @click="signInAs(item.id)" icon
                       color="primary" v-on="on">
                  <v-icon>mdi-login</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.user.overview.tooltip.sign_in_as') }}</span>
            </v-tooltip>
            <v-tooltip top v-if="!item.isActive && activateUsers" >
              <template v-slot:activator="{ on }">
                <v-btn @click="changeActivationState(item, true)" icon color="primary" v-on="on">
                  <v-icon>mdi-sleep-off</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.user.overview.tooltip.activate_user') }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.isActive && deleteUsers">
              <template v-slot:activator="{ on }">
                <v-btn @click="changeActivationState(item, false)" icon color="primary" v-on="on">
                  <v-icon>mdi-sleep</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.user.overview.tooltip.deactivate_user') }}</span>
            </v-tooltip>
            <v-tooltip top  v-if="deleteUsers">
              <template v-slot:activator="{ on }">
                <v-btn @click="openDeleteUserConfirmation(item)" icon color="primary" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.user.overview.tooltip.delete_user') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.user.length" class="pa-3">
              <user-details-view v-bind:user="item"></user-details-view>
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-if="users.length > 0" v-model="userPage" :length="pageCount"></v-pagination>
        </div>
      </v-tab-item>
      <v-tab-item key="roles-tab">
        <div class="pa-2">
          <h3 class="pa-3">{{ $t('management.user.roles.title') }}</h3>
          <v-row class="ma-2 justify-end">
            <v-btn color="primary" @click="isOpenCreateRole = true" :disabled="!canEditRoles">
              <v-icon>mdi-plus-box</v-icon>
              {{ $t('general.actions.create') }}
            </v-btn>
          </v-row>
          <v-expansion-panels>
            <v-expansion-panel v-for="role in roles" :key="role.id" class="secondary lighten-1">
              <v-expansion-panel-header>
                <span>
                  <v-icon class="mr-2">mdi-account-key</v-icon>
                  <b>{{ role.name.toUpperCase() }}</b>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pa-2">
                  <v-checkbox v-for="mappedRight in role.mappedRights" :key="mappedRight.id"
                              v-model="mappedRight.selected"
                              :label="mappedRight.name.toUpperCase()" class="ml-3"
                              :disabled="role.readonly || !canEditRoles">
                  </v-checkbox>
                  <v-row class="ma-2 mt-2 justify-end" v-if="!role.readonly">
                    <v-btn class="mr-2" color="primary" @click="openDeleteRoleConfirmation(role)" :disabled="!canEditRoles">
                      <v-icon>mdi-delete</v-icon>
                      {{ $t('general.actions.delete') }}
                    </v-btn>
                    <v-btn color="primary" @click="updateRole(role)" :disabled="!canEditRoles">
                      <v-icon>mdi-content-save</v-icon>
                      {{ $t('general.actions.save') }}
                    </v-btn>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-tab-item>
      <v-tab-item key="characters-tab" class="ma-2">
        <v-row>
          <v-col class="col-12 col-md-6 col-lg-6 col-sm-6">
            <v-row class="pa-2">
              <v-text-field class="pa-2" v-model="character.search" append-icon="mdi-magnify" :label="$t('general.misc.search')" clearable hide-details></v-text-field>
              <v-chip :color="!character.filterOnActive ? 'grey' : character.activeFilter ? 'green' : 'red'" text-color="white" class="mt-3 ml-2 mr-2" @contextmenu="$event.preventDefault(); character.activeFilter = !character.activeFilter" @click="character.filterOnActive = !character.filterOnActive">
                <v-avatar left>
                  <v-icon>{{ character.activeFilter ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
                </v-avatar>
                <span v-if="character.activeFilter">
                {{ $t('management.user.active') }}
              </span>
                <span v-else>
                {{ $t('management.user.inactive') }}
              </span>
              </v-chip>
            </v-row>

            <v-data-table :headers="character.headers" :items="filterCharactersWithoutUser" item-key="id"
                          :search="character.search" :loading="isLoading"
                          show-select v-model="character.selected"
                          :page.sync="character.page" :items-per-page="character.itemsPerPage" hide-default-footer
                          @page-count="character.pageCount = $event" :custom-filter="Utilities().searchNormalized">

              <template v-slot:item.class="{ item }">
                <class-avatar v-bind:type="'class'" v-bind:value="item.class.toLowerCase()" v-bind:size="30"/>
              </template>
              <template v-slot:item.name="{ item }">
                <span :class="item.class.toLowerCase().replaceAll(' ', '-')"><b>{{ item.name }}</b></span>
              </template>
              <template v-slot:item.isActive="{ item }">
                <v-chip small :color="item.isActive ? 'green' : 'red'" text-color="white" class="mr-1 mb-1">
                  <v-avatar left>
                    <v-icon small>{{ item.isActive ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
                  </v-avatar>
                <span v-if="item.isActive">
                  {{ $t('management.user.active') }}
                </span>
                <span v-else>
                  {{ $t('management.user.inactive') }}
                </span>
                </v-chip>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-if="characters.length > 0" v-model="character.page" :length="character.pageCount" total-visible="3"></v-pagination>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="col-12 col-md-6 col-lg-6 col-sm-6">
            <v-tabs right v-model="subTab">
              <v-tab key="create-tab">{{ $t('management.user.character_assign.new_user.title') }}</v-tab>
              <v-tab key="update-tab">{{ $t('management.user.character_assign.existing_user.title') }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="subTab" class="mt-3">
              <v-tab-item key="create-tab" class="ma-2">
                <v-card>
                  <v-card-text class="secondary lighten-1">
                    <p ><v-icon class="mb-1">mdi-information</v-icon> <b>{{  $t('general.misc.information') }}</b></p>
                    <p>{{ $t('management.user.character_assign.new_user.information.text_1')}}</p>
                  </v-card-text>
                </v-card>
                <v-row class="px-3 py-1 mt-2">
                  <v-text-field class="ma-2" :label="$t('general.misc.name')" v-model="createUser.name" hide-details></v-text-field>
                </v-row>
                <v-row class="px-3 py-1">
                  <v-autocomplete :items="character.selected" v-model="createUser.mainCharacter" :label="$t('management.character_assign.main_character')" hide-details return-object clearable
                                  :filter="Utilities().searchNormalizedCharacter" class="ma-2" item-text="display">
                    <template v-slot:selection="{ item }">
                      <p class="font-weight-medium mb-0">
                        <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                      </p>
                    </template>
                    <template v-slot:item="{ item }">
                      <p class="font-weight-medium mb-0">
                        <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                      </p>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-row class="px-3 py-1 mt-3 justify-end">
                  <v-btn class="primary" @click="createNewTemporaryUser" :disabled="createUser.name.length === 0 || createUser.mainCharacter === null">
                    {{ $t('management.character_assign.create_temporary')}}
                  </v-btn>
                </v-row>
              </v-tab-item>
              <v-tab-item key="update-tab" class="ma-2">
                <v-card>
                  <v-card-text class="secondary lighten-1">
                    <p ><v-icon class="mb-1">mdi-information</v-icon> <b>{{  $t('general.misc.information') }}</b></p>
                    <p>{{ $t('management.user.character_assign.existing_user.information.text_1')}}</p>
                  </v-card-text>
                </v-card>
                <v-row class="px-3 py-1 mt-2">

                </v-row>
                <v-autocomplete :label="$t('general.misc.user')" :items="users" v-model="updateUser.selected" item-text="username" class="mt-3 px-2"
                                return-object></v-autocomplete>
                <v-row class="pa-3 justify-end">
                  <v-btn class="primary" @click="addCharactersToExistingUser">{{ $t('management.user.character_assign.existing_user.add_characters')}}</v-btn>
                </v-row>

                <div v-if="updateUser.selected">
                  <h4 class="px-2 mt-2">{{ $t('management.user.character_assign.existing_user.user_characters') }}</h4>
                  <v-list two-line>
                    <v-list-item class="mt-2" :class="`${character.class.toLowerCase().replaceAll(' ', '-')}-bg`"
                                 v-for="character of updateUser.selected.characters" :key="character.id" >
                      <class-avatar :size="30" :type="'class'" :value="character.class.toLowerCase()" class="mr-3"></class-avatar>
                      <v-list-item-content class="py-0">
                        <v-list-item-title>
                          <b :class="character.class.toLowerCase().replaceAll(' ', '-')">{{character.name}}</b>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="updateUser.selected.mainCharacter === character.id">
                            Main
                          </div>
                          <div v-else>
                            Twink
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="openUnlinkConfirmation(updateUser.selected, character)" v-bind="attrs" v-on="on" :disabled="character.userId !== null">
                              <v-icon>mdi-link-off</v-icon>
                            </v-btn>
                          </template>
                          <span>{{  $t('management.user.character_assign.existing_user.delete_character_link') }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpenCreateRole">
      <v-card>
        <v-toolbar flat class="font-weight-bold" color="primary">
          {{$t('management.user.roles.new_role')}}
        </v-toolbar>
        <v-card-text>
          <form class="pa-2 mt-4" v-on:submit.prevent="createRole()">
            <v-layout column>
              <v-flex>
                <v-text-field v-model="newRole" :label="$t('general.misc.role')" required type="text"></v-text-field>
                <v-text-field v-model="newDescription" :label="$t('general.misc.description')" type="text"></v-text-field>
              </v-flex>
              <v-flex class="text-end" mt-5>
                <v-btn text @click="isOpenCreateRole = false">{{$t('general.actions.cancel')}}</v-btn>
                <v-btn text color="primary" type="submit" :disabled="newRole.length === 0">{{$t('general.actions.create')}}</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirmation-dialog identifier="user" :question="$t('management.user.overview.delete_confirmation')" :confirmation-handler="deleteUser"></confirmation-dialog>
    <confirmation-dialog identifier="role" :question="$t('management.user.roles.delete_confirmation')" :confirmation-handler="deleteRole"></confirmation-dialog>
    <confirmation-dialog identifier="characterUnlink" :question="$t('management.character_assign.unlink_confirmation')" :confirmation-handler="unlinkCharacterFromUser"></confirmation-dialog>
  </div>
</template>

<script>
import CharacterService from "@/services/character.service";
import ClassAvatar from "@/components/ClassAvatar.vue";
import UserService from "@/services/user.service";
import DataService from "@/services/data.service";
import MessageService from "@/services/message.service";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import UserDetailsView from "@/components/UserDetailsView.vue";
import Utilities from "@/util/Utilities";

export default {
  name: "UserManagement",
  components: {UserDetailsView, ConfirmationDialog, ClassAvatar},
  data() {
    return {
      isLoading: true,
      tab: 0,
      subTab: 0,
      temporaryUserAction: "update",
      expanded: [],
      pageCount: 0,
      itemsPerPage: 50,
      userPage: 1,
      rolePage: 1,
      search: "",
      headers: {
        user: [
          {text: this.$t('management.user.overview.table.name'), value: "username"},
          {text: this.$t('management.user.overview.table.character_count'), value: "characterCount"},
          {text: this.$t('management.user.overview.table.mail'), value: "email"},
          {text: this.$t('management.user.overview.table.battle_tag'), value: "battleTag"},
          {text: this.$t('general.misc.state'), value: "tags", sortable: false},
          {text: this.$t('management.user.overview.table.generic'), value: "isGeneric", align: " d-none",
            filter: value => {
              return !this.filterOnGeneric || value === this.genericFilter;
            }
          },
          {text: this.$t('management.user.overview.table.active'), value: "isActive", align: " d-none",
            filter: value => {
              return !this.filterOnActive || value === this.activeFilter;
            }
          },
          {text: this.$t('management.user.overview.table.temporary'), value: "isTemporary", align: " d-none",
            filter: value => {
              return !this.filterOnTemporary || value === this.temporaryFilter;
            }
          },
          {text: this.$t('management.user.overview.table.actions'), value: "actions", sortable: false},
          {text: '', value: 'data-table-expand'}
        ],
        role: [
          {text: this.$t('general.misc.name'), value: "name"},
          {text: '', value: 'data-table-expand'}
        ]
      },
      characters: [],
      character: {
        selected: [],
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        filterOnActive: true,
        activeFilter: true,
        headers: [
          {text: '', value: 'actions', sortable: false},
          {text: '', value: "class", width: "20px"},
          {text: this.$t('general.misc.name'), value: "name"},
          {text: this.$t('general.misc.realm'), value: "realm"},
          {text: this.$t('general.misc.state'), value: "isActive",
            filter: value => {
              return !this.character.filterOnActive || value === this.character.activeFilter;
            }
          },
          {text: this.$t('general.misc.class'), align: " d-none", value: "class"},
          {text: this.$t('general.misc.spec'), align: " d-none", value: "spec"},

        ],
      },

      createUser: {
        name: "",
        mainCharacter: null
      },
      updateUser: {
        selected: null,
      },

      unlinkInfo: {
        user: null,
        character: null
      },

      users: [],
      userToDelete: null,
      filterOnToActivate: false,
      filterOnGeneric: false,
      genericFilter: false,
      filterOnActive: false,
      activeFilter: false,
      filterOnTemporary: true,
      temporaryFilter: false,

      grmData: "",

      roles: [],
      rights: [],
      isOpenCreateRole: false,
      roleToDelete: null,
      newRole: "",
      newDescription: "",
    }
  },
  async mounted() {
    if (!this.canEditUsers && this.$route.path !== '/home') {
      await this.$router.push('/home');
    } else {
      await this.loadCharacters();
      await this.loadUsers();
      this.users = this.users.sort((a,b) => a.username.localeCompare(b.username));
      this.rights = await DataService.getRights()
      this.roles = await DataService.getRoles();
      this.roles = this.roles.sort((a,b) => a.name.localeCompare(b.name));
      this.roles.forEach(role => {
        role.readonly = role.name === 'admin';
        role.mappedRights = this.rights.map(right => ({
          ...right,
          selected: role.rights.find(x => x.id === right.id) !== undefined
        }));
      });

      this.users.forEach(user => {
        const userRoles = JSON.parse(JSON.stringify(user.roles));
        user.roles = this.roles.map(role => ({
          ...role,
          selected: userRoles.find(ur => ur.id === role.id) !== undefined
        }));
      });

      this.isLoading = false;
    }
  },
  methods: {
    Utilities() {
      return Utilities
    },

    async loadUsers(){
      this.users = await UserService.getAll(true, true);
    },
    async loadCharacters(){
      this.characters = await CharacterService.getCharacterList(true);
    },
    createNewTemporaryUser(){
      UserService.createTemporaryUser(this.createUser.name, this.character.selected, this.createUser.mainCharacter).then(data => {
        if(data){
          if(data.charactersNotAdded.length > 0){
            MessageService.warn(`The following characters could not be added to temporary user: [${data.charactersNotAdded.map(x => x.name).join(",")}]`);
          }

          this.loadUsers();
          this.loadCharacters();

          this.createUser.name = "";
          this.createUser.mainCharacter = null;
          this.character.selected = [];
        }
      });
    },
    addCharactersToExistingUser(){
      let promiseList = [];
      this.character.selected.forEach(selectedCharacter => {
        promiseList.push(this.updateCharacter(this.updateUser.selected.id, selectedCharacter, this.updateUser.selected.characters));
      });

      Promise.all(promiseList).then(async () => {
        this.character.selected = [];
        await this.loadCharacters();
      });
    },

    updateCharacter(userId, character, characterList){
      return new Promise(resolve => {
        CharacterService.updateCharacter(character.id, {
          userId: userId
        }).then(data => {
          if(data){
            characterList.push(character);
          }
          resolve();
        });
      });
    },

    openUnlinkConfirmation(user, character){
      this.unlinkInfo.character = character;
      this.unlinkInfo.user = user;
      this.$root.$emit('characterUnlink_confirmationOpen', true);
    },
    unlinkCharacterFromUser(){
      CharacterService.updateCharacter(this.unlinkInfo.character.id, {
        userId: null
      }).then(data => {
        if(data){
          let index = this.updateUser.selected.characters.findIndex(x => x.id === this.unlinkInfo.character.id);
          if (index !== -1) {
            this.updateUser.selected.characters.splice(index, 1);
            this.unlinkInfo.character = null;
            this.unlinkInfo.user = null;
            this.loadCharacters();
          }
        }
        this.$root.$emit('characterUnlink_confirmationOpen', false);
      });
    },

    signInAs(userId) {
      this.$store.dispatch("auth/signInAs", {
        id: userId
      }).then(user => {
        MessageService.success(`Now signed in with user '${user.data.username}'`);
        if (!this.currentUser?.rights.includes("READ_ADMIN_PANEL")) {
          this.$router.push('/home');
        }
      }, (error) => {
        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      });
    },
    changeActivationState: function (user, isActive) {
      UserService.changeActivationState(user.id, isActive).then(success => {
        if(success){
          user.isActive = isActive;
          this.$forceUpdate();
        }
      });
    },
    deleteUser: function () {
      if(this.userToDelete != null){
        UserService.deleteUser(this.userToDelete).then(deleted => {
          if(deleted){
            let index = this.users.findIndex(u => u.id === this.userToDelete.id);
            if(index !== -1){
              this.users.splice(index, 1);
              this.userToDelete = null;
              this.$root.$emit('user_confirmationOpen', false);
              this.$forceUpdate();
            }
          }
        });
      }
    },
    openDeleteUserConfirmation(user){
      this.userToDelete = user;
      this.$root.$emit('user_confirmationOpen', true);
    },

    createRole(){
      DataService.createRole(this.newRole, this.newDescription).then(data => {
        if(data !== null){
          data.rights = [];
          data.readonly = false;
          data.mappedRights = this.rights.map(r => ({
            ...r,
            selected: false
          }));

          this.roles.push(data);
          this.roles = this.roles.sort((a,b) => a.name.localeCompare(b.name));

          this.isOpenCreateRole = false;
        }
      });
    },
    deleteRole: function () {
      if(this.roleToDelete != null){
        DataService.deleteRole(this.roleToDelete.id).then(deleted => {
          if(deleted){
            let index = this.roles.findIndex(u => u.id === this.roleToDelete.id);
            if(index !== -1){
              this.roles.splice(index, 1);
              this.roleToDelete = null;
              this.$root.$emit('role_confirmationOpen', false);
              this.$forceUpdate();
            }
          }
        });
      }
    },
    openDeleteRoleConfirmation(role){
      this.roleToDelete = role;
      this.$root.$emit('role_confirmationOpen', true);
    },

    updateRole(role) {
      let rights = role.mappedRights.filter(x => x.selected);
      DataService.updateRole(role.id, rights).then(data => {
        if (data) {
          role = data;
          MessageService.success("Role successfully updated");
        }
      });
    }
    },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    canEditUsers() {
      return this.currentUser && this.currentUser?.rights.includes("EDIT_USER_DATA");
    },
    canEditRoles() {
      return this.currentUser && this.currentUser?.rights.includes("EDIT_ADMIN_DATA");
    },
    filterCharactersWithoutUser(){
      return this.characters.filter(c => c.userId == null);
    },
    enableSignInAs() {
      let enableSignIs = false;
      let appConfigs = this.$store.getters["data/appConfig"];
      if (appConfigs.length) {
        enableSignIs = appConfigs.find(x => x.key === "ENABLE_SIGN_IN_AS" && x.value === "true")
            && this.currentUser?.rights.includes("SIGN_IN_AS");
      }

      return enableSignIs;
    },
    activateUsers() {
      return this.currentUser && this.currentUser?.rights.includes("EDIT_USER_DATA");
    },
    deleteUsers() {
      return this.currentUser && this.currentUser?.rights.includes("DELETE_USERS");
    }
  }
}
</script>

<style scoped lang="scss">
.v-list--two-line .v-list-item, .v-list-item--two-line {
  min-height: 50px;
}
</style>