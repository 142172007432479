import SimulationOptions from "@/simulation/SimulationOptions";

export default class SimulationObject {
    OPTIONS;
    CHARACTER;
    TALENTS = "";
    EQUIPMENT = [];

    constructor() {
        this.EQUIPMENT = [];
        this.TALENTS = "";

        this.OPTIONS = new SimulationOptions();
    }

    toJson(){
        return {
            options: this.OPTIONS.toJson(),
            character: this.CHARACTER.toJson(),
            equip: this.EQUIPMENT,
            talents: this.TALENTS
        }
    }
}

