export default class SimulationVersion {
    LATEST_VERSION_INFO;
    SIM_VERSION_ENABLE_SPECIFIC;
    SIM_VERSIONS;
    SIM_VERSION;
    SIM_VERSION_MODE;
    constructor() {
        this.SIM_VERSION_MODE = {
           VALUE: "latest",
           DEFAULT: "latest"
        };
        this.SIM_VERSIONS = [];
        this.SIM_VERSION = null;
        this.SIM_VERSION_ENABLE_SPECIFIC = false;
    }

    updateWithData(simVersionData){
        if(simVersionData != null){
            this.LATEST_VERSION_INFO = simVersionData.latestVersion;
            this.SIM_VERSIONS = simVersionData.builds;
            this.SIM_VERSION_ENABLE_SPECIFIC = simVersionData.builds.length > 0;
            if(this.SIM_VERSION_ENABLE_SPECIFIC){
                this.SIM_VERSION = simVersionData.builds[0];
            }
        } else {
            this.LATEST_VERSION_INFO = "Version info not available";
            this.SIM_VERSIONS = [];
            this.SIM_VERSION_ENABLE_SPECIFIC = false;
        }
    }

    toJson() {
        return {
            mode: this.SIM_VERSION_MODE.VALUE,
            data: this.SIM_VERSION
        };
    }

    load(jsonObj) {
        if (jsonObj) {
            this.resolveSetting(this.SIM_VERSION_MODE, jsonObj.mode);
            this.SIM_VERSION = jsonObj.data;
        } else {
            this.SIM_VERSION_MODE.VALUE = this.SIM_VERSION_MODE.DEFAULT;
            this.SIM_VERSION = null;
        }
    }

    resolveSetting(context, value){
        if(value !== undefined){
            context.VALUE = value;
        } else {
            context.VALUE = context.DEFAULT;
        }
    }
}