<template>
  <div>
    <h3>{{ $t('raids.next_raid') }}</h3>
    <div v-if="nextRaids.length === 0">
      <v-progress-circular :size="25" color="primary" class="ma-2" indeterminate v-if="nextRaidsLoading"></v-progress-circular>
      <p v-if="!nextRaidsLoading">
        <v-icon>mdi-close-circle</v-icon>
        {{ $t('raids.no_next') }}
      </p>
    </div>
    <div>
      <v-row class="ma-2 align-center" v-for="nextRaid in nextRaids" :key="nextRaid.id">
        <p class="my-0 px-0">
          <v-chip label :color="nextRaid.color" outlined small class="mr-2">
            <v-icon left small class="noHover">mdi-fire</v-icon>
            {{ Constants.ROSTER_INFO.flame.description }}
          </v-chip>
          <a :href="nextRaid.url" :class="nextRaid.roster" target="_blank">
            {{ nextRaid.instance }} ({{ nextRaid.difficulty }}) |
            {{ new Date(nextRaid.date).toLocaleDateString() }} |
            {{ new Date(nextRaid.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) }} - {{ new Date(nextRaid.end).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) }}
          </a>
        </p>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openSelectionsImage(nextRaid['selections_image'])" v-if="nextRaid['selections_image']" v-bind="attrs"  v-on="on" class="ml-2">
              <v-icon small>mdi-image-check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('raids.open_image')}}</span>
        </v-tooltip>

        <v-expansion-panels class="mt-2">
          <v-expansion-panel v-for="encounter in nextRaid?.encounters" :key="encounter.name">
            <v-expansion-panel-header>
              {{ encounter.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <group-setup :entries="encounter.selections" :addition-allowed="false" :selection-change-allowed="false"></group-setup>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>

    <v-row class="mx-1 mt-3 align-end">
      <h3 class="mt-3"> {{ $t('raids.calendar.title') }}</h3>
      <v-spacer></v-spacer>
      <v-chip label class="noHover" color="primary" outlined>
        <v-icon left>mdi-fire</v-icon>
        Feuer
      </v-chip>
      <v-chip label class="ml-2 noHover" color="#298F85" outlined>
        <v-icon left>mdi-fire</v-icon>
        Flamme
      </v-chip>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="white darken-2" @click="setToday">
              {{ $t('raids.calendar.today') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn fab text small color="white darken-2" @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn fab text small color="white darken-2" @click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar ref="calendar" v-model="focus" color="primary" :events="raids" event-color="red" :type="type"
                      :locale="$i18n.locale" @click:event="showEvent" :weekdays="weekdays">
          </v-calendar>
          <v-dialog v-model="isOpenEventDetails" max-width="1000px">
            <v-card flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title>
                  <span>
                    <v-icon>mdi-sword-cross</v-icon>
                    {{ selectedEvent.name }}
                        <v-chip label class="ml-2 noHover" color="white" outlined>
                          <v-icon left>mdi-fire</v-icon>
                          {{ selectedEvent.roster === "fire" ? "Feuer" : "Flamme"}}
                        </v-chip>
                  </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="openEvent(selectedEvent)" v-bind="attrs" v-on="on">
                      <v-icon>mdi-share</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('raids.open.wow_audit') }}</span>
                </v-tooltip>
                <v-btn icon @click="isOpenEventDetails = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="pa-3">
                <div v-if="selectedEvent.source === 'wow-audit'">
                  <v-row class="ma-2 align-center">
                    <v-icon class="mr-2">mdi-skull</v-icon>
                    {{ selectedEvent.difficulty }}
                  </v-row>
                  <v-row class="ma-2 align-center">
                    <v-icon class="mr-2">mdi-clock</v-icon>
                    {{ selectedEvent.start.toLocaleDateString() }}, {{ selectedEvent['start_time'] }} -
                    {{ selectedEvent['end_time'] }}
                  </v-row>
                  <v-row class="ma-2 align-center">
                    <v-icon class="mr-2">mdi-account-group</v-icon>
                    {{ selectedEvent['present_size'] }} / {{ selectedEvent['total_size'] }} {{ $t('raids.persons') }}
                  </v-row>
                  <group-setup v-if="selectedEvent.detailsLoaded" :event="selectedEvent"
                               :addition-allowed="false" :selection-change-allowed="false"></group-setup>
                </div>
                <div v-else>
                  <v-row class="ma-2 align-center">
                    <p>{{ selectedEvent.description }}</p>
                  </v-row>
                  <v-row class="ma-2 align-center">
                    <v-icon class="mr-2">mdi-clock</v-icon>
                    {{ selectedEvent.start?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) }} -
                    {{ selectedEvent.end?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) }}
                  </v-row>
                  <v-row class="ma-2 align-center" v-if="!selectedEvent.detailsLoaded">
                    <v-icon class="mr-2">mdi-account-group</v-icon>
                    {{ selectedEvent['signUpsAmount'] }} {{ $t('raids.persons') }}
                  </v-row>
                  <v-row class="ma-2 align-center" v-if="selectedEvent.detailsLoaded">
                    <v-icon class="mr-2">mdi-account-group</v-icon>
                    {{ selectedEvent['signUpsAmount'] }} {{ $t('raids.persons') }} |
                    {{ getSignupCount(selectedEvent, 'Bench') }} {{ $t('raids.bench') }} |
                    {{ getSignupCount(selectedEvent, 'Absence') }} {{ $t('raids.absent') }}
                  </v-row>

                  <group-setup v-if="selectedEvent.detailsLoaded"
                               :event="selectedEvent" :addition-allowed="false" :selection-change-allowed="false"></group-setup>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="isOpenSelectionsImage" max-width="600px">
      <v-card class="mx-auto">
        <v-img
            :src="selectionsImage"
            height="800px"
            contain>
          <v-app-bar flat color="transparent">
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click="isOpenSelectionsImage = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExternalApiService from "@/services/wow_audit.service";
import GroupSetup from "@/components/GroupSetup";
import MessageService from "@/services/message.service";
import Constants from "@/util/constants";

export default {
  name: "Raids",
  components: {GroupSetup},
  data: () => ({
    tab: null,
    focus: '',
    type: 'month',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    typeToLabel: {},
    selectedEvent: {},
    isOpenEventDetails: false,
    raids: [],
    nextRaids: [],
    nextRaidFire: null,
    nextRaidFlame: null,
    nextRaidsLoading: true,
    isOpenSelectionsImage: false,
    selectionsImage: ""
  }),
  async mounted() {
    this.typeToLabel = {
      month: this.$t('raids.calendar.month'),
      week: this.$t('raids.calendar.week'),
      day: this.$t('raids.calendar.day')
    }

    for (const roster of Constants.ROSTER_LIST) {
      await this.resolveRaidsForRoster(roster);
    }

    this.nextRaidsLoading = false;
  },
  methods: {
    async resolveRaidsForRoster(roster){
      let wowAuditRaids = await ExternalApiService.getWowAuditRaids(roster);
      if(wowAuditRaids && wowAuditRaids.length > 0){
        let raids = this.mapRaidsForCalendar(wowAuditRaids, roster);
        this.raids = this.raids.concat(raids);
        await this.resolveNextRaid(raids, roster);
      } else {
        MessageService.warn(`Could not retrieve raids for roster '${Constants.ROSTER_INFO[roster].description}'`);
      }
    },
    mapRaid(raid, roster){
      return {
        ...raid,
        name: `${raid.instance}`,
        description: '',
        start: new Date(`${raid.date} ${raid['start_time']}`),
        end: new Date(`${raid.date} ${raid['end_time']}`),
        timed: true,
        color: Constants.ROSTER_INFO[roster].color,
        detailsLoaded: false,
        details: null,
        source: 'wow-audit',
        roster: roster,
        url:`${Constants.ROSTER_INFO[roster].baseUrl}/raids/${raid.id}`
      };
    },
    mapRaidsForCalendar(raids, roster){
      return raids.map(r => this.mapRaid(r, roster));
    },
    resolveNextRaid(raids, roster){
      if (raids.length > 0) {
        let filteredRaids = raids.filter(x => this.isFutureDate(x.start)).sort((a, b) => a.start - b.start);
        if(filteredRaids.length > 0){

          ExternalApiService.getWowAuditRaidDetails(roster, filteredRaids[0].id).then(data => {
            let nextRaid = this.mapRaid(data, roster);

            let encountersSelected = nextRaid.encounters.filter(e => e.selections !== undefined).length > 0;
            let encounters = [];

            if (encountersSelected) {
              nextRaid.encounters.forEach(encounter => {
                if (encounter.selections !== undefined) {
                  let enc = {
                    name: encounter.name,
                    selections: []
                  }

                  nextRaid.signups.forEach(char => {
                    let resolvedChar = JSON.parse(JSON.stringify(char));
                    resolvedChar.selected = encounter.selections.find(x => x["character_id"] === char.character.id).selected;
                    enc.selections.push(resolvedChar);
                  });

                  encounters.push(enc);
                }
              })
            } else {
              encounters.push({
                name: "All",
                selections: nextRaid.signups
              });
            }

            nextRaid.encounters = encounters;
            this.nextRaids.push(nextRaid);
            this.nextRaids = this.nextRaids.sort((a,b) => new Date(a.start) - new Date(b.start));
          });
        }
      }
    },
    isFutureDate(date){
      let today = new Date(Date.now());
      today.setMilliseconds(0);
      today.setSeconds(0);
      today.setMinutes(0);
      today.setHours(0);
      return date >= today;
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({event}) {
      this.selectedEvent = event;
      this.isOpenEventDetails = true;
      this.loadDetails(event);
    },
    loadDetails(event) {
      if (event.source === 'wow-audit') {
        ExternalApiService.getWowAuditRaidDetails(event.roster, event.id).then(data => {
          event.detailsLoaded = true;
          event.details = data;
        });
      }
    },
    openEvent(event) {
      window.open(event.url, '_blank')
    },
    getSignupCount(event, role) {
      return event.details.signups.filter(x => x.role === role).length;
    },
    openSelectionsImage(image) {
      this.selectionsImage = image;
      this.isOpenSelectionsImage = true;
    }
  },
  computed: {
    Constants() {
      return Constants
    },
    currentUser() {
      return this.$store.getters["auth/user"];
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.typeToLabel = {
        month: this.$t('raids.calendar.month'),
        week: this.$t('raids.calendar.week'),
        day: this.$t('raids.calendar.day')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.planner-char-entry {
  gap: 0 8px;

  &:hover {
    background-color: #333333;
    border-radius: 4px;
  }
}

.flame{
  color: #298F85;
}
</style>
