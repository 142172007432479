import MessageService from "@/services/message.service";

import axios from 'axios';
const API_URL = '/service/raid';

class RaidService {
    createRaidEntry(roster, raid, signups, items) {
        return axios.post(`${API_URL}/${roster}` , {
            raid: raid,
            signups: signups,
            items: items
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create raid entry. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    deleteRaidEntry(id) {
        return axios.delete(`${API_URL}/${id}` ).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to delete saved raid entry. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    getRaidEntries(roster) {
        return axios.get(`${API_URL}/${roster}` ).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get raid entries. Error: '${error.response.statusText}'`);
            return null;
        });
    }
}

export default new RaidService();
