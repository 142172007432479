import MessageService from "@/services/message.service";

import axios from 'axios';
import Constants from "@/util/constants";

const API_URL = '/service/character';

class CharacterService {
    getCharacterList(includeInactive = false, enhanceDisplay = false) {
        return axios.get(`${API_URL}/${includeInactive}`).then(response => {
            if(response.data && response.data.length > 0){
                return response.data.map(c => ({
                    ...c,
                    display: enhanceDisplay ? `${c.id} - ${c.name} - ${c.realm}` : c.name,
                    simpleDisplay: `${c.name}`,
                    rank: c.rank === -1 ? "n/a" : c.rank,
                    inRoster: c.rosterInformation && Object.keys(c.rosterInformation).length > 0,
                    rosterFire: c.rosterInformation && c.rosterInformation[Constants.ROSTER_INFO.fire.value] !== undefined,
                    rosterFlame: c.rosterInformation && c.rosterInformation[Constants.ROSTER_INFO.flame.value] !== undefined,
                    guestFlame: c.rosterInformation && c.rosterInformation[Constants.ROSTER_INFO.flame.value] !== undefined && c.rosterInformation[Constants.ROSTER_INFO.flame.value].guest,
                    isSyncedToUser: c.userId !== null,
                    dps: c.simulation_results.length > 0 ? c.simulation_results.sort((a, b) => new Date(b["createdAt"]) - new Date(a["createdAt"]))[0].dps : 0,
                    detailsLoaded: c["details"] && Object.keys(c["details"]).length > 0,
                    onDpsLoading: false,
                    simResult: null,
                    executionTime: 0
                }));
            } else {
                return [];
            }
        }).catch(error => {
            MessageService.error(`Failed to get character list. Error: '${error.response.statusText}'`);
        });
    }

    getCharacterListForUser(userId, fetchResults = false) {
        return axios.get(`${API_URL}/user/${userId}`, {
            fetchResults: fetchResults
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get character list for user. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    linkCharactersToUser(userId, characters) {
        return axios.post(`${API_URL}/user/${userId}`, characters).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to link characters for user. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getListForLoot(roster) {
        return axios.get(`${API_URL}/loot/${roster}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get roster list. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    getCharacterListDetails() {
        return axios.get(`${API_URL}/detail/refresh`).then(response => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to get character list details. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    getCharacterDetails(id, forceUpdate = false) {
        return axios.get(`${API_URL}/detail/${id}/${forceUpdate}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get character details. Error: '${error.response.statusText}'`);
        });
    }

    updateCharacter(id, updateData) {
        return axios.put(`${API_URL}/${id}`, updateData).then(response => {
            MessageService.success(`Character with id '${id}' successfully updated`);
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to update character details for character with id '${id}'. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    transferCharacterData(originId, newId, copy){
        return axios.post(`${API_URL}/transfer/${copy}`, {
            originId: originId,
            newId: newId
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to transfer character data. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    addCharacter(character) {
        return axios.post(`${API_URL}`, character).then(response => {
            MessageService.success("Character successfully created.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create character. Error: '${error.response.statusText}'`);
            return null;
        });
    }
}

export default new CharacterService();
