<template>
  <div>
    <div class="pa-2">
      <v-row class="pa-3 align-center">
        <v-avatar>
          <v-icon large>mdi-account</v-icon>
        </v-avatar>
        <h3>{{currentUser.data.username}}</h3>
      </v-row>
      <v-divider></v-divider>
      <v-list two-line v-if="!currentUser.data.isGeneric">
        <template>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-at</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{$t('user_account.mail')}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{currentUser.data.email}}
                <v-btn color="primary" icon @click="isOpenMailChange = true" v-if="!currentUser.data.isGeneric">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-pound</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                BattleTag
              </v-list-item-title>
              <v-list-item-subtitle>
                <p class="pt-2 pb-2 mb-0" v-if="currentUser.data.battleTag && currentUser.data.battleTag.length > 0">{{currentUser.data.battleTag}}</p>
                <p class="pt-2 pb-2 mb-0" v-else> {{$t('user_characters.bnet_not_synced')}}</p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <p class="font-weight-medium ma-2" v-else>
        <v-icon class="pb-2">mdi-information-box</v-icon>
        {{$t('user_account.generic_user_info')}}
      </p>
      <v-divider></v-divider>
      <v-row class="ma-4">
        <v-btn class="mr-2" color="primary" @click="isOpenPasswordChange = true" v-if="!currentUser.data.isGeneric">
          {{$t('user_account.dialogs.password.title')}}
        </v-btn>
        <v-btn color="primary" @click="refreshRightsAndRoles">
          {{$t('user_account.refresh')}}
        </v-btn>
      </v-row>
      <v-divider></v-divider>
      <v-expansion-panels multiple>
        <v-expansion-panel class="secondary">
          <v-expansion-panel-header>
            <h3>{{$t('user_account.assigned_roles')}}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list class="secondary">
              <v-list-item link v-for="role of getSortedRoles" :key="role.id" selectable>
                <v-list-item-icon>
                  <v-icon>mdi-key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ role }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="secondary">
          <v-expansion-panel-header>
            <h3>{{$t('user_account.assigned_rights')}}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list class="secondary">
              <v-list-item link v-for="right of getSortedRights" :key="right.id" selectable>
                <v-list-item-icon>
                  <v-icon>mdi-key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ right }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpenPasswordChange">
      <v-card>
        <v-toolbar flat class="font-weight-bold" color="primary">
          {{$t('user_account.dialogs.password.title')}}
        </v-toolbar>
        <v-card-text>
          <form class="pa-2 mt-4" v-on:submit.prevent="changePassword()">
            <v-layout column>
            <v-flex>
              <v-text-field v-model="oldPassword" :label="$t('user_account.dialogs.password.old.title')" required type="password"
                            @input="$v.oldPassword.$touch()" @blur="$v.oldPassword.$touch()"
                            :error-messages="getErrors('oldPassword')" ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field v-model="newPassword" :label="$t('user_account.dialogs.password.new.title')" required type="password"
                            @input="$v.newPassword.$touch()" @blur="$v.newPassword.$touch()"
                            :error-messages="getErrors('newPassword')"></v-text-field>
            </v-flex>
              <v-text-field v-model="newPasswordConfirmation" :label="$t('user_account.dialogs.password.confirmation.title')" required type="password"
                            @input="$v.newPasswordConfirmation.$touch()" @blur="$v.newPasswordConfirmation.$touch()"
                            :error-messages="getErrors('newPasswordConfirmation')"></v-text-field>

            <v-flex class="text-end" mt-5>
              <v-btn text @click="isOpenPasswordChange = false">{{$t('general.actions.cancel')}}</v-btn>
              <v-btn text color="primary" type="submit">{{$t('general.actions.save')}}</v-btn>
            </v-flex>
          </v-layout>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpenMailChange">
      <v-card>
        <v-toolbar flat class="font-weight-bold" color="primary">
          {{$t('user_account.dialogs.mail.title')}}
        </v-toolbar>
        <v-card-text>
          <form class="pa-2 mt-4" v-on:submit.prevent="changeMail()">
            <v-layout column>
              <v-flex>
                <v-text-field v-model="mail" :label="$t('user_account.dialogs.mail.old.title')" required type="text" disabled></v-text-field>
                <v-text-field v-model="newMail" :label="$t('user_account.dialogs.mail.new.title')" required type="text"
                              @input="$v.newMail.$touch()" @blur="$v.newMail.$touch()"
                              :error-messages="getErrors('newMail')"></v-text-field>
              </v-flex>

              <v-flex class="text-end" mt-5>
                <v-btn text @click="isOpenMailChange = false">{{$t('general.actions.cancel')}}</v-btn>
                <v-btn text color="primary" type="submit">{{$t('general.actions.save')}}</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, minLength, sameAs, email, not} from "vuelidate/lib/validators";
import UserService from "@/services/user.service";
import MessageService from "@/services/message.service";

export default {
  name: "UserAccount",
  mixins: [validationMixin],
  validations: {
    oldPassword: {required, minLength: minLength(5)},
    newPassword: {required, minLength: minLength(5), notSameAsOldPassword: not(sameAs('oldPassword'))},
    newPasswordConfirmation: {required, minLength: minLength(5), sameAsNewPassword: sameAs('newPassword')},
    mail: {required, email},
    newMail: {required, email, notSameAsMail: not(sameAs('mail'))}
  },
  data(){
    return {
      tab: null,
      isOpenPasswordChange: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      isOpenMailChange: false,
      mail: '',
      newMail: ''
    }
  },
  beforeMount() {
    this.mail = this.$store.getters["auth/user"].data.email;
  },
  methods: {
    refreshRightsAndRoles(){
      this.$store.dispatch("auth/refreshToken").then(user => {
        if(user !== null){
          MessageService.success(`Successfully refreshed rights and roles`);
        }
      });
    },
    getErrors(name){
      const errors = [];

      switch (name) {
        case "newMail":
          if (!this.$v.newMail.$dirty) return errors;
          !this.$v.newMail.required && errors.push(this.$t('user_account.dialogs.mail.new.required'));
          !this.$v.newMail.email && errors.push(this.$t('user_account.dialogs.mail.new.invalid'));
          !this.$v.newMail.notSameAsMail && errors.push(this.$t('user_account.dialogs.mail.new.sameAsOld'));
          break;
        case "oldPassword":
          if (!this.$v.oldPassword.$dirty) return errors;
          !this.$v.oldPassword.required && errors.push(this.$t('user_account.dialogs.password.old.required'));
          !this.$v.oldPassword.minLength && errors.push(this.$t('user_account.dialogs.password.old.minLength'));
          break;
        case "newPassword":
          if (!this.$v.newPassword.$dirty) return errors;
          !this.$v.newPassword.required && errors.push(this.$t('user_account.dialogs.password.new.required'));
          !this.$v.newPassword.minLength && errors.push(this.$t('user_account.dialogs.password.new.minLength'));
          !this.$v.newPassword.notSameAsOldPassword && errors.push(this.$t('user_account.dialogs.password.new.sameAsOld'));
          break;
        case "newPasswordConfirmation":
          if (!this.$v.newPasswordConfirmation.$dirty) return errors;
          !this.$v.newPasswordConfirmation.required && errors.push(this.$t('user_account.dialogs.password.confirmation.required'));
          !this.$v.newPasswordConfirmation.minLength && errors.push(this.$t('user_account.dialogs.password.confirmation.minLength'));
          !this.$v.newPasswordConfirmation.sameAsNewPassword && errors.push(this.$t('user_account.dialogs.password.confirmation.sameAsNew'));
          break;
        default:
          break;
      }
      return errors;
    },
    changePassword(){
      if (!this.$v.oldPassword.$invalid && !this.$v.newPassword.$invalid && !this.$v.newPasswordConfirmation.$invalid) {
        UserService.updatePassword(this.currentUser.data.id, this.oldPassword, this.newPassword).then(() => {
          this.isOpenPasswordChange = false;
        });
      }
    },
    changeMail(){
      if (!this.$v.mail.$invalid && !this.$v.newMail.$invalid) {
        UserService.updateMail(this.currentUser.data.id, this.newMail).then(data => {
          this.currentUser.data.email = data["email"];
          this.isOpenMailChange = false;
        });
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    getSortedRights(){
      let currentUser = this.$store.getters["auth/user"];
      return currentUser.rights.sort((a,b) => a.localeCompare(b));
    },
    getSortedRoles(){
      let currentUser = this.$store.getters["auth/user"];
      return currentUser.roles.sort((a,b) => a.localeCompare(b));
    }
  }
}
</script>

<style scoped>
</style>
