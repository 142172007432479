<template>
  <v-container>
    <div v-if="currentUser">
      <h2 class="mb-4 ml-2">{{ $t('sign_in.user_msg') }}</h2>
    </div>
    <div v-else>
      <div v-if="resolve">
        <v-layout row wrap>
          <v-flex xs12 class="text-center" mt-5>
            <h1>{{ $t('sign_in.reset_password') }}</h1>
          </v-flex>
          <v-flex xs12 sm6 offset-sm3 mt-3>
            <form v-on:submit.prevent="handleResolvePassword()">
              <v-layout column>
                <v-flex>
                  <v-text-field v-model="newPassword" :label="$t('user_account.dialogs.password.new.title')" required type="password"
                                @input="$v.newPassword.$touch()" @blur="$v.newPassword.$touch()"
                                :error-messages="getErrors('newPassword')"></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field v-model="newPasswordConfirmation" :label="$t('user_account.dialogs.password.confirmation.title')" required type="password"
                                @input="$v.newPasswordConfirmation.$touch()" @blur="$v.newPasswordConfirmation.$touch()"
                                :error-messages="getErrors('newPasswordConfirmation')"></v-text-field>
                </v-flex>

                <v-flex class="text-center" mt-5>
                  <v-btn color="primary" type="submit">{{ $t('general.actions.submit') }}</v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-flex>
        </v-layout>
      </div>
      <div v-else>
        <v-layout row wrap>
          <v-flex xs12 class="text-center" mt-5>
            <h1>{{ $t('sign_in.forgot_password') }}</h1>
          </v-flex>
          <v-flex xs12 sm6 offset-sm3 mt-3>
            <v-card>
              <v-card-text class="secondary lighten-1">
                <p ><v-icon class="mb-1">mdi-information</v-icon> <b>{{ $t('general.misc.information') }}</b></p>
                <p>{{ $t('sign_in.forgot_password_text_1') }}</p>
                <p>{{ $t('sign_in.forgot_password_text_2') }}</p>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 offset-sm3 mt-3>
            <form v-on:submit.prevent="handleForgotPassword()">
              <v-layout column>
                <v-flex>
                  <v-text-field v-model="name" :error-messages="getErrors('name')" :label="$t('sign_in.name.title')" required
                                @input="$v.name.$touch()" @blur="$v.name.$touch()" autocomplete="username"></v-text-field>
                </v-flex>
                <v-flex class="text-center" mt-5>
                  <v-btn color="secondary" class="mr-2" @click="navigateToSignIn">{{ $t('sign_in.back') }}</v-btn>
                  <v-btn color="primary" type="submit">{{ $t('general.actions.submit') }}</v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-flex>
        </v-layout>
      </div>

    </div>
  </v-container>
</template>

<script>

import {validationMixin} from "vuelidate";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import UserService from "@/services/user.service";

export default {
  name: "PasswordReset",
  mixins: [validationMixin],
  validations: {
    name: {required},
    newPassword: {required, minLength: minLength(5)},
    newPasswordConfirmation: {required, minLength: minLength(5), sameAsNewPassword: sameAs('newPassword')},
  },
  data: () => ({
    resolve: false,
    token: null,
    name: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }),
  mounted() {
    if(this.$route.query.token){
      this.token = this.$route.query.token;
      this.resolve = true;
    }
  },
  methods: {
    getErrors(name){
      const errors = [];

      switch (name) {
        case "name":
          if (!this.$v.name.$dirty) return errors;
          !this.$v.name.required && errors.push(this.$t('register.name.required'));
          break;
        case "newPassword":
          if (!this.$v.newPassword.$dirty) return errors;
          !this.$v.newPassword.required && errors.push(this.$t('user_account.dialogs.password.new.required'));
          !this.$v.newPassword.minLength && errors.push(this.$t('user_account.dialogs.password.new.minLength'));
          break;
        case "newPasswordConfirmation":
          if (!this.$v.newPasswordConfirmation.$dirty) return errors;
          !this.$v.newPasswordConfirmation.required && errors.push(this.$t('user_account.dialogs.password.confirmation.required'));
          !this.$v.newPasswordConfirmation.minLength && errors.push(this.$t('user_account.dialogs.password.confirmation.minLength'));
          !this.$v.newPasswordConfirmation.sameAsNewPassword && errors.push(this.$t('user_account.dialogs.password.confirmation.sameAsNew'));
          break;
        default:
          break;
      }
      return errors;
    },
    handleForgotPassword(){
      if (!this.$v.name.$invalid) {
        UserService.sendPasswordReset(this.name, this.$i18n.locale).then(data => {
          if(data){
            this.$router.push("/sign-in");
          }
        });

      }
    },
    handleResolvePassword(){
      if (!this.$v.newPassword.$invalid && !this.$v.newPasswordConfirmation.$invalid) {
        UserService.resolvePasswordReset(this.token, this.newPassword).then(data => {
          if(data){
            this.newPassword = "";
            this.newPasswordConfirmation = "";
            this.$router.push("/sign-in");
          }
        })
      }
    },
    navigateToSignIn(){
      this.$router.push('/sign-in');
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    }
  }
}
</script>

<style scoped>

</style>
