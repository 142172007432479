<template>
    <span>
        <v-avatar :size="size" v-if="role === 'Heal' || role === 'Healer'">
            <img alt="heal" src="../assets/img/role/healer.jpg">
        </v-avatar>

        <v-avatar :size="size" v-if="role === 'Ranged' || role === 'Melee'">
            <img alt="dps" src="../assets/img/role/dps.jpg">
        </v-avatar>

        <v-avatar :size="size" v-if="role === 'Tank'">
            <img alt="tank" src="../assets/img/role/tank.jpg">
        </v-avatar>

        <v-avatar :size="size" v-if="role === 'None'">
          <v-icon>mdi-cancel</v-icon>
        </v-avatar>
    </span>
</template>

<script>
export default {
  name: "RoleAvatar",
  props: ['role', 'size']
}
</script>

<style scoped>

</style>
