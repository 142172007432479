<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="vModel" :label="label" hint="MM/DD/YYYY" v-bind="attrs" v-on="on" readonly hide-details></v-text-field>
    </template>
    <v-date-picker v-model="vModel" no-title @input="menu = false" :locale="$i18n.locale"></v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: "DateInputTextField",
  props: ["identifier", "model", "label"],
  data() {
    return {
      vModel: this.model,
      menu: false
    }
  },
  watch: {
    model: function() {
      this.vModel = this.model;
    },
    vModel: function (){
      this.$root.$emit("date_input_change", {
        identifier: this.identifier,
        value: this.vModel !== null ? this.vModel : ""
      });
    }
  }
}
</script>

<style scoped>

</style>