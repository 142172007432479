<template>
  <div>
    <v-tabs right v-model="tab">
      <v-tab key="general-tab">Allgemein</v-tab>
      <v-tab key="itemlevel-ww-tab">Itemlevel (TWW)</v-tab>
      <v-tab key="mplus-ww-tab">Mythic+ (TWW)</v-tab>
      <v-tab key="itemlevel-df-tab">Itemlevel (DF)</v-tab>
      <v-tab key="mplus-df-tab">Mythic+ (DF)</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item key="general-tab" class="ma-2">
        <h3>Dunkelmondjahrmarkt</h3>
        <v-data-table :items="dark_moon_fairy.data" :headers="dark_moon_fairy.headers" hide-default-footer disable-pagination>
          <template v-slot:item.quest="{ item }">
            <a :href='`https://www.wowhead.com/de/quest=${item.quest.id}`'>{{ item.quest.name }}</a>
          </template>
          <template v-slot:item.materials="{ item }">
            <div v-for="material in item.materials" :key="material.id">
              <span v-if="material.id !== null">
                {{material.qty}}x <a :href='`https://www.wowhead.com/de/item=${material.id}`' >{{ material.name }}</a>
              </span>
              <span v-else>
                {{material.qty}}x {{ material.name }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item key="itemlevel-ww-tab" class="ma-2">
        <h3>Dungeons</h3>
        <table class="itemlevel-table">
          <tbody>
          <tr>
            <td class="l t b r">Key</td>
            <td class="t b">Loot</td>
            <td class="t b">Vault</td>
            <td class="r t b">Emblem</td>
          </tr>
          <tr>
            <td class="l r">N</td>
            <td>554</td>
            <td>n/a</td>
            <td class="r"></td>
          </tr>
          <tr>
            <td class="l r" title="Pre-Season">H*</td>
            <td class="adventurer">571</td>
            <td class="veteran">n/a</td>
            <td class="crest1 r" rowspan="2">Verwittert</td>
          </tr>
          <tr>
            <td class="l r">H</td>
            <td class="adventurer">580</td>
            <td class="veteran">593</td>
          </tr>
          <tr>
            <td class="l r">M</td>
            <td class="veteran">593</td>
            <td class="champ">603</td>
            <td class="r crest2" rowspan="3">Geschnitzt</td>
          </tr>
          <tr>
            <td class="l r">2</td>
            <td class="champ">597</td>
            <td class="champ">606</td>
          </tr>
          <tr>
            <td class="l r">3</td>
            <td class="champ ">597</td>
            <td class="hero">610</td>
          </tr>
          <tr>
            <td class="l r">4</td>
            <td class="champ">600</td>
            <td class="hero">610</td>
            <td class="r crest3" rowspan="4">Runenverziert</td>

          </tr>
          <tr>
            <td class="l r">5</td>
            <td class="champ">603</td>
            <td class="hero">613</td>
          </tr>
          <tr>
            <td class="l r">6</td>
            <td class="champ">606</td>
            <td class="hero">613</td>
          </tr>
          <tr>
            <td class="l r">7</td>
            <td class="hero">610</td>
            <td class="hero">616</td>
          </tr>
          <tr>
            <td class="l r">8</td>
            <td class="hero">610</td>
            <td class="hero">619</td>
            <td class="r b crest4" rowspan="3">Vergoldet</td>
          </tr>
          <tr>
            <td class="l r">9</td>
            <td class="hero">613</td>
            <td class="hero">619</td>
          </tr>
          <tr>
            <td class="l b r">10+</td>
            <td class="b hero">613</td>
            <td class="b myth">623</td>

          </tr>
          </tbody>
        </table>
        <br>

        <h3>Raids</h3>
        <div style="width: 100%; overflow-y: auto">
        <table class="itemlevel-table">
          <tbody>
          <tr>
            <td class="l r t b">ilvl</td>
            <td class="l r t b">braucht</td>
            <td class="l r t b" colspan="10">Upgrade Track</td>
            <td class="l r t b" colspan="4">Herstellung</td>
            <td class="l r t b">Raid</td>
          </tr>
          <tr>
            <td class="l r">571</td>
            <td class="r" rowspan="4"></td>
            <td class="l adventurer">1/8</td>
            <td class="r b adventurer" rowspan="8">Abenteurer</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l r" rowspan="4"></td>
          </tr>
          <tr>
            <td class="l r">574</td>
            <td class="l adventurer">2/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">577</td>
            <td class="l adventurer">3/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l t crest1" dir="ltr">Q1</td>
            <td class="r b t crest1" dir="ltr" rowspan="5">30x Verwittert</td>
          </tr>
          <tr>
            <td class="l r">580</td>
            <td class="l adventurer">4/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest1" dir="ltr">Q2</td>
          </tr>
          <tr>
            <td class="l r">584</td>
            <td class="r crest1" rowspan="4">Verwittert</td>
            <td class="l adventurer">5/8</td>
            <td class="l t veteran">1/8</td>
            <td class="r b t veteran" rowspan="8">Veteran</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            <td class="l crest1" dir="ltr">Q3</td>
            <td class="l r b t" dir="ltr" rowspan="4">LFR</td>
          </tr>
          <tr>
            <td class="l r">587</td>
            <td class="l adventurer">6/8</td>
            <td class="l veteran">2/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest1">Q4</td>
          </tr>
          <tr>
            <td class="l r">590</td>
            <td class="l adventurer">7/8</td>
            <td class="l veteran">3/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="s21"></td>
            <td class="l t crest2" dir="ltr">Q1</td>
            <td class="r t b crest2" rowspan="5">Funke</td>
            <td class="l b crest1" dir="ltr">Q5</td>
          </tr>
          <tr>
            <td class="l r">593</td>
            <td class="l b adventurer">8/8</td>
            <td class="l veteran">4/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest2" dir="ltr">Q2</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">597</td>
            <td class="r crest2" rowspan="4">Geschnitzt</td>
            <td></td>
            <td></td>
            <td class="l veteran">5/8</td>
            <td class="t l champ">1/8</td>
            <td class="t b r champ" rowspan="8">Champion</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest2" dir="ltr">Q3</td>
            <td></td>
            <td></td>
            <td class="l r t b" dir="ltr" rowspan="4">Normal</td>
          </tr>
          <tr>
            <td class="l r">600</td>
            <td></td>
            <td></td>
            <td class="l veteran">6/8</td>
            <td class="l champ">2/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest2" dir="ltr">Q4</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">603</td>
            <td></td>
            <td></td>
            <td class="l veteran">7/8</td>
            <td class="l champ">3/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l b crest2" dir="ltr">Q5</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">606</td>
            <td></td>
            <td></td>
            <td class="l b veteran">8/8</td>
            <td class="l champ">4/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l t crest3" dir="ltr">Q1</td>
            <td class="r t b crest3" dir="ltr" rowspan="5">Funke<br>45x Runenverziert</td>
          </tr>
          <tr>
            <td class="l r">610</td>
            <td class="r crest3" rowspan="4">Runenverziert</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l champ">5/8</td>
            <td class="t l hero">1/6</td>
            <td class="t b r hero" rowspan="6">Held</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest3">Q2</td>
            <td class="l r t b" dir="ltr" rowspan="4">Heroisch</td>
          </tr>
          <tr>
            <td class="l r">613</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l champ">6/8</td>
            <td class="l hero">2/6</td>
            <td></td>
            <td></td>
            <td class="s36"></td>
            <td class="s37"></td>
            <td class="l crest3" dir="ltr">Q3</td>
          </tr>
          <tr>
            <td class="l r">616</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l champ">7/8</td>
            <td class="l hero">3/6</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest3">Q4</td>
          </tr>
          <tr>
            <td class="l r">619</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="b l champ">8/8</td>
            <td class="l hero">4/6</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l b crest3">Q5</td>
          </tr>
          <tr>
            <td class="l r">623</td>
            <td class="r b crest4" rowspan="6">Vergoldet</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l hero">5/6</td>
            <td class="t l myth">1/6</td>
            <td class="t b r myth" rowspan="6">Mythos</td>
            <td class="l t crest4" dir="ltr">Q1</td>
            <td class="r t b crest4" dir="ltr" rowspan="5">Funke<br>90x Vergoldet</td>
            <td></td>
            <td></td>
            <td class="l r t b" dir="ltr" rowspan="6">Mythisch</td>
          </tr>
          <tr>
            <td class="l r">626</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="b l hero">6/6</td>
            <td class="l myth">2/6</td>
            <td class="l crest4">Q2</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">629</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l myth">3/6</td>
            <td class="l crest4" dir="ltr">Q3</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">632</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class=" l myth">4/6</td>
            <td class="l crest4" dir="ltr">Q4</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">636</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l myth">5/6</td>
            <td class="l b crest4" dir="ltr">Q5</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="b l r">639</td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b l myth">6/6</td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
          </tr>
          </tbody>
        </table>
        </div>
      </v-tab-item>
      <v-tab-item key="mplus-ww-tab" class="ma-2">
        <h3>Mythic+ Affix-Rotation (tbd)</h3>
        <v-row class="container">
          <v-col v-for="index in [0,1,2,3]" :key="index">
            <v-row class="justify-center mb-2 font-weight-bold">
              {{$tc('information.affix_timespan', index)}}
            </v-row>
            <v-row v-for="rotation in getCurrentAffixes(getCurrentWeekNumber() + index, 'tww_s1')" :key="rotation">
              <v-col>
                <v-row class="justify-center">
                  <a :href="`https://www.wowhead.com/affix=${rotation}`" v-bind:data-wowhead="`domain=${$i18n.locale}`" target="_blank">
                    <img :src="`https://wow.zamimg.com/images/wow/icons/large/${(getAffixDetails(rotation)).media}.jpg`" style="width: 64px; height:64px;" alt="wow-icon">
                  </a>
                </v-row>
                <v-row class="justify-center mb-1 mt-1">
                  {{ (getAffixDetails(rotation)).name }}
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <h3>Mythic+ Instanzzeiten</h3>
        <v-data-table
            :headers="instance_timers_headers"
            :items="instance_timers_dungeons.ww_s1"
            item-key="name"
            hide-default-footer
        ></v-data-table>
      </v-tab-item>
      <v-tab-item key="itemlevel-df-tab" class="ma-2">
        <h3>Dungeons</h3>
        <table class="itemlevel-table">
          <tbody>
          <tr>
            <td class="l t b">Key</td>
            <td class="t b">Loot</td>
            <td class="t b">Vault</td>
            <td class="r t b">Emblem</td>
          </tr>
          <tr>
            <td class="l">H</td>
            <td class="adventurer">476</td>
            <td class="veteran">489</td>
            <td class="crest1 r">Welpling</td>
          </tr>
          <tr>
            <td class="l crest2">0</td>
            <td class="champ">493</td>
            <td class="hero">506</td>
            <td class="r crest2">Drache</td>
          </tr>
          <tr>
            <td class="l crest3">2</td>
            <td class="champ">496</td>
            <td class="hero">509</td>
            <td class="r crest3" rowspan="4">Wyrm</td>
          </tr>
          <tr>
            <td class="l crest3">3</td>
            <td class="champ ">499</td>
            <td class="hero">509</td>
          </tr>
          <tr>
            <td class="l crest3">4</td>
            <td class="champ">499</td>
            <td class="hero">512</td>

          </tr>
          <tr>
            <td class="l crest3">5</td>
            <td class="champ">502</td>
            <td class="hero">512</td>
          </tr>
          <tr>
            <td class="l crest4">6</td>
            <td class="champ">502</td>
            <td class="hero">515</td>
            <td class="r b crest4" rowspan="5">Aspekt</td>
          </tr>
          <tr>
            <td class="l crest4">7</td>
            <td class="hero">506</td>
            <td class="hero">515</td>
          </tr>
          <tr>
            <td class="l crest4">8</td>
            <td class="hero">506</td>
            <td class="myth">519</td>

          </tr>
          <tr>
            <td class="l crest4">9</td>
            <td class="hero">509</td>
            <td class="myth">519</td>

          </tr>
          <tr>
            <td class="l b crest4">10+</td>
            <td class="b hero">509</td>
            <td class="b myth">522</td>

          </tr>
          </tbody>
        </table>
        <br>
        <h3>Raids</h3>
        <table class="itemlevel-table">
          <tbody>
          <tr>
            <td class="l r t b">ilvl</td>
            <td class="l r t b">braucht</td>
            <!--<td rowspan="19"></td>-->
            <td class="l r t b" colspan="8">Upgrade Track</td>
            <td class="l r t b" colspan="2">Goldbarren</td>
            <td class="l r t b" colspan="4">Herstellung</td>
            <td class="l r t b">Raid</td>
          </tr>
          <tr>
            <td class="l r">480</td>
            <td class="r crest1" rowspan="4">Welpe</td>
            <td class="l veteran">1/8</td>
            <td class="r b veteran" rowspan="8">Veteran</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest1" dir="ltr">Q3</td>
            <td class="r b crest1" dir="ltr" rowspan="3">30 Welpe</td>
            <td class="l r b" dir="ltr" rowspan="4">LFR</td>
          </tr>
          <tr>
            <td class="l r">483</td>
            <td class="l veteran">2/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l crest1">Q4</td>
          </tr>
          <tr>
            <td class="l r">486</td>
            <td class="l veteran">3/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="s21"></td>
            <td class="l b crest1" dir="ltr">Q5</td>
          </tr>
          <tr>
            <td class="l r">489</td>
            <td class="l veteran">4/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="s21"></td>
            <td class="l t crest2" dir="ltr">Q1</td>
            <td class="r t b crest2" rowspan="5">Funke</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">493</td>
            <td class="r crest2" rowspan="4">Drache</td>
            <td class="l veteran">5/8</td>
            <td class="t l champ">1/8</td>
            <td class="t b r champ" rowspan="8">Champion</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="t l awakened">1/12</td>
            <td class="t b r awakened" rowspan="14">Erwacht</td>
            <td class="l crest2" dir="ltr">Q2</td>
            <td></td>
            <td></td>
            <td class="l r t b" dir="ltr" rowspan="4">Normal</td>
          </tr>
          <tr>
            <td class="l r">496</td>
            <td class="l veteran">6/8</td>
            <td class="l champ">2/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l awakened">2/12</td>
            <td class="l crest2" dir="ltr">Q3</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">499</td>
            <td class="l veteran">7/8</td>
            <td class="l champ">3/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l awakened">3/12</td>
            <td class="l crest2">Q4</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">502</td>
            <td class="l b veteran">8/8</td>
            <td class="l champ">4/8</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l awakened">4/12</td>
            <td class="l b crest2" dir="ltr">Q5</td>
            <td class="l t crest3" dir="ltr">Q1</td>
            <td class="r t b crest3" dir="ltr" rowspan="5">Funke<br>45 Wyrm</td>
          </tr>
          <tr>
            <td class="l r">506</td>
            <td class="r crest3" rowspan="4">Wyrm</td>
            <td class="l"></td>
            <td></td>
            <td class="l champ">5/8</td>
            <td class="t l hero">1/6</td>
            <td class="t b r hero" rowspan="6">Held</td>
            <td></td>
            <td></td>
            <td class="l awakened">5/12</td>
            <td></td>
            <td></td>
            <td class="l crest3">Q2</td>
            <td class="l r t b" dir="ltr" rowspan="4">Heroisch</td>
          </tr>
          <tr>
            <td class="l r">509</td>
            <td class="l"></td>
            <td></td>
            <td class="l champ">6/8</td>
            <td class="l hero">2/6</td>
            <td></td>
            <td></td>
            <td class="l awakened">6/12</td>
            <td class="s36"></td>
            <td class="s37"></td>
            <td class="l crest3" dir="ltr">Q3</td>
          </tr>
          <tr>
            <td class="l r">512</td>
            <td class="l"></td>
            <td></td>
            <td class="l champ">7/8</td>
            <td class="l hero">3/6</td>
            <td></td>
            <td></td>
            <td class="l awakened">7/12</td>
            <td class="l t crest4" dir="ltr">Q1</td>
            <td class="r t b crest4" dir="ltr" rowspan="5">Funke<br>60 Aspekt</td>
            <td class="l crest3">Q4</td>
          </tr>
          <tr>
            <td class="l r">515</td>
            <td class="l"></td>
            <td></td>
            <td class="b l champ">8/8</td>
            <td class="l hero">4/6</td>
            <td></td>
            <td></td>
            <td class="l awakened">8/12</td>
            <td class="l crest4" dir="ltr">Q2</td>
            <td class="l b crest3">Q5</td>
          </tr>
          <tr>
            <td class="l r">519</td>
            <td class="r b crest4" rowspan="6">Aspekt</td>
            <td class="l"></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l hero">5/6</td>
            <td class="t l myth">1/4</td>
            <td class="t b r myth" rowspan="4">Mythos</td>
            <td class="l awakened">9/12</td>
            <td class="l crest4">Q3</td>
            <td></td>
            <td></td>
            <td class="l r t b" dir="ltr" rowspan="6">Mythisch</td>
          </tr>
          <tr>
            <td class="l r">522</td>
            <td class="l"></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="b l hero">6/6</td>
            <td class="l myth">2/4</td>
            <td class="l awakened">10/12</td>
            <td class="l crest4">Q4</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">525</td>
            <td class="l"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="l myth">3/4</td>
            <td class="l awakened">11/12</td>
            <td class="l b crest4" dir="ltr">Q5</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">528</td>
            <td class="l"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="b l myth">4/4</td>
            <td class="l awakened">12/12</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="l r">532</td>
            <td class="l"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td title="Legendary oder super rare Drops" class="l awakened">13/12 *</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="b l r">535</td>
            <td class="b l"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td title="Legendary oder super rare Drops" class="b l awakened">14/12 *</td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
            <td class="b"></td>
          </tr>
          </tbody>
        </table>
      </v-tab-item>
      <v-tab-item key="mplus-df-tab" class="ma-2">
          <h3>Mythic+ Affix-Rotation</h3>
           <v-row class="container">
             <v-col v-for="index in [0,1,2,3]" :key="index">
               <v-row class="justify-center mb-2 font-weight-bold">
                 {{$tc('information.affix_timespan', index)}}
               </v-row>
               <v-row v-for="rotation in getCurrentAffixes(getCurrentWeekNumber() + index, 'df_s4')" :key="rotation">
                 <v-col>
                   <v-row class="justify-center">
                     <a :href="`https://www.wowhead.com/affix=${rotation}`" v-bind:data-wowhead="`domain=${$i18n.locale}`" target="_blank">
                       <img :src="`https://wow.zamimg.com/images/wow/icons/large/${(getAffixDetails(rotation)).media}.jpg`" style="width: 64px; height:64px;" alt="wow-icon">
                     </a>
                   </v-row>
                   <v-row class="justify-center mb-1 mt-1">
                     {{ (getAffixDetails(rotation)).name }}
                   </v-row>
                 </v-col>
               </v-row>
             </v-col>
           </v-row>
          <br>
          <h3>Mythic+ Instanzzeiten</h3>
          <v-data-table
              :headers="instance_timers_headers"
              :items="instance_timers_dungeons.df_s4"
              item-key="name"
              hide-default-footer
          ></v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

export default {
  name: "Information",
  data() {
    return {
      tab: 0,
      affixMapping: {
        3: {
          name: "Volcanic",
          media: "spell_shaman_lavasurge"
        },
        6: {
          name: "Raging",
          media: "ability_warrior_focusedrage"
        },
        7: {
          name: "Bolstering",
          media: "ability_warrior_battleshout"
        },
        8: {
          name: "Sanguine",
          media: "spell_shadow_bloodboil"
        },
        9: {
          name: "Tyrannical",
          media: "achievement_boss_archaedas"
        },
        10: {
          name: "Fortified",
          media: "ability_toughness"
        },
        11: {
          name: "Bursting",
          media: "ability_ironmaidens_whirlofblood"
        },
        123: {
          name: "Spiteful",
          media: "spell_holy_prayerofshadowprotection"
        },
        124: {
          name: "Storming",
          media: "spell_nature_cyclone"
        },
        134: {
          name: "Inspiring",
          media: "inv_misc_root_01"
        },
        135: {
          name: "Afflicted",
          media: "spell_misc_emotionsad"
        },
        136: {
          name: "Incorporeal",
          media: "achievement_boss_anomalus"
        }
      },
      affixesTurn: {
        df_s4: [
          [9, 134, 11],
          [10, 3, 123],
          [9, 124, 6],
          [10, 134, 7],
          [9, 136, 123],
          [10, 135, 6],
          [9, 3, 8],
          [10, 124, 11],
          [9, 135, 7],
          [10, 136, 8]
        ],
        tww_s1: [[9, 9, 9], [10, 10, 10]]
      },
      EU_Start_Week: 1670385600,
      SECONDS_PER_DAY: 24*60*60,
      EU_DELAY: 0,
      instance_timers_headers: [
        { text: 'Dungeon', value: 'name' },
        { text: 'Timer', value: 'timer' },
        { text: '+2', value: 'plus2' },
        { text: '+3', value: 'plus3' },
      ],
      instance_timers_dungeons: {
        df_s4: [
          {name: "Neltharus", timer: "33:00", plus2: "26:24", plus3: "19:48"},
          {name: "Uldaman", timer: "35:00", plus2: "28:00", plus3: "21:00"},
          {name: "Hallen der Infusion", timer: "35:00", plus2: "28:00", plus3: "21:00"},
          {name: "Rubinlebensbecken", timer: "30:00", plus2: "24:00", plus3: "18:00"},
          {name: "Algeth'ar Akedemie", timer: "32:00", plus2: "25:36", plus3: "19:12"},
          {name: "Brackenfellhöhle", timer: "35:00", plus2: "28:00", plus3: "21:00"},
          {name: "Die Nokhud Offensive", timer: "40:00", plus2: "32:00", plus3: "24:00"},
          {name: "Azurblaues Gewölbe", timer: "36:30", plus2: "29:12", plus3: "21:54"},
        ], ww_s1: [
          {name: "Ara-Kara, Stadt der Echos", timer: "30:00", plus2: "24:00", plus3: "18:00"},
          {name: "Stadt der Fäden", timer: "35:00", plus2: "28:00", plus3: "21:00"},
          {name: "Die Morgenbringer", timer: "30:00", plus2: "24:00", plus3: "18:00"},
          {name: "Das Steingewölbe", timer: "33:00", plus2: "26:24", plus3: "19:48"},
          {name: "Die Nebel von Tirna Scithe", timer: "30:00", plus2: "24:00", plus3: "18:00"},
          {name: "Die Belagerung von Boralus", timer: "34:00", plus2: "27:12", plus3: "20:24"},
          {name: "Grim Batol", timer: "36:00", plus2: "29:12", plus3: "21:36"},
          {name: "Die Nekrotische Schneise", timer: "32:00", plus2: "25:36", plus3: "19:12"}
        ]
      },
      dark_moon_fairy: {
        headers: [
          { text: 'Beruf', value: 'profession' },
          { text: 'Quest', value: 'quest' },
          { text: 'Zusätzliches Material', value: 'materials' }
        ],
        data: [
          {
            profession: "Alchemie",
            quest: { id: 29506, name: "Eine prickelnde Mischung" },
            materials: [{ qty: 5, id: 1645, name: "Mondbeerensaft" }]
          },
          {
            profession: "Angeln",
            quest: { id: 29513, name: "Leckerer Fisch im Schlafrock" },
            materials: null
          },
          {
            profession: "Archäologie",
            quest: { id: 29507, name: "Spaß für die Kleinen" },
            materials: [{ qty: 15, id: null, name: "Fossil-Fragmente" }]
          },
          {
            profession: "Bergbau",
            quest: { id: 29518, name: "Wiederbewaffnung, Wiederverwendung, Wiederverwertung" },
            materials: null
          },
          {
            profession: "Ingenieurskunst",
            quest: { id: 29511, name: "Panzerreparatur" },
            materials: null
          },
          {
            profession: "Inschriftenkunde",
            quest: { id: 29515, name: "Ein Blick in die Zukunft" },
            materials: [{ qty: 5, id: 39354, name: "Leichtes Pergament" }]
          },
          {
            profession: "Juwelenschleifen",
            quest: { id: 29516, name: "Jahrmarkt der Eitelkeiten" },
            materials: null
          },
          {
            profession: "Kochkunst",
            quest: { id: 29509, name: "Wie der Frosch knusprig wird" },
            materials: [{ qty: 5, id: 30817, name: "Herkömmliches Mehl" }]
          },
          {
            profession: "Kräuterkunde",
            quest: { id: 29514, name: "Heilkräuter" },
            materials: null
          },
          {
            profession: "Kürschnerei",
            quest: { id: 29519, name: "Gerbt meine Häute" },
            materials: null
          },
          {
            profession: "Lederverarbeitung",
            quest: { id: 29517, name: "Begehrte Preise" },
            materials: [
              { qty: 10, id: 6529, name: "Glänzendes Schmuckstück" },
              { qty: 5, id: 2320, name: "Grober Faden" },
              { qty: 5, id: 6260, name: "Blauer Farbstoff" }
            ]
          },
          {
            profession: "Schmiedekunst",
            quest: { id: 29508, name: "Baby braucht zwei Paar Schuhe" },
            materials: null
          },
          {
            profession: "Schneiderei",
            quest: { id: 29520, name: "Banner, überall Banner!" },
            materials: [
              { qty: 1, id: 2320, name: "Grober Faden" },
              { qty: 1, id: 6260, name: "Blauer Farbstoff" },
              { qty: 1, id: 2604, name: "Roter Farbstoff" }
            ]
          },
          {
            profession: "Verzauberkunst",
            quest: { id: 59510, name: "Auch Abfall kann nützlich sein" },
            materials: null
          }
        ]
      }
    }
  },
  methods: {
    getAffixDetails(affix) {
      return this.affixMapping[affix];
    },
    getCurrentWeekNumber() {
      const week0 = new Date(this.EU_Start_Week * 1000);

      const now = Math.floor(Date.now() / 1000);
      let startWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() - 6 * this.SECONDS_PER_DAY + 5 * 3600)) * 1000);
      let endWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() + 7 * this.SECONDS_PER_DAY - 1 + 4 * 3600 + 59 * 60 + 59)) * 1000);
      if (!(now >= Math.floor(startWeek.getTime() / 1000) && now <= Math.floor(endWeek.getTime() / 1000))) {
        startWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() + 7 * this.SECONDS_PER_DAY + 5 * 3600)) * 1000);
        endWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() + 14 * this.SECONDS_PER_DAY - 1 + 4 * 3600 + 59 * 60 + 59)) * 1000);
        if (!(now >= Math.floor(startWeek.getTime() / 1000) && now <= Math.floor(endWeek.getTime() / 1000))) {
          startWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() - 7 * this.SECONDS_PER_DAY + 5 * 3600)) * 1000);
          endWeek = new Date((now + (this.SECONDS_PER_DAY * 2 - this.SECONDS_PER_DAY * new Date(now * 1000).getDay() + this.SECONDS_PER_DAY - 1 + 4 * 3600 + 59 * 60 + 59)) * 1000);
        }
      }

      const thisWeek = new Date(startWeek);

      return Math.floor((thisWeek.getTime() - week0.getTime()) / (1000 * 60 * 60 * 24 * 7));
    },
    getCurrentAffixes(weekNumber, season) {
      let affixesTurn = this.affixesTurn[season];
      const index = (weekNumber + this.EU_DELAY) % affixesTurn.length;
      return affixesTurn[index];
    }
  }
}
</script>

<style lang="scss">
$crest: #cc5858;
$track: rgba(130, 139, 229, 0.83);

.itemlevel-table {
  border-collapse: collapse;
  text-align: center;
}
.itemlevel-table {
  .crest1 {
    background-color: $crest;
  }

  .crest2 {
    background-color: darken($crest, 10%);
  }

  .crest3 {
    background-color: darken($crest, 20%);
  }

  .crest4 {
    background-color: darken($crest, 30%);
  }

  .explorer {
    background-color: $track;
  }

  .adventurer {
    background-color: darken($track, 10%);
  }

  .veteran {
    background-color: darken($track, 20%);
  }

  .champ {
    background-color: darken($track, 30%);
  }

  .hero {
    background-color: darken($track, 40%);
  }

  .myth {
    background-color: darken($track, 50%);
  }

  .awakened {
    background-color: #eedd00;
    color: #000000;
  }

  .l {
    border-left-style: solid;
  }

  td {
    min-width: 50px;
    padding: 0 5px;
    border-color: #dddddd;

    &:not(.adventurer):not(.veteran):not(.explorer):not(.champ):not(.hero):not(.myth):not(.crest1):not(.crest2):not(.crest3):not(.crest4):not(.awakened) {
      border-bottom: solid #333 1px;
    }
  }

  td.gap {
    min-width: 25px;
  }

  .r {
    border-right-style: solid;
  }

  .t {
    border-top-style: solid;
  }

  .b {
    border-bottom-style: solid;
    border-color: #dddddd !important;
    border-width: initial !important;
  }
}

.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width:200px;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}
</style>