import MessageService from "@/services/message.service";
import axios from 'axios';

const API_URL = '/service/behaviour-log';

class BehaviourLogService {
    getBehaviourLogs() {
        return axios.get(`${API_URL}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get behaviour logs. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    addBehaviourLogsForCharacterList(body) {
        return axios.post(`${API_URL}`, body).then(response => {
            MessageService.success("Successfully added behaviour logs.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create behaviour logs. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    deleteBehaviourLogs(body) {
        return axios.post(`${API_URL}/remove`, body).then(response => {
            MessageService.success("Successfully deleted behaviour logs.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to delete behaviour logs. Error: '${error.response.statusText}'`);
            return null;
        });
    }
}

export default new BehaviourLogService();
