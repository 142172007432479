import MessageService from "@/services/message.service";

import axios from 'axios';
const API_URL_WOW_AUDIT = '/service/wow-audit';

class WowAuditService {
    getWowAuditCharacters(roster) {
        return axios.get(`${API_URL_WOW_AUDIT}/characters/${roster}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get characters. Error: '${error.response.statusText}'`);
            return [];
        });
    }
    getWowAuditWishlists(roster, force) {
        return axios.get(`${API_URL_WOW_AUDIT}/wishlists/${roster}/${force}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get wishlists. Error: '${error.response.statusText}'`);
            return {
                syncDate: "-",
                data: {}
            };
        });
    }

    getWowAuditRaids(roster) {
        return axios.get(`${API_URL_WOW_AUDIT}/raids/${roster}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get raid list. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getWowAuditRaidDetails(roster, id) {
        return axios.get(`${API_URL_WOW_AUDIT}/raids/detail/${roster}/${id}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get raid details. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getWoWAuditGuestsForRoster(roster) {
        return axios.get(`${API_URL_WOW_AUDIT}/guests/${roster}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get guests for roster '${roster}'. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    removeCharacterFromGuests(roster, character, guestId) {
        return axios.post(`${API_URL_WOW_AUDIT}/guests/delete/${roster}`, {
            character: character,
            guestId: guestId
        }).then(response => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to delete guest '${guestId}' for roster '${roster}'. Error: '${error.response.statusText}'`);
            return false;
        });
    }
}
export default new WowAuditService();