import Constants from "@/util/constants";

class BinaryArrayReader {
    initialize(exportString){
        this.array = [];
        for (let i = 0; i < exportString.length; i++) {
            // For example, the value of F is 5 (because F is the 6th element in the array)
            let value = Constants.BASE_64_TABLE.indexOf(exportString[i]);
            // Base 64 is a 6-bit encoding, because all numerical values are between 0 and 63 (each represented by a readable character)
            let valueBitArray = [value>>5 & 1, value>>4 & 1, value>>3 & 1, value>>2 & 1, value>>1 & 1, value & 1]
            this.array.push(valueBitArray)
        }
        this.arrayIndex = 0;
        this.bitCursor = 5;
    }

    read(width) {
        let resultArray = [];
        let readBits = 0;
        // Gathering the next ``width'' bits
        while (readBits < width) {
            if (this.array[this.arrayIndex]) {
                resultArray.unshift(this.array[this.arrayIndex][this.bitCursor]);
                if (this.bitCursor === 0) {
                    this.bitCursor = 5;
                    this.arrayIndex += 1;
                } else {
                    this.bitCursor -= 1;
                }
            }
            readBits += 1; // otherwise it can go into an infinite loop, a better way may be with a for loop
        }
        // Transforming the bits gathered into a number, going through the array from the right most
        // bit to the left most bit. A bit in position n from the right will add a value of
        // bit * 2^n (which can just be written as bit << n, as shifting the 1 to the right once)
        // equals multiplying it by 2: 1 (1), 10 (2), 100 (4), 1000 (8), 10000 (16), etc.
        let result = 0;
        for (let i = 0; i < width; i++) {
            result += resultArray[width-1-i] << i;
        }
        return result;
    }
}

export default new BinaryArrayReader();