<template>
  <div>
    <v-row class="pa-4">
      <h3>Battle Net Authorization is processing. Please wait...</h3>
      <v-progress-circular :value="100" color="primary" indeterminate class="ml-3"></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AuthCallback",
  mounted() {
    this.$router.push({
      name: 'UserCharacters',
      params: {
        authCallback: true,
        code: this.$route.query.code
      }
    });
  }
}
</script>

<style scoped>
</style>
