let toast;

class MessageService {
    initialize(instance){
        toast = instance;
    }

    success(message){
        toast.success(message);
    }

    warn(message){
        toast.warning(message);
    }

    error(message){
        toast.error(message);
    }

    info(message){
        toast.info(message);
    }
}

export default new MessageService();