<template>
  <v-dialog v-model="isOpen" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('simulation.settings') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn plain variant="text" @click="restoreDefault">
            {{ $t('general.actions.restore') }}
          </v-btn>
          <v-btn plain variant="text" @click="saveSettings">
            {{ $t('general.actions.save') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel expand :value="0">
            <v-expansion-panel-header>
              <h3>{{ $t('simulation.version.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2 pb-0">
                <v-btn-toggle borderless v-model="simOptions.SIM_VERSION.SIM_VERSION_MODE.VALUE" class="ml-1">
                  <v-btn value="latest" small>
                    {{  $t('simulation.version.use_latest') }}
                  </v-btn>
                  <v-btn value="specific" small>
                    {{  $t('simulation.version.use_specific') }}
                  </v-btn>
                </v-btn-toggle>
              </v-row>
              <v-row class="pa-2 pb-0" v-if="simOptions.SIM_VERSION.SIM_VERSION_MODE.VALUE === 'latest'">
                <p class="mt-2 ml-2"><b>{{ $t('simulation.version.build_date') }}:</b> {{ simOptions.SIM_VERSION.LATEST_VERSION_INFO }} </p>
              </v-row>
              <v-row class="pa-2 pb-0" v-if="simOptions.SIM_VERSION.SIM_VERSION_MODE.VALUE === 'specific'">
                <v-autocomplete class="ml-2" :label="$t('simulation.version.specific_version')" return-object item-text="display"
                                :items="simOptions.SIM_VERSION.SIM_VERSIONS"
                                v-model="simOptions.SIM_VERSION.SIM_VERSION"></v-autocomplete>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel expand :value="1">
            <v-expansion-panel-header>
              <h3>{{ $t('simulation.options.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2 pb-0">
                <v-autocomplete class="ml-2" :label="$t('simulation.options.fight_style')" return-object
                                :items="simOptions.SETTINGS.FIGHT_STYLE_OPTIONS"
                                v-model="simOptions.SETTINGS.FIGHT_STYLE.VALUE"></v-autocomplete>
              </v-row>
              <v-row class="py-2 pb-0">
                <v-col cols="12" class="py-0">
                  <v-subheader class="ml-0 pl-2" style="max-height: 28px">{{
                      $t('simulation.options.target_count')
                    }}
                  </v-subheader>
                  <v-slider class="align-center" v-model="simOptions.SETTINGS.TARGET_COUNT.VALUE" thumb-label
                            :min="simOptions.SETTINGS.TARGET_COUNT.MIN"
                            :max="simOptions.SETTINGS.TARGET_COUNT.MAX" hide-details :disabled="simOptions.SETTINGS.FIGHT_STYLE.VALUE !== 'Patchwerk'">
                    <template v-slot:append>
                      <v-text-field class="mt-0 pt-0" type="number" style="max-width: 100px" hide-details
                                    v-model="simOptions.SETTINGS.TARGET_COUNT.VALUE"></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
              <v-row class="py-2 pt-0 pb-0">
                <v-col cols="12" class="py-0">
                  <v-subheader class="ml-0 pl-2" style="max-height: 28px">{{
                      $t('simulation.options.iterations')
                    }}
                  </v-subheader>
                  <v-slider class="align-center" v-model="simOptions.SETTINGS.ITERATIONS.VALUE" thumb-label
                            :min="simOptions.SETTINGS.ITERATIONS.MIN"
                            :max="simOptions.SETTINGS.ITERATIONS.MAX" hide-details>
                    <template v-slot:append>
                      <v-text-field class="mt-0 pt-0" type="number" style="max-width: 100px" hide-details
                                    v-model="simOptions.SETTINGS.ITERATIONS.VALUE"></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-subheader class="ml-0 pl-2" style="max-height: 28px">{{
                      $t('simulation.options.combat_length')
                    }}
                  </v-subheader>
                  <v-slider class="align-center" v-model="simOptions.SETTINGS.MAX_TIME.VALUE" thumb-label
                            :min="simOptions.SETTINGS.MAX_TIME.MIN"
                            :max="simOptions.SETTINGS.MAX_TIME.MAX">
                    <template v-slot:append>
                      <v-text-field class="mt-0 pt-0" type="number" style="max-width: 100px" hide-details
                                    v-model="simOptions.SETTINGS.MAX_TIME.VALUE"></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-checkbox class="consumableCheckbox ml-2" :label="$t('simulation.options.stat_scaling')"
                              v-model="simOptions.SETTINGS.STAT_SCALING.VALUE"></v-checkbox>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel expand :value="2">
            <v-expansion-panel-header>
              <h3>{{ $t('simulation.consumables.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="px-2">
                <v-checkbox :label="$t('simulation.consumables.use_default')"
                            v-model="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
              </v-row>
              <v-row class="px-2">
                <v-checkbox class="consumableCheckbox" :label="$t('simulation.consumables.flasks.use')"
                            v-model="simOptions.CONSUMABLES.FLASK.USE"
                            :disabled="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
                <simulation-combobox :label="$t('simulation.consumables.flasks.title')" :items="consumables.flasks" :icon-type="'item'"
                                     identifier="FLASK" category="CONSUMABLES" :model="simOptions.CONSUMABLES.FLASK.VALUE"
                                     :disabled="!simOptions.CONSUMABLES.FLASK.USE || simOptions.CONSUMABLES.USE_DEFAULT.VALUE"
                                     language-category="consumables.flasks"></simulation-combobox>
              </v-row>
              <v-row class="px-2">
                <v-checkbox class="consumableCheckbox" :label="$t('simulation.consumables.potions.use')"
                            v-model="simOptions.CONSUMABLES.POTION.USE"
                            :disabled="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
                <simulation-combobox :label="$t('simulation.consumables.potions.title')" :items="consumables.potions" :icon-type="'item'"
                                     identifier="POTION" category="CONSUMABLES" :model="simOptions.CONSUMABLES.POTION.VALUE"
                                     :disabled="!simOptions.CONSUMABLES.POTION.USE || simOptions.CONSUMABLES.USE_DEFAULT.VALUE"
                                     language-category="consumables.potions"></simulation-combobox>
              </v-row>
              <v-row class="px-2">
                <v-checkbox class="consumableCheckbox" :label="$t('simulation.consumables.food.use')"
                            v-model="simOptions.CONSUMABLES.FOOD.USE"
                            :disabled="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
                <simulation-combobox :label="$t('simulation.consumables.food.title')" :items="consumables.food" :icon-type="'item'"
                                     identifier="FOOD" category="CONSUMABLES" :model="simOptions.CONSUMABLES.FOOD.VALUE"
                                     :disabled="!simOptions.CONSUMABLES.FOOD.USE || simOptions.CONSUMABLES.USE_DEFAULT.VALUE"
                                     language-category="consumables.food"></simulation-combobox>
              </v-row>
              <v-row class="px-2">
                <v-checkbox class="consumableCheckbox" :label="$t('simulation.consumables.augment_runes.use')"
                            v-model="simOptions.CONSUMABLES.AUGMENT_RUNE.USE"
                            :disabled="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
                <simulation-combobox :label="$t('simulation.consumables.augment_runes.title')" identifier="AUGMENT_RUNE" :icon-type="'item'"
                                     :items="consumables.augmentRunes" category="CONSUMABLES" :model="simOptions.CONSUMABLES.AUGMENT_RUNE.VALUE"
                                     :disabled="!simOptions.CONSUMABLES.AUGMENT_RUNE.USE || simOptions.CONSUMABLES.USE_DEFAULT.VALUE"
                                     language-category="consumables.augment_runes"></simulation-combobox>
              </v-row>
              <v-row class="px-2">
                <v-checkbox class="consumableCheckbox" :label="$t('simulation.consumables.weapon_runes.use')"
                            v-model="simOptions.CONSUMABLES.WEAPON_RUNE.USE"
                            :disabled="simOptions.CONSUMABLES.USE_DEFAULT.VALUE"></v-checkbox>
                <simulation-combobox :label="$t('simulation.consumables.weapon_runes.title')" identifier="WEAPON_RUNE" :icon-type="'item'"
                                     :items="consumables.weaponRunes" category="CONSUMABLES" :model="simOptions.CONSUMABLES.WEAPON_RUNE.VALUE"
                                     :disabled="!simOptions.CONSUMABLES.WEAPON_RUNE.USE || simOptions.CONSUMABLES.USE_DEFAULT.VALUE"
                                     language-category="consumables.weapon_runes"></simulation-combobox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- TEMPORARY DISABLE TRINKETS -->
<!--          <v-expansion-panel expand :value="3">-->
<!--            <v-expansion-panel-header>-->
<!--              <h3>{{ $t('simulation.trinkets.title') }}</h3>-->
<!--            </v-expansion-panel-header>-->
<!--            <v-expansion-panel-content>-->
<!--              <v-row class="px-2">-->
<!--                <simulation-combobox :label="$t('simulation.trinkets.chromatic_essence.title')" :items="simOptions.TRINKETS.CHROMATIC_ESSENCE.VALUES"-->
<!--                                     identifier="CHROMATIC_ESSENCE" category="TRINKETS" :icon-type="'spell'" :model="simOptions.TRINKETS.CHROMATIC_ESSENCE.VALUE"-->
<!--                                     language-category="trinkets.chromatic_essence"></simulation-combobox>-->
<!--              </v-row>-->
<!--              <v-row class="px-2">-->
<!--                <v-checkbox class="consumableCheckbox ml-1" :label="$t('simulation.trinkets.chromatic_essence.ally_buffs')"-->
<!--                            v-model="simOptions.TRINKETS.CHROMATIC_ESSENCE.USE_ALLY_BUFFS"></v-checkbox>-->
<!--              </v-row>-->
<!--            </v-expansion-panel-content>-->
<!--          </v-expansion-panel>-->
          <v-expansion-panel expand :value="4">
            <v-expansion-panel-header>
              <h3>{{ $t('simulation.buffs.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <simulation-checkbox :item="simOptions.BUFFS.BLOOD_LUST" :model="simOptions.BUFFS.BLOOD_LUST.VALUE"
                                   category="buffs" identifier="BLOOD_LUST"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.BATTLE_SHOUT" :model="simOptions.BUFFS.BATTLE_SHOUT.VALUE"
                                   category="buffs" identifier="BATTLE_SHOUT"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.ARCANE_INTELLECT"
                                   :model="simOptions.BUFFS.ARCANE_INTELLECT.VALUE"
                                   category="buffs" identifier="ARCANE_INTELLECT"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.MARK_OF_THE_WILD"
                                   :model="simOptions.BUFFS.MARK_OF_THE_WILD.VALUE"
                                   category="buffs" identifier="MARK_OF_THE_WILD"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.MYSTIC_TOUCH" :model="simOptions.BUFFS.MYSTIC_TOUCH.VALUE"
                                   category="buffs" identifier="MYSTIC_TOUCH"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.CHAOS_BRAND" :model="simOptions.BUFFS.CHAOS_BRAND.VALUE"
                                   category="buffs" identifier="CHAOS_BRAND"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.BLEEDING" :model="simOptions.BUFFS.BLEEDING.VALUE"
                                   category="buffs" identifier="BLEEDING"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.FORTITUDE" :model="simOptions.BUFFS.FORTITUDE.VALUE"
                                   category="buffs" identifier="FORTITUDE"></simulation-checkbox>
              <simulation-checkbox :item="simOptions.BUFFS.SKYFURY" :model="simOptions.BUFFS.SKYFURY.VALUE"
                                   category="buffs" identifier="SKYFURY"></simulation-checkbox>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AUGMENT_RUNES from "@/simulation/consumables/augment_runes.json";
import FLASKS from "@/simulation/consumables/tww/flasks.json";
import FOOD from "@/simulation/consumables/tww/food.json";
import POTIONS from "@/simulation/consumables/tww/potions.json";
import WEAPON_RUNES from "@/simulation/consumables/tww/weapon_enchantments.json";

import SimulationCheckbox from "@/components/controls/SimulationCheckbox.vue";
import SimulationCombobox from "@/components/controls/SimulationCombobox.vue";
import SimulationOptions from "@/simulation/SimulationOptions";

export default {
  name: "SimSettingsDialog",
  components: {SimulationCheckbox, SimulationCombobox},
  data() {
    return {
      isOpen: false,
      panels: [0, 1, 2, 3],
      simOptions: new SimulationOptions(),
      consumables: {
        flasks: Object.values(FLASKS),
        potions: Object.values(POTIONS),
        food: Object.values(FOOD),
        augmentRunes: Object.values(AUGMENT_RUNES),
        weaponRunes: Object.values(WEAPON_RUNES)
      }
    }
  },
  mounted() {
    this.simOptions.loadFromStorage();
    this.$root.$on('simSettingsOpen', () => {
      this.isOpen = true;
    });

    this.$root.$on('sim_combo_change', data => {
      this.simOptions[data.category][data.identifier].VALUE = data.value;
      this.isOpen = true;
    });

    this.$root.$on('sim_check_change', data => {
      this.simOptions.BUFFS[data.identifier].VALUE = data.value;
      this.isOpen = true;
    });
  },
  beforeDestroy() {
    this.$root.$off('simSettingsOpen');
  },
  methods: {
    restoreDefault(){
      this.simOptions.setToDefault();
      this.simOptions.save();
      this.$forceUpdate();
    },
    saveSettings() {
      this.simOptions.save();
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>
.consumableCheckbox {
  min-width: 280px;
}
</style>