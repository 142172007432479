<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab key="user-roles-tab">{{ $t('management.user.roles.title') }}</v-tab>
      <v-tab key="user-characters-tab">{{ $t('characters.title')}}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="user-roles-tab" class="ma-3">
        <h3 v-if="user.id === currentUser.data.id" class="pa-3">
          <v-icon>mdi-close-circle</v-icon>
          {{ $t('management.user.roles.role_assign_error') }}!
        </h3>
        <div v-else>
          <h3>
            <v-icon class="mr-2">mdi-account-key</v-icon>
            {{ $t('management.user.roles.title') }}
          </h3>
          <div class="pa-2">
            <v-checkbox v-for="role in user.roles" :key="role.id" :disabled="!canEditRoles"
                        v-model="role.selected"
                        :label="role.name.toUpperCase()" class="ml-3">
            </v-checkbox>
            <v-row class="ma-2 mt-2 justify-end">
              <v-btn color="primary" @click="updateUserRoles(user)" :disabled="!canEditRoles">
                <v-icon>mdi-content-save</v-icon>
                {{ $t('general.actions.save') }}
              </v-btn>
            </v-row>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item key="user-characters-tab" class="ma-3">
        <h4>MAIN</h4>
        <v-divider></v-divider>
        <v-list two-line v-if="mainCharacter != null">
          <v-list-item class="mt-2" :class="`${mainCharacter.class.toLowerCase().replaceAll(' ', '-')}-bg`">
            <class-avatar :size="40" :type="'class'" :value="mainCharacter.class.toLowerCase()"
                          class="mr-3"></class-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <b :class="mainCharacter.class.toLowerCase().replaceAll(' ', '-')">{{ mainCharacter.name }}</b>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{mainCharacter.realm}} | {{ mainCharacter.level }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <h3 class="pa-3" v-else>
          <v-icon>mdi-close-circle</v-icon>
          {{ $t('user_detail.no_main_selected') }}
        </h3>
        <h4>TWINKS</h4>
        <v-divider></v-divider>
        <v-list two-line>
          <v-list-item class="mt-2" :class="`${character.class.toLowerCase().replaceAll(' ', '-')}-bg`"
                       v-for="character of twinks" :key="character.id">
            <class-avatar :size="40" :type="'class'" :value="character.class.toLowerCase()" class="mr-3"></class-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <b :class="character.class.toLowerCase().replaceAll(' ', '-')">{{ character.name }}</b>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{character.realm}} | {{ character.level }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="openUnlinkConfirmation(character)" v-bind="attrs" v-on="on">
                      <v-icon>mdi-link-off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('management.user.character_assign.existing_user.delete_character_link') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :disabled="mainCharacter != null && mainCharacter.rosterFire"
                           @click="markCharacterAsMain(character)">
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('user_detail.mark_as_main') }}</span>
                </v-tooltip>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-tab-item>
    </v-tabs-items>

    <confirmation-dialog identifier="unlinkCharacterFromDetails" :question="$t('management.character_assign.unlink_confirmation')" :confirmation-handler="unlinkCharacterFromUser"></confirmation-dialog>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import MessageService from "@/services/message.service";
import ClassAvatar from "@/components/ClassAvatar.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import CharacterService from "@/services/character.service";

export default {
  name: "UserDetailsView",
  components: {ConfirmationDialog, ClassAvatar},
  props: ['user'],
  data() {
    return {
      tab: 0,
      mainCharacter: null,
      twinks: [],
      characterToUnlink: null
    }
  },
  mounted() {
    this.loadMainCharacterAndTwinks();
  },
  methods: {
    loadMainCharacterAndTwinks(){
      this.twinks = [];
      this.mainCharacter = null;

      this.user.characters.forEach(char => {
        if (this.user.mainCharacter != null && char.id === this.user.mainCharacter) {
          this.mainCharacter = char;
        } else {
          this.twinks.push(char);
        }
      });

      this.twinks = this.twinks.sort((a,b) => {
        let realmCompare = a.realm.localeCompare(b.realm);
        let levelCompare = b.level - a.level;
        let classCompare = a.class.localeCompare(b.class);

        return realmCompare || levelCompare || classCompare;
      });
    },
    updateUserRoles: function (user) {
      if (this.canEditRoles) {
        UserService.updateUserRoles(user.id, user.roles.filter(x => x.selected));
      } else {
        MessageService.error("No permissions to update");
      }
    },
    markCharacterAsMain(character) {
      UserService.markCharacterAsMain(this.user.id, character.id).then(success => {
        if(success){
          let found = this.user.characters.find(x => x.id === character.id);
          if (found) {
            this.user.mainCharacter = found.id;
          }

          this.loadMainCharacterAndTwinks();
        }
      });
    },
    openUnlinkConfirmation(character){
      this.characterToUnlink = character;
      this.$root.$emit('unlinkCharacterFromDetails_confirmationOpen', true);
    },
    unlinkCharacterFromUser() {
      CharacterService.updateCharacter(this.characterToUnlink.id, {
        userId: null
      }).then(data => {
        if(data){
          let index = this.user.characters.findIndex(x => x.id === this.characterToUnlink.id);
          if (index !== -1) {
            this.user.characters.splice(index, 1);
            this.characterToUnlink = null;
            this.loadMainCharacterAndTwinks()
          }
        }
        this.$root.$emit('unlinkCharacterFromDetails_confirmationOpen', false);
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    canEditRoles() {
      return this.currentUser && this.currentUser?.rights.includes("EDIT_ADMIN_DATA");
    }
  }
}
</script>


<style scoped lang="scss">

</style>