import DataService from '../services/data.service';

export const data = {
    namespaced: true,
    state: {
        battleNetClientId: "",
        appConfig: [],
        realmList: [],
        classInfo: { list: [], map: {}},
        specInfo: { list: [], map: {}},
        heroInfo: { list: [], map: {}}
    },
    getters: {
        battleNetClientId: state => state.battleNetClientId,
        classInfo: state => state.classInfo,
        specInfo: state => state.specInfo,
        heroInfo: state => state.heroInfo,
        appConfig: state => state.appConfig,
        realmList: state => state.realmList
    },
    actions: {
        getRealmList({commit}){
            DataService.getRealmList().then(data => {
                commit('SET_REALM_LIST', data?.sort((a,b) => a.name.localeCompare(b.name)));
                return Promise.resolve(data);
            }, error => {
                return Promise.reject(error);
            });
        },

        getClassList({commit}){
            DataService.getClassList().then(data => {
                commit('SET_CLASS_LIST', data);
                return Promise.resolve(data);
            }, error => {
                return Promise.reject(error);
            });
        },

        getSpecializationList({commit}){
            DataService.getSpecializationList().then(data => {
                commit('SET_SPEC_LIST', data);
                return Promise.resolve(data);
            }, error => {
                return Promise.reject(error);
            });
        },

        getHeroList({commit}){
            DataService.getHeroList().then(data => {
                commit('SET_HERO_LIST', data);
                return Promise.resolve(data);
            }, error => {
                return Promise.reject(error);
            });
        },

        battleNetClientId({commit}){
            DataService.getBattleNetClientApi().then(data => {
                commit('SET_BNET_CLIENT_ID', data);
                return Promise.resolve(data);
            }, error => {
                return Promise.reject(error);
            });
        },

        getAppConfig({ commit }) {
            DataService.getAllAppConfigs().then(
                data => {
                    commit('SET_APP_CONFIG', data);
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateAppConfig({ commit }, appConfig) {
            return DataService.saveAppConfigs(appConfig).then(
                data => {
                    commit('SET_APP_CONFIG', appConfig);
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        SET_APP_CONFIG: (state, value) => {
            state.appConfig = value.map(config => ({
                ...config,
                mappedValue: mapConfigValue(config)
            }));
        },
        SET_BNET_CLIENT_ID: (state, value) => {
            state.battleNetClientId = value;
        },
        SET_REALM_LIST: (state, value) => {
            state.realmList = value;
        },
        SET_CLASS_LIST: (state, value) => {
            state.classInfo = value;
        },
        SET_SPEC_LIST: (state, value) => {
            state.specInfo = value;
        },
        SET_HERO_LIST: (state, value) => {
            state.heroInfo = value;
        }
    }
};

function mapConfigValue(config){
    if(config.type === "combo-multi"){
        return config.value.split(";");
    } else if( config.type === "checkbox"){
        return config.value === "true";
    } else {
        return config.value;
    }
}
