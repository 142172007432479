<template>
  <div>
    <div v-if="isLoading">
      <v-progress-circular color="primary" indeterminate :size="25" class="ml-1 mt-1 pa-2 align-center d-inline-block"></v-progress-circular>
    </div>
    <div v-else>
      <p v-if="errorOnLoadingTree">Tree not available for class '{{ classDataResolved }}' and game version
        '{{ gameVersion }}'.</p>
      <div v-else>
        <v-row class="row--dense py-2">
          <v-col>
            <v-btn v-if="talentVersion === loadedTalentVersion && talentString && talentString.length > 0"
                   @click="copyToClipboard(talentString)" class="mt-4">
              <v-icon>mdi-share</v-icon>
              {{ $t('general.actions.share') }}
            </v-btn>
            <v-btn v-if="talentVersion === loadedTalentVersion" @click="isOpenImportTalents = true" class="ml-1 mt-4">
              <v-icon>mdi-download-outline</v-icon>
              {{ $t('general.actions.import') }}
            </v-btn>
            <v-btn v-if="talentString && talentString.length > 0" @click="clearTrees()" class="ml-1 mt-4">
              <v-icon>mdi-refresh</v-icon>
              {{ $t('general.actions.clear') }}
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-chip variant="elevated" label color="primary" v-if="talentString && talentString.length > 0 && showDps"
                    class="mt-4">
              <b>DPS:</b> {{ dps?.toFixed(0) }}
            </v-chip>
          </v-col>
          <v-col align="right">
          <span v-for="specialization in specList" v-bind:key="specialization.id" class="mr-1">
            <v-btn icon x-large @click="changeSpec(specialization)" class="align-center ml-4 mb-1 spec-btn"
                   :active="spec.id === specialization.id" :id1="spec.id" :id2="specialization.id">
               <specialization-avatar v-bind:size="50" v-bind:type="classDataResolved.toLowerCase()"
                                      v-bind:value="specialization.key"
                                      class="spec-avatar"/>
            </v-btn>
          </span>
          </v-col>
        </v-row>
        <v-row class="row--dense">
          <v-col v-for="treeName in treeNames" v-bind:key="treeName" :class="getClass(treeName)">
            <v-card class="mx-auto fill-height talentCard pa-0" outlined
                    :class="`${classDataResolved?.toLowerCase().replaceAll(' ', '-')}-border`"
                    v-if="hasHeroTalents && treeName === 'hero'">
              <v-card-title class="px-3 py-3" :class="`${classDataResolved?.toLowerCase().replaceAll(' ', '-')}-bg`">
                <v-row class="row--dense align-center">
                  <v-col class="pa-1 col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12">
                    <v-btn value="hero-left" small block @click="changeHeroChoice('hero-left')"
                           :color="classDataResolved?.toLowerCase().replaceAll(' ', '-')"
                           :plain="selectedHero !== 'hero-left'" :outlined="selectedHero === 'hero-left'">
                      {{ heroMap[spec.hero['hero-left'].name][$i18n.locale] }}
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1 col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12">
                    <v-btn value="hero-right" small block @click="changeHeroChoice('hero-right')"
                           :color="classDataResolved?.toLowerCase().replaceAll(' ', '-')"
                           :plain="selectedHero !== 'hero-right'" :outlined="selectedHero === 'hero-right'">
                      {{ heroMap[spec.hero['hero-right'].name][$i18n.locale] }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-4">
                <div class="mx-auto ma-4 pa-3 treeContainer">
                  <v-row v-for="r in getUsedRowsHero" v-bind:key="r" class="row--dense" style="z-index: 10">
                    <v-col v-for="c in getUsedColumnsHero" v-bind:key="c" class="hero">
                      <div v-for="node in getHeroNode(selectedHero, r, c)" v-bind:key="node.id" class="iconSpace">
                        <a v-bind:href="`https://www.wowhead.com/spell=${talent.spellId}`" target="_blank"
                           :data-wowhead="`domain=${$i18n.locale}`"
                           v-for="(talent, n) in node.spells" v-bind:key="talent.definitionId"
                           :class="node.type === 'choice' ? ('choice choice' + (n===0 ? '-left' : '-right')) : ''"
                           :active="talent.isActive" :type="node.type.toLowerCase()"
                           :partial="node.isPartiallyFilled"
                           :style="{backgroundImage: `url(`+ getTalentMedia(talent) + `)`}"
                           @click.prevent="!talent.isDefault ? changeTalentStatus(node, talent, true) : null"
                           @contextmenu.prevent="!talent.isDefault ? changeTalentStatus(node, talent, false) : null"
                           @touch.prevent="!talent.isDefault ? changeTalentStatus(node, talent, false) : null">
                          <v-badge :content="getNodeBadge(node)" class="iconBadge" v-if="!node.isDefault"></v-badge>
                        </a>
                      </div>
                      <div v-if="!getNode(selectedHero, r, c).length" class="iconSpace"></div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto fill-height talentCard pa-0" outlined
                    :class="`${classDataResolved?.toLowerCase().replaceAll(' ', '-')}-border`" v-else>
              <v-card-title class="px-3 py-3" :class="`${classDataResolved?.toLowerCase().replaceAll(' ', '-')}-bg`">
                <span v-if="treeName === 'class'">
                   <class-avatar v-bind:size="25" v-bind:value="classDataResolved.toLowerCase()" class="mr-2"/>
                  <span :class="classDataResolved?.toLowerCase().replaceAll(' ', '-')">
                    {{ getFromClassMap(classDataResolved, $i18n.locale) }}
                  </span>
                </span>
                <span v-else>
                  <specialization-avatar v-bind:size="25" v-bind:type="classDataResolved.toLowerCase()"
                                         v-bind:value="spec.name.toLowerCase()"
                                         class="mr-2"/>
                  <span :class="classDataResolved?.toLowerCase().replaceAll(' ', '-')">
                    {{ specMap.hasOwnProperty(spec.name) ? specMap[spec.name][$i18n.locale] : "" }}
                  </span>
                </span>
              </v-card-title>
              <v-card-text class="pa-4">
                <div class="mx-auto ma-4 pa-3 treeContainer">
                  <v-row v-for="r in getUsedRows" v-bind:key="r" class="row--dense" style="z-index: 10">
                    <v-col v-for="c in getUsedColumns" v-bind:key="c">
                      <div v-for="node in getNode(treeName, r, c)" v-bind:key="node.id" class="iconSpace">
                        <a v-bind:href="`https://www.wowhead.com/spell=${talent.spellId}`" target="_blank"
                           :data-wowhead="`domain=${$i18n.locale}`"
                           v-for="(talent, n) in node.spells" v-bind:key="talent.definitionId"
                           :class="node.type === 'choice' ? ('choice choice' + (n===0 ? '-left' : '-right')) : ''"
                           :active="talent.isActive" :type="node.type.toLowerCase()"
                           :partial="node.isPartiallyFilled"
                           :style="{backgroundImage: `url(`+ getTalentMedia(talent) + `)`}"
                           @click.prevent="!talent.isDefault ? changeTalentStatus(node, talent, true) : null"
                           @contextmenu.prevent="!talent.isDefault ? changeTalentStatus(node, talent, false) : null"
                           @touch.prevent="!talent.isDefault ? changeTalentStatus(node, talent, false) : null">
                          <v-badge :content="getNodeBadge(node)" class="iconBadge" v-if="!node.isDefault"></v-badge>
                        </a>
                      </div>
                      <div v-if="!getNode(treeName, r, c).length" class="iconSpace"></div>
                    </v-col>
                  </v-row>
                </div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="isOpenImportTalents" persistent max-width="500px">
      <v-card>
        <v-card-title class="mt-2">
          {{ $t('simulation.import_talents') }}
        </v-card-title>
        <v-card-text class="mb-2">
          <v-form class="pa-2">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field :label="$t('simulation.talentString') " v-model="importTalents"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4 justify-end">
          <v-btn @click="isOpenImportTalents = false">{{ $t('general.actions.cancel') }}</v-btn>
          <v-btn color="primary" @click="readTalentString(importTalents)">{{ $t('general.actions.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BinaryArrayWriter from "@/util/BinaryArrayWriter";
import SpecializationAvatar from "@/components/SpecializationAvatar";
import ClassAvatar from "@/components/ClassAvatar";
import binaryArrayReader from "@/util/BinaryArrayReader";
import DataService from "@/services/data.service";
import MessageService from "@/services/message.service";

export default {
  name: "TalentTree",
  props: ["classData", "showDps", "gameVersion"],
  components: {SpecializationAvatar, ClassAvatar},
  data() {
    return {
      trees: {},
      isLoading: true,
      errorOnLoadingTree: false,
      hasHeroTalents: false,
      nodes: [],
      nodeIds: [],
      heroMetaNodes: [],
      classId: 0,
      classInfo: [],
      specList: [],
      defaultNodes: [],
      importTalents: "",
      talentString: "",
      talentVersion: 2,
      loadedTalentVersion: 0,
      classDataResolved: "",
      spec: "",
      dps: 0,
      isOpenImportTalents: false,
      selectedHero: "",
      selectedHeroMetaNode: 0,
      treeNames: ["class", "hero", "spec"],
      classMap: this.$store.getters["data/classInfo"].map,
      specMap: this.$store.getters["data/specInfo"].map,
      heroMap: this.$store.getters["data/heroInfo"].map,
    }
  },
  mounted() {
    this.classDataResolved = this.classData;

    this.$root.$on('dps', (value) => {
      this.dps = value;
    });

    this.$root.$on('changeClass', (value) => {
      this.talentString = "";
      this.classDataResolved = value;
      this.loadTree(value);
    });

    this.$root.$on('changeLoadout', (value) => {
      this.talentString = value;
      this.readTalentString(value);
    });
  },
  methods: {
    getClass(treeName) {
      if (treeName === "hero") {
        return "pa-1 col-12 col-md-12 col-lg-2 col-sm-12";
      } else {
        return "pa-1 col-12 col-md-12 col-lg-5 col-sm-12";
      }
    },
    getFromClassMap(className, locale) {
      let key = className.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
      return this.classMap[key][locale];
    },
    getTalentMedia(talent) {
      if (talent.media) {
        return talent.media;
      } else {
        return `${process.env.VUE_APP_BACKEND_URL}:${process.env.VUE_APP_BACKEND_PORT}/service/data/talent-image`;
      }
    },
    async loadTree(className, spec) {
      this.isLoading = true;

      let result = await DataService.getTalentTreeForClass(this.gameVersion, className.toLowerCase());
      if (result) {
        this.loadedTalentVersion = result.talentVersion;
        this.$root.$emit("readonlyMode", this.talentVersion !== this.loadedTalentVersion);

        this.errorOnLoadingTree = false;
        this.classInfo = result["classData"];

        this.nodes = result["all"].map(node => ({
          ...node,
          spells: node.spells.map(spell => ({
            ...spell,
            href_wowhead: `https://www.wowhead.com/spell=${spell.spellId}`
          }))
        }));
        this.nodeIds = this.nodes.map(x => x.id).sort((a, b) => a - b);
        this.nodeIds = this.nodeIds.concat(result["unusedIds"]);
        this.nodeIds = this.nodeIds.concat(result["heroMetaNodes"]);
        this.heroMetaNodes = result["heroMetaNodes"];
        this.defaultNodes = result["defaultNodes"];
        this.specList = result["specs"];
        this.classId = result["classData"].classes.find(x => x.name = className.toLowerCase()).classId;

        if (spec) {
          let found = this.specList.find(x => x.id === spec.id);
          this.spec = found || this.specList[0];
        } else {
          this.spec = this.specList[0];
        }

        this.hasHeroTalents = result["hasHeroTalents"];
        if (this.hasHeroTalents) {
          this.treeNames = ["class", "hero", "spec"];
          this.selectedHero = "hero-left";
          this.selectedHeroMetaNode = this.spec.hero.metaNodeId;
        } else {
          this.treeNames = ["class", "spec"];
        }

        this.changeSpec(this.spec);
      } else {
        this.errorOnLoadingTree = true;
      }

      this.isLoading = false;
    },
    getHeroNode(treeName, r, c) {
      let heroTreeInfo = this.spec?.hero[treeName];
      return this.nodes.filter(x => x.heroTreeId === heroTreeInfo["subTreeID"] && x.specs.includes(this.spec.name.toLowerCase()) && x.row === r && x.column === c && this.getIsColUsed("hero", c));
    },
    getNode(type, r, c) {
      return this.nodes.filter(x => x.tree === type && x.specs.includes(this.spec.name.toLowerCase()) && x.row === r && x.column === c && this.getIsColUsed(type, c));
    },
    getIsColUsed(type, c) {
      return this.nodes.filter(x => x.tree === type && x.specs.includes(this.spec.name.toLowerCase()) && x.column === c).length > 0;
    },
    getNodeBadge(node) {
      return `${node.pointsInvested}/${node.maxRanks}`;
    },
    changeTalentStatus(node, choice, activate) {
      node.spells.forEach(x => x.isActive = false);
      choice.isActive = activate;

      if (activate) {
        if (node.pointsInvested < node.maxRanks) {
          node.pointsInvested++;
        }
      } else {
        if (node.pointsInvested > 0) {
          node.pointsInvested--;
        }
      }

      node.isPartiallyFilled = node.pointsInvested < node.maxRanks;
      this.talentString = this.createTalentString();
      this.$root.$emit('talents', this.talentString);
    },

    createTalentString() {
      BinaryArrayWriter.initialize();
      const binaryWriter = BinaryArrayWriter;

      binaryWriter.write(8, this.talentVersion);
      binaryWriter.write(16, this.spec.id);
      binaryWriter.write(128, 0);

      this.nodeIds.forEach(nodeId => {
        let node = this.nodes.find(x => x.id === nodeId);

        if (node) {
          this.writeNode(binaryWriter, node);
        } else {
          this.writeHeroNode(binaryWriter, nodeId);
        }
      });

      return binaryWriter.toExportString();
    },
    writeNode(binaryWriter, node) {
      let hasChoices = node.spells.length > 1;
      let isActive = node.spells.some(x => x.isActive && !x.isDefault);
      if (isActive) {
        binaryWriter.write(1, 1);
        binaryWriter.write(1, 1);
        if (node.isPartiallyFilled) {
          binaryWriter.write(1, 1);
          binaryWriter.write(6, node.pointsInvested)
        } else {
          binaryWriter.write(1, 0);
        }

        if (hasChoices) {
          binaryWriter.write(1, 1);
          let activeChoice = node.spells.findIndex(x => x.isActive);
          binaryWriter.write(2, activeChoice);
        } else {
          binaryWriter.write(1, 0);
        }
      } else {
        binaryWriter.write(1, 0);
      }
    },
    writeHeroNode(binaryWriter, nodeId) {
      if(nodeId === this.selectedHeroMetaNode){
        binaryWriter.write(1, 1); //active
        binaryWriter.write(1, 1); //not partially filled
        binaryWriter.write(1, 0);
        binaryWriter.write(1, 1);

        let treeIndex = this.selectedHero === "hero-left" ? 0 : 1; //TODO: statt left/right einfach 0,1,2,3 nutzen, wobei 0=left und 1=right
        binaryWriter.write(2, treeIndex); //tree index [00 || 01 || 10 || 11]
      } else {
        binaryWriter.write(1, 0); //not selected
      }
    },

    async readTalentString(talents) {
      binaryArrayReader.initialize(talents);
      const binaryReader = binaryArrayReader;

      const version = binaryReader.read(8);
      if (version === this.talentVersion) {
        const specId = binaryReader.read(16);
        const loadedSpec = this.classInfo.specs.find(x => x.id === specId);
        if (loadedSpec) {
          const classId = this.classInfo.specs.find(x => x.id === specId).classId;
          this.classDataResolved = loadedSpec.className;

          if (this.classId !== classId) {
            this.$root.$emit('importClass', loadedSpec.className);
            await this.loadTree(loadedSpec.className, loadedSpec);
          } else if (this.spec.id !== specId) {
            this.changeSpec(loadedSpec);
          }

          //unused
          binaryReader.read(128);

          let heroMetaNode;
          this.nodeIds.sort((a, b) => a - b).forEach(nodeId => {
            let isHeroMetaNode = this.heroMetaNodes.includes(nodeId);
            let node = this.nodes.find(x => x.id === nodeId);

            let isSelectedNode = binaryReader.read(1);
            if (isSelectedNode === 1) {
              let isPurchasedNode = binaryReader.read(1);
              if (isPurchasedNode === 1) {
                if (!isHeroMetaNode) {
                  if (node) {
                    this.readNode(binaryReader, node);
                  } else {
                    console.warn(`Error on searching node '${nodeId}'`)
                  }

                } else {
                  heroMetaNode = this.readHeroNode(binaryReader, nodeId);
                }
              }
            }
          });

          if (this.hasHeroTalents && heroMetaNode) {
            this.selectedHero = heroMetaNode.entryChosen === 0 ? "hero-left" : "hero-right";
            this.selectedHeroMetaNode = heroMetaNode.id;
          }

          this.talentString = talents;
          this.importTalents = "";
          this.$root.$emit('talents', this.talentString);
        } else {
          MessageService.error("Import failed. The talent string is not for the current supported version.");
        }

        this.isOpenImportTalents = false;
      }
    },
    readNode(binaryReader, node) {
      let isPartiallyRankedNode = binaryReader.read(1);
      if (isPartiallyRankedNode === 1) {
        node.pointsInvested = binaryReader.read(6);
        node.isPartiallyFilled = true;
      } else {
        node.isPartiallyFilled = false;
        node.pointsInvested = node.maxRanks;
      }

      let isChoiceNode = binaryReader.read(1);
      if (isChoiceNode === 1) {
        let entryChosen = binaryReader.read(2);
        node["entryChosen"] = entryChosen;
        if (node.spells[entryChosen]) {
          node.spells[entryChosen].isActive = true;
        } else {
          console.warn(`Error on activating talent - '${node.id}' - row: '${node.row}' - column: '${node.column}' - talent is no choice node`);
        }
      } else {
        node.spells[0].isActive = true;
      }
    },
    readHeroNode(binaryReader, nodeId) {
      let entryChosen;

      let isPartiallyRankedNode = binaryReader.read(1);
      if (isPartiallyRankedNode === 1) {
        binaryReader.read(6);
      }

      let isChoiceNode = binaryReader.read(1);
      if (isChoiceNode === 1) {
        entryChosen = binaryReader.read(2);
      }

      return {
        id: nodeId,
        entryChosen: entryChosen
      }
    },

    changeSpec(spec) {
      if (spec !== undefined) {
        this.spec = spec;
        //reset default talents for spec
        this.nodes.forEach(n => {
          n.spells.forEach(t => {
            t.isDefault = this.defaultNodes[this.spec.name.toLowerCase()].includes(n.id);
          });
          n.isDefault = n.spells.filter(x => x.isDefault).length > 0
        });

        this.$root.$emit('spec', spec.name.toLowerCase());
        this.clearTrees();
        this.$forceUpdate();
      }
    },
    changeHeroChoice(heroChoice) {
      //clear hero nodes
      this.nodes.filter(x => x.tree === "hero").forEach(node => {
        node.pointsInvested = node.isDefault ? 1 : 0;
        node.isActive = node.isDefault ? 1 : 0;
        node.spells.forEach(spell => {
          spell.isActive = node.isDefault;
          spell.pointsInvested = node.isDefault ? 1 : 0;
        });
      });
      this.$forceUpdate();

      this.selectedHero = heroChoice;
    },
    async copyToClipboard(text) {
      if (navigator.clipboard && text) {
        await navigator.clipboard.writeText(text);
      } else {
        MessageService.warn("Talents could not be copied.");
      }
    },
    clearTrees() {
      this.nodes.forEach(node => {
        node.pointsInvested = node.isDefault ? 1 : 0;
        node.isActive = node.isDefault ? 1 : 0;
        node.spells.forEach(spell => {
          spell.isActive = node.isDefault;
          spell.pointsInvested = node.isDefault ? 1 : 0;
        });
      });
      this.$forceUpdate();
    }
  },
  beforeDestroy() {
    this.$root.$off('dps');
    this.$root.$off('changeClass');
    this.$root.$off('changeLoadout');
  },
  watch: {
    gameVersion: function (newVal) {
      if (newVal !== "") {
        this.loadTree(this.classDataResolved);
        if (this.talentString !== "") {
          this.readTalentString(this.talentString);
        }
      }
    }
  },
  computed: {
    getUsedColumns() {
      let minUsedColSpec = Math.min(...this.nodes.filter(x => x.tree === "spec" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));
      let minUsedColClass = Math.min(...this.nodes.filter(x => x.tree === "class" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));
      let maxUsedColSpec = Math.max(...this.nodes.filter(x => x.tree === "spec" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));
      let maxUsedColClass = Math.max(...this.nodes.filter(x => x.tree === "class" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));

      let start = Math.min(minUsedColSpec, minUsedColClass);
      let end = Math.max(maxUsedColSpec, maxUsedColClass);
      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
    getUsedRows() {
      let minUsedColSpec = Math.min(...this.nodes.filter(x => x.tree === "spec" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));
      let minUsedColClass = Math.min(...this.nodes.filter(x => x.tree === "class" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));
      let maxUsedColSpec = Math.max(...this.nodes.filter(x => x.tree === "spec" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));
      let maxUsedColClass = Math.max(...this.nodes.filter(x => x.tree === "class" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));

      let start = Math.min(minUsedColSpec, minUsedColClass);
      let end = Math.max(maxUsedColSpec, maxUsedColClass);
      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
    getUsedRowsHero() {
      let start = Math.min(...this.nodes.filter(x => x.tree === "hero" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));
      let end = Math.max(...this.nodes.filter(x => x.tree === "hero" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.row));

      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
    getUsedColumnsHero() {
      let start = Math.min(...this.nodes.filter(x => x.tree === "hero" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));
      let end = Math.max(...this.nodes.filter(x => x.tree === "hero" && x.specs.includes(this.spec.name.toLowerCase())).map(x => x.column));

      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    }
  }
}
</script>

<style scoped lang="scss">
$orange: rgba(255, 160, 0);
$silver: silver;

.spec-avatar {
  margin-left: -1.43px;
}

.v-btn--icon.v-size--x-large.spec-btn {
  height: 50px;
  width: 50px;
}

.spec-btn {
  &[active] {
    box-shadow: $orange 0 0 15px 0;
  }

  &:not([active]) {
    box-shadow: $silver 0 0 15px 0;
  }
}

.iconSpace {
  position: relative;
  margin-top: 200%;

  a {
    display: block;

    &:not(.iterator) {
      background-size: cover; /*default picture when na*/
      position: absolute;
      inset: -50%;
      margin-top: -200%;

      &[type=passive] {
        border-radius: 50%;
      }

      &[partial][active] {
        filter: none;
        border: 2px solid white;
        box-shadow: white 0 0 7px 0;
      }

      &[unlocked]:not([active]) {
        filter: none;
        border: 2px solid lime;
        box-shadow: lime 0 0 7px 0;
      }

      &:not([active]) {
        filter: grayscale(1);
        border: 2px solid grey;
      }

      &[active] {
        border: 2px solid orange;
        box-shadow: orange 0 0 7px 0;
        z-index: 6;
        width: 200%;
        margin-left: 0 !important;
      }
    }

    &.choice {
      width: 100%;
      z-index: 5;
      transition: width 0.5s ease, margin 0.5s ease;

      &:hover {
        z-index: 6;
        width: 200%;
      }

      &.choice-right {
        margin-left: 100%;
        background-position-x: right;

        &:hover {
          margin-left: 0;
        }
      }
    }
  }
}

.row--dense > .col, .row--dense > [class*=col-] {
  padding: 0.8% 0.8%;
}

.col.hero {
  padding: 9.5% 9.5%;
}

.talentCard {
  padding: 5%;
  border-width: medium !important;
}

.treeContainer {
  max-width: 600px;
}

.hero-toggle {
  margin: 2px !important;
  padding: 5px !important;
}
</style>