export default class SimulationCharacter {
    ID;
    NAME;
    LEVEL;
    CLASS_NAME;
    SPEC;
    REALM;
    RACE;

    constructor(character, spec) {
        this.ID = character.id;
        this.NAME = character.name.toLowerCase();
        this.LEVEL = character.level;
        this.CLASS_NAME = character.class.toLowerCase().replaceAll(" ", "");
        this.SPEC = spec;
        this.REALM = character.realm.toLowerCase().replace(" ", "-").replace("'", "");
        this.RACE = character.details.race.toLowerCase();
    }

    toJson() {
        return {
            id: this.ID,
            name: this.NAME,
            level: this.LEVEL,
            className: this.CLASS_NAME,
            spec: this.SPEC,
            realm: this.REALM,
            race: this.RACE
        }
    }
}