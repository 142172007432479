<template>
  <v-container>
    <div v-if="currentUser">
      <h2 class="mb-4 ml-2">{{ $t('sign_in.user_msg') }}</h2>
    </div>
    <div v-if="!currentUser">
      <v-layout row wrap>
        <v-flex xs12 class="text-center" mt-5>
          <h1>{{ $t('sign_in.title') }}</h1>
        </v-flex>
        <v-flex xs12 sm6 offset-sm3 mt-3>
          <form v-on:submit.prevent="handleSignIn()">
            <v-layout column>
              <v-flex>
                <v-text-field v-model="name" :error-messages="nameErrors" :label="$t('sign_in.name.title')" required
                              @input="$v.name.$touch()" @blur="$v.name.$touch()" autocomplete="username"></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field v-model="password" :error-messages="passwordErrors" :label="$t('sign_in.password.title')" required
                              @input="$v.password.$touch()" @blur="$v.password.$touch()" type="password" autocomplete="current-password"></v-text-field>
              </v-flex>
              <v-flex class="text-center" mt-5>
                <v-btn color="secondary" class="mr-2" @click="navigateToPasswordReset">{{ $t('sign_in.forgot_password') }}</v-btn>
                <v-btn color="primary" type="submit">{{ $t('general.actions.submit') }}</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  name: "Signin",
  mixins: [validationMixin],
  validations: {
    name: {required},
    password: {required},
  },
  data: () => ({
    name: '',
    password: ''
  }),
  methods: {
    handleSignIn() {
      if (!this.$v.invalid) {
        this.$store.dispatch("auth/signIn", {
          username: this.$v.name.$model,
          password: this.$v.password.$model
        }).then(() => {
          if (this.$route.path !== '/user-account') {
            this.$router.push("/user-account");
          }
        }, (error) => {
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        });
      }
    },
    navigateToPasswordReset(){
      this.$router.push("/password-reset");
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t('sign_in.name.required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('sign_in.password.required'))
      return errors
    }
  }
}
</script>

<style scoped>

</style>
