<template>
  <div>
    <div>
      <v-row class="pa-3 align-center">
        <v-avatar>
          <v-icon medium>mdi-account-group</v-icon>
        </v-avatar>
        <h3>{{$t('user_characters.title')}}</h3>
      </v-row>
      <v-divider></v-divider>
      <v-card>
        <v-card-text class="secondary lighten-1">
          <p ><v-icon class="mb-1">mdi-information</v-icon> <b>{{ $t('general.misc.information') }}</b></p>
          <p>{{ $t('user_characters.information.text_1')}}</p>
          <p>{{ $t('user_characters.information.text_2')}}</p>
          <ul>
            <li>{{ $t('user_characters.information.list_1')}}</li>
            <li>{{ $t('user_characters.information.list_2')}}</li>
            <li>{{ $t('user_characters.information.list_3')}}</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-row class="mt-2 px-4 align-center justify-end">
        <v-btn class="ml-2" color="primary" @click="authorize" :disabled="currentUser.data.isGeneric || hasOriginalId">
          {{$t('user_characters.bnet_sync')}}
        </v-btn>
      </v-row>
      <v-row class="pa-2">
        <v-expansion-panels multiple v-model="panels" v-if="Object.keys(characters).length > 0">
          <v-expansion-panel v-for="(realm, index) of Object.keys(characters)" :key="realm" expand :value="index">
            <v-expansion-panel-header>
              <h4>
                <v-icon class="mr-2">mdi-earth</v-icon>
                {{ realm }}
                <v-chip small class="ma-2" color="primary">
                  {{ characters[realm].length }}
                </v-chip>
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list two-line>
                <v-list-item class="mt-2" :class="`${character.class.toLowerCase().replaceAll(' ', '-')}-bg`" v-for="character of characters[realm]" :key="character.id">
                  <class-avatar :size="40" :type="'class'" :value="character.class.toLowerCase()" class="mr-3"></class-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b :class="character.class.toLowerCase().replaceAll(' ', '-')">{{character.name}}</b>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Level {{character.level}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="mt-1">
          <p class="font-weight-medium ma-2">
            <v-icon>mdi-close-circle</v-icon>
            {{$t('user_characters.no_characters_synced')}}
          </p>
        </div>
      </v-row>
    </div>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpenNewCharacters">
      <v-card>
        <v-toolbar flat dense color="primary">
          <v-icon class="mr-1">mdi-account-switch</v-icon>
          {{$t('user_characters.sync_new')}}
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels class="mt-2" multiple>
            <v-expansion-panel v-for="realm of Object.keys(newCharacters)" :key="realm">
              <v-expansion-panel-header>
                <h4>
                  <v-icon class="mr-2">mdi-earth</v-icon>
                  {{ realm }}
                  <v-chip small class="ma-2" color="primary">
                    {{ newCharacters[realm].length }}
                  </v-chip>
                </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="align-center" v-for="character of newCharacters[realm]" :key="character.id" dense>
                  <v-checkbox v-model="character.selected" class="pt-2"></v-checkbox>
                  <h4 class="font-weight-medium mb-0">
                    <class-avatar :size="18" :type="'class'" :value="character['playable_class'].name.toLowerCase()" class="mr-1"></class-avatar>
                    <span :class="character['playable_class'].name.toLowerCase().replaceAll(' ', '-')">{{character.name}}</span>
                    <span class="pb-0 mb-0 font-italic"> Level {{character.level}}</span>
                  </h4>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="pa-4 justify-end">
          <v-btn class="ml-2"  @click="isOpenNewCharacters = false" :disabled="currentUser.data.isGeneric">
            {{$t('user_characters.no_link')}}
          </v-btn>
          <v-btn class="ml-2" color="primary" @click="linkNewCharacters" :disabled="currentUser.data.isGeneric">
            {{$t('user_characters.link_to_account')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import ClassAvatar from "@/components/ClassAvatar.vue";
import CharacterService from "@/services/character.service";
import UserService from "@/services/user.service";
import MessageService from "@/services/message.service";

export default {
  components: {ClassAvatar},
  data() {
    return {
      panels: [0],
      characters: {},
      newCharacters: {},
      isOpenNewCharacters: false,
      redirectUrl: process.env.VUE_APP_REDIRECT
    }
  },
  mounted() {
    this.listCharactersForUser();

    if(this.$route.params.authCallback){
      this.tab = 1;
      UserService.updateUserCharacters(this.currentUser.data.id, this.$route.params.code, this.redirectUrl).then(data => {
        if(data !== null){
          this.currentUser.data.battleTag = data.battleTag;

          if(data.linkedCharacters.length > 0){
            this.characters = Object.assign({}, ...data.linkedCharacters.map((x) => ({[x.realm]: data.linkedCharacters.filter(d => d.realm === x.realm)})));
          }

          if(data.newCharacters.length > 0){
            this.newCharacters = Object.assign({}, ...data.newCharacters.map((x) => ({
              [x.realm.name]: data.newCharacters.filter(d => d.realm.name === x.realm.name).map(t => ({
                ...t,
                selected: false
              }))
            })));
            this.isOpenNewCharacters = true;
          } else {
            this.newCharacters = {};
          }
        } else {
          MessageService.error("BNet Sync failed. Please try again later.");
        }
      });
    }
  },
  methods: {
    listCharactersForUser(){
      CharacterService.getCharacterListForUser(this.currentUser.data.id).then(data => {
        this.characters = Object.assign({}, ...data.map((x) => ({[x.realm]: data.filter(d => d.realm === x.realm)})));
      });
    },
    linkNewCharacters(){
      let characters = Object.values(this.newCharacters).flat();
      let charactersToLink = characters.filter(x => x.selected);
      CharacterService.linkCharactersToUser(this.currentUser.data.id, charactersToLink).then(data => {
        data.forEach(char => {
          if(!this.characters[char.realm]){
            this.characters[char.realm] = [];
          }

          this.characters[char.realm].push(char);
        });
        this.isOpenNewCharacters = false;
        this.listCharactersForUser();
      });
    },
    authorize(){
      let params = [
        `region=eu`,
        `response_type=code`,
        `client_id=${this.$store.getters["data/battleNetClientId"]}`,
        `redirect_uri=${this.redirectUrl}`,
        "scope=wow.profile",
        "state=ZdPAuth"
      ]
      window.location.href = `https://oauth.battle.net/oauth/authorize?${params.join("&")}`;
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    hasOriginalId() {
      return this.currentUser && !!this.currentUser.originalId;
    },
  }
}
</script>

<style scoped lang="scss">
.v-list--two-line .v-list-item, .v-list-item--two-line {
  min-height: 4px;
}
</style>