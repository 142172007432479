<template>
  <v-container>
    <div v-if="currentUser">
      <h2 class="mb-4 ml-2">{{ $t('register.user_msg') }}</h2>
    </div>
    <div v-if="!currentUser">
      <v-layout row wrap>
        <v-flex xs12 class="text-center" mt-5>
          <h1>{{ $t('register.title') }}</h1>
        </v-flex>
        <v-flex xs12 sm6 offset-sm3 mt-3>
          <form v-on:submit.prevent="handleRegister()">
            <v-layout column>
              <v-flex>
                <v-text-field v-model="name" :error-messages="nameErrors" :label="$t('register.name.title')" required @input="$v.name.$touch()"
                              @blur="$v.name.$touch()" autocomplete="username"></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field v-model="email" :error-messages="emailErrors" :label="$t('register.mail.title')" required @input="$v.email.$touch()"
                              @blur="$v.email.$touch()" autocomplete="email"></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field v-model="password" :error-messages="passwordErrors" :label="$t('register.password.title')" required @input="$v.password.$touch()"
                              @blur="$v.password.$touch()" autocomplete="new-password" :type="showPassword ? 'text' : 'password'"
                              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showPassword = !showPassword"></v-text-field>
              </v-flex>
              <v-flex class="text-center" mt-5>
                <v-btn color="primary" type="submit">{{ $t('general.actions.submit') }}</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, email, minLength} from "vuelidate/lib/validators";

export default {
  name: "Register",
  mixins: [validationMixin],
  validations: {
    name: {required},
    email: {required, email},
    password: {required, minLength: minLength(6)},
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    showPassword: false
  }),
  mounted() {
    let appConfig = this.$store.getters["data/appConfig"];
    let enableRegistration = appConfig.find(x => x.key === "ENABLE_REGISTRATION");
    if (enableRegistration?.value !== "true") {
      this.$router.push('/home');
    }
  },
  methods: {
    handleRegister() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("auth/register", {
          username: this.$v.name.$model,
          email: this.$v.email.$model,
          password: this.$v.password.$model
        }).then(() => {
          this.message = "";
        }, (error) => {
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        });
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t('register.name.required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push(this.$t('register.mail.required'))
      !this.$v.email.email && errors.push(this.$t('register.mail.invalid'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('register.password.required'))
      !this.$v.password.minLength && errors.push(this.$t('register.password.min_length'))
      return errors
    }
  },
}
</script>

<style scoped>

</style>
