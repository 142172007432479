<template>
  <div>
    <v-row class="mx-2 py-1 align-center">
      <v-col>
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('general.misc.search')"
                      clearable hide-details></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete :label="$t('general.armorClass.title')" v-model="armorClass" :items="armorClasses" return-object
                    item-value="value" item-text="text" hide-details></v-autocomplete>
      </v-col>
      <v-col v-if="useWishlists && wishlistsLoaded">
        <v-autocomplete :label="$t('general.misc.encounter')" v-model="encounter" :items="encounters" return-object
                    item-text="name" hide-details></v-autocomplete>
      </v-col>
      <v-col v-if="useWishlists && wishlistsLoaded">
        <v-autocomplete :label="$t('general.misc.difficulty')" v-model="difficulty" :items="difficulties" hide-details return-object
                    @change="changeDifficulty"></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="mx-2 my-1 py-1 align-center">
      <v-checkbox v-model="showDetails" :label="$t('loot.show_details')" class="ml-3 mr-2"></v-checkbox>
      <v-checkbox v-model="lootMasterMode" :label="$t('loot.lootmaster_mode')" v-if="manageLoot"></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn @click="expandAll" class="mr-2">
        <v-icon>mdi-expand-all</v-icon>
        {{ $t('general.actions.expand') }}
      </v-btn>
      <v-btn @click="collapseAll" class="mr-2">
        <v-icon>mdi-collapse-all</v-icon>
        {{ $t('general.actions.collapse') }}
      </v-btn>
      <div class="mr-2" v-if="useWishlists">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" @click="loadWishlists(true)" :disabled="wishlistsLoading" v-bind="attrs" v-on="on">
              <v-icon class="mr-1">mdi-gift</v-icon>
              {{ $t('loot.wishlist.title') }}
            </v-btn>
          </template>
          {{ $t('loot.wishlist.load_wishlist') }}. {{ $t('char_detail.last_update') }}: {{ wishlistSync }}
        </v-tooltip>
        <v-progress-circular :size="25" color="primary" indeterminate v-if="wishlistsLoading"
                             class="ml-2 mt-1"></v-progress-circular>
      </div>
    </v-row>
    <v-card class="secondary lighten-1 pa-0 mb-2" v-if="lootMasterMode">
      <v-card-subtitle>{{ $t('loot.lootmaster_mode') }}</v-card-subtitle>
      <v-card-text>
        <v-row class="align-center">
          <v-autocomplete :items="classList" v-model="temporaryLootEntry.class" :label=" $t('general.misc.class')" return-object clearable
                          :filter="Utilities().searchNormalizedClass" class="ml-3 mr-2" style="max-width: 400px;" >
            <template v-slot:selection="{ item }">
              <p class="font-weight-medium mb-0">
                <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
                <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
              </p>
            </template>
            <template v-slot:item="{ item }">
              <p class="font-weight-medium mb-0">
                <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
                <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
              </p>
            </template>
          </v-autocomplete>
          <v-text-field :label=" $t('loot.temporary_character')" class="mr-2" v-model="temporaryLootEntry.name" clearable
                        style="max-width: 400px;"></v-text-field>
          <v-btn text class="ml-2" @click="addTemporaryLootEntry"
                 :disabled="temporaryLootEntry.name === '' || temporaryLootEntry.name === null || temporaryLootEntry.class === null">
            <v-icon class="mr-1">mdi-plus-circle</v-icon>
            {{ $t('general.actions.add') }}
          </v-btn>
          <v-btn text color="primary" @click="restoreLootMasterData">
            <v-icon class="mr-1">mdi-refresh</v-icon>
            {{ $t('general.actions.restore_data') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="pa-2 mr-1 justify-end">
      <p v-if="lootMasterMode"><b>{{ $t("loot.max_raid_count") }}: {{ maxRaidCount + 1 }}</b></p>
      <p v-else><b>{{ $t("loot.max_raid_count") }}: {{ maxRaidCount }}</b></p>
    </v-row>
    <v-data-table :headers="showHeaders" :items="filterLootEntries" item-key="id" :custom-filter="Utilities().searchNormalized"
                  :search="table.search" :loading="isLoading"
                  show-expand :expanded.sync="table.expanded"
                  :page.sync="table.page" :items-per-page="table.itemsPerPage" hide-default-footer
                  @page-count="table.pageCount = $event">
      <template v-slot:[`header.${head.value}`]=" { }" v-for="head in showHeaders">
        <v-tooltip top v-if="head.tooltip" v-bind:key="head.value">
          <template v-slot:activator="{ on, attrs }">
            {{ head.text }}
            <v-icon medium class="ml-2 mb-1" v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ head.tooltip }}</span>
        </v-tooltip>
        <span v-else v-bind:key="head.value">{{ head.text }}</span>
      </template>
      <template v-slot:item.class="{ item }">
        <class-avatar v-bind:type="'class'" v-bind:value="item.class.toLowerCase()" v-bind:size="30"/>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.class.toLowerCase().replaceAll(' ', '-')"><b>{{ item.name }}</b></span>
        <v-icon v-if="item.isTemporary" @click="removeTemporaryLootEntry(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.attendancePercent="{ item }">
        {{ item.attendancePercent }} %
      </template>
      <template v-slot:item.attendancePercentEdit="{ item }">
        {{ item.attendancePercentEdit }} %
      </template>
      <template v-slot:item.itemsCountEdit="{ item }">
        <v-icon @click="item.itemsCountEdit--;calculateNewResult(item)"
                :disabled="item.itemsCountEdit === item.itemsCount" class="mr-2">mdi-minus-circle
        </v-icon>
        {{ item.itemsCountEdit }}
        <v-icon @click="item.itemsCountEdit++;calculateNewResult(item)" class="ml-2">mdi-plus-circle</v-icon>
      </template>
      <template v-slot:item.result="{ item }">
        <b class="primary--text">{{ parseFloat(item.result) }}</b>
      </template>
      <template v-slot:item.resultEdit="{ item }">
        <b class="primary--text">{{ parseFloat(item.resultEdit) }}</b>
      </template>
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <td v-if="useWishlists || item.subEntries.length > 1" class="text-start">
          <v-btn icon
                 @click="expand(!isExpanded)"
                 class="v-data-table__expand-icon"
                 :class="{'v-data-table__expand-icon--active' : isExpanded}">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="table.headers.length" class="pa-3">
          <loot-detail-view :loot-entry="item" :encounter="encounter" :loot-master-mode="lootMasterMode"
                            :difficulty="difficulty" :instance-list="currentInstanceList"
                            :use-wishlist="useWishlists && wishlistsLoaded" :wishlists="wishlists"
                            :current-instance-data="currentInstanceData" :roster="roster" :show-behaviour-logs="roster === 'fire'">
          </loot-detail-view>

        </td>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-if="lootEntries.length > 0" v-model="table.page" :length="table.pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import ExternalApiService from "@/services/wow_audit.service";
import DataService from "@/services/data.service";
import MessageService from "@/services/message.service";
import ClassAvatar from "@/components/ClassAvatar";
import CharacterService from "@/services/character.service";
import LootDetailView from "@/components/LootDetailView.vue";
import Utilities from "@/util/Utilities";

export default {
  name: "LootTable",
  props: ["roster", "useWishlists", "useExtraItems"],
  components: {LootDetailView, ClassAvatar},
  data() {
    return {
      isLoading: true,
      lootStringVersion: 1,
      lootMasterDataKey: `loot_master_data_${this.roster}`,
      lootMasterTemporaryDataKey: `loot_master_temporary_data_${this.roster}`,
      showDetails: false,
      wishlistSync: "-",
      wishlists: [],
      wishlistsLoaded: false,
      wishlistsLoading: false,
      lootEntries: [],
      difficulties: [],
      difficulty: null,
      armorClass: {value: "all", text: this.$t('general.armorClass.all')},
      armorClasses: [
        {value: "all", text: this.$t('general.armorClass.all')},
        {value: "cloth", text: this.$t('general.armorClass.cloth')},
        {value: "leather", text: this.$t('general.armorClass.leather')},
        {value: "mail", text: this.$t('general.armorClass.mail')},
        {value: "plate", text: this.$t('general.armorClass.plate')}
      ],
      currentInstanceData: null,
      currentInstanceList: [],
      encounters: [{
        name: "All",
        instance: "all",
      }, {
        name: "Zone",
        instance: "all",
      }],
      encounter: {
        name: "All",
        instance: "all"
      },
      lootMasterMode: false,
      maxRaidCount: 0,
      temporaryLootEntries: [],
      classList: this.$store.getters["data/classInfo"].list.sort(),
      classMap: this.$store.getters["data/classInfo"].map,
      temporaryLootEntry: {
        name: "",
        class: "Death Knight"
      },
      savedData: [],
      table: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 40,
        expanded: [],
        search: '',
        headers: [
          {
            text: '', value: "searchKeys", align: " d-none", simple: true,
          },
          {
            text: '', value: "class", width: "20px", simple: true,
            filter: value => this.filterOnArmoryClass(value)
          },
          {text: this.$t('general.misc.name'), value: "name", simple: true},
          {text: this.$t('loot.table.state'), value: "state", simple: true},
          {text: this.$t('general.misc.default_role'), value: "defaultRole", align: " d-none", simple: true},
          {
            text: this.$t('loot.table.attendance'),
            value: "attendanceCount",
            lootMasterMode: false,
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.attendance'),
            value: "attendanceCountEdit",
            lootMasterMode: true,
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.attendance_percent'),
            value: "attendancePercent",
            lootMasterMode: false,
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.attendance_percent'),
            value: "attendancePercentEdit",
            lootMasterMode: true,
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.items'),
            value: "itemsCount",
            lootMasterMode: false,
            tooltip: this.$t('loot.table.items_tooltip'),
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.items'),
            value: "itemsCountEdit",
            lootMasterMode: true,
            tooltip: this.$t('loot.table.items_tooltip'),
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.items_calculated'),
            value: "itemsCalculated",
            lootMasterMode: false,
            tooltip: this.$t('loot.table.items_calculated_tooltip'),
            align: 'center',
            simple: false
          },
          {
            text: this.$t('loot.table.items_calculated'),
            value: "itemsCalculatedEdit",
            lootMasterMode: true,
            tooltip: this.$t('loot.table.items_calculated_tooltip'),
            align: 'center',
            simple: false
          },
          {
            text: this.$t('loot.table.extra'),
            value: "extraItemsCount",
            lootMasterMode: false,
            tooltip: this.$t('loot.table.extra_tooltip'),
            align: this.useExtraItems ? 'center' : " d-none",
            simple: true
          },
          {
            text: this.$t('loot.table.extra'),
            value: "extraItemsCountEdit",
            lootMasterMode: true,
            tooltip: this.$t('loot.table.extra_tooltip'),
            align: this.useExtraItems ? 'center' : " d-none",
            simple: true
          },
          {
            text: this.$t('loot.table.attendance_relative'),
            value: "attendanceRelative",
            lootMasterMode: false,
            tooltip: this.$t('loot.table.attendance_relative_tooltip'),
            align: 'center',
            simple: false
          },
          {
            text: this.$t('loot.table.attendance_relative'),
            value: "attendanceRelativeEdit",
            lootMasterMode: true,
            tooltip: this.$t('loot.table.attendance_relative_tooltip'),
            align: 'center',
            simple: false
          },
          {
            text: this.$t('loot.table.result'),
            value: "result",
            lootMasterMode: false,
            tooltip: this.$t('loot.table.result_tooltip'),
            align: 'center',
            simple: true
          },
          {
            text: this.$t('loot.table.result'),
            value: "resultEdit",
            lootMasterMode: true,
            tooltip: this.$t('loot.table.result_tooltip'),
            align: 'center',
            simple: true
          },
          {text: '', value: 'data-table-expand', simple: true}
        ],
      }
    }
  },
  mounted() {
    this.loadLootEntryData();

    let appConfig = this.$store.getters["data/appConfig"];
    let currentInstances = appConfig.find(x => x.key === "CURRENT_INSTANCES");
    if (currentInstances) {
      this.currentInstanceData = {};
      this.currentInstanceList = currentInstances.value.split(";");
      this.currentInstanceList.forEach(instance => {
        DataService.getInstance(instance).then(data => {
          this.currentInstanceData[data.name] = data;
          this.encounters.push({
            customId: this.encounters.length,
            header: instance
          });

          this.encounters = this.encounters.concat(data.encounters.filter(x => x.name !== "Zone").map(x => ({
            customId: this.encounters.length,
            name: x.name,
            instance: data.name
          })));

          this.encounters.push({
            customId: this.encounters.length,
            divider: true
          });
        });
      });
    } else {
      MessageService.error("Current instances could not be loaded properly");
    }

    let difficulties = appConfig.find(x => x.key === "DIFFICULTIES");
    let currentDifficulty = appConfig.find(x => x.key === "CURRENT_DIFFICULTY");
    if (difficulties && currentDifficulty) {
      this.difficulties = difficulties.value.split(";");
      this.difficulty = currentDifficulty.value;
    }


    this.$root.$on(`raidUpdate_${this.roster}`, () => {
      this.loadLootEntryData();
    });

    this.loadWishlists(false);
  },
  beforeDestroy() {
    this.$root.$off(`raidUpdate_${this.roster}`);
  },
  methods: {
    Utilities() {
      return Utilities
    },
    expandAll() {
      this.table.expanded = this.lootEntries;
    },
    collapseAll() {
      this.table.expanded = [];
    },
    filterOnArmoryClass(value) {
      let armoryMap = {
        cloth: ["Mage", "Priest", "Warlock"],
        leather: ["Demon Hunter", "Druid", "Monk", "Rogue"],
        mail: ["Evoker", "Hunter", "Shaman"],
        plate: ["Death Knight", "Paladin", "Warrior"],
      };

      if (this.armorClass.value === "all") {
        return true;
      } else {
        return armoryMap[this.armorClass.value].includes(value);
      }
    },
    loadWishlists(force) {
      this.wishlistsLoading = true;
      ExternalApiService.getWowAuditWishlists("fire", force).then(wishlists => {
        this.wishlistSync = wishlists["syncDate"];
        this.wishlists = wishlists.data;
        this.wishlistsLoading = false;
        this.wishlistsLoaded = true;
      });
    },
    changeDifficulty() {
      if (this.difficulty === null) {
        this.difficulty = this.difficulties[0];
      }
    },
    loadLootEntryData() {
      this.isLoading = true;
      let data = localStorage.getItem(this.lootMasterDataKey);

      let lootMasterData;
      if (data) {

        lootMasterData = JSON.parse(data);

        let today = new Date(Date.now());
        let yesterday = new Date(today.getDate() -1);

        let versionValid = lootMasterData.version && lootMasterData.version === this.lootStringVersion;
        let syncDateValid = new Date(lootMasterData.syncDate) >= yesterday;
        if(!versionValid || !syncDateValid){
          this.restoreLootMasterData();
        }
      }

      CharacterService.getListForLoot(this.roster).then(result => {
        if (result) {
          this.maxRaidCount = result["raidCount"];
          this.lootEntries = result["lootEntries"].map(lootEntry => {
            let mappedLootEntry = {
              ...lootEntry,
              searchKeys: lootEntry.subEntries.map(x => x.name),
              isTemporary: false
            };

            if (lootMasterData && lootMasterData[lootEntry.id]) {
              mappedLootEntry.attendanceCountEdit = lootMasterData[lootEntry.id].attendanceCountEdit;
              mappedLootEntry.attendanceRelativeEdit = lootMasterData[lootEntry.id].attendanceRelativeEdit;
              mappedLootEntry.attendancePercentEdit = lootMasterData[lootEntry.id].attendancePercentEdit;
              mappedLootEntry.itemsCountEdit = lootMasterData[lootEntry.id].itemsCountEdit;
              mappedLootEntry.extraItemsCountEdit = lootMasterData[lootEntry.id].extraItemsCount;
              mappedLootEntry.itemsCalculatedEdit = lootMasterData[lootEntry.id].itemsCalculatedEdit;
              mappedLootEntry.resultEdit = lootMasterData[lootEntry.id].resultEdit;
            } else {
              this.calculateLootMasterValues(mappedLootEntry);
            }

            return mappedLootEntry;
          });

          let data2 = localStorage.getItem(this.lootMasterTemporaryDataKey);
          if (data2) {
            this.temporaryLootEntries = JSON.parse(data2);
            this.temporaryLootEntries.forEach(tc => {
              this.lootEntries.push(tc);
            });
          }
        }

        this.isLoading = false;
      });
    },
    calculateLootMasterValues(lootEntry) {
      let attendanceCount = lootEntry.attendanceCount + 1;
      let attendanceRelative = attendanceCount / (lootEntry.totalRaidCount + 1);

      lootEntry.attendanceCountEdit = attendanceCount;
      lootEntry.attendanceRelativeEdit = Number(attendanceRelative.toFixed(2));
      lootEntry.attendancePercentEdit = Number((attendanceRelative * 100).toFixed(0));
      lootEntry.itemsCountEdit = lootEntry.itemsCount;
      lootEntry.extraItemsCountEdit = lootEntry.extraItemsCount;
      lootEntry.itemsCalculatedEdit = attendanceCount - lootEntry.itemsCount;
      lootEntry.resultEdit = Number(((attendanceCount - lootEntry.itemsCount) + attendanceRelative + lootEntry.extraItemsCount).toFixed(2));
    },
    calculateNewResult(item) {
      item.itemsCalculated = item.attendanceCountEdit - item.itemsCountEdit;
      item.resultEdit = Number(((item.attendanceCountEdit - item.itemsCountEdit) + item.attendanceRelativeEdit + item.extraItemsCountEdit).toFixed(2));

      let data = Object.fromEntries(this.lootEntries.map(x => [x.id, {
        attendanceCountEdit: x.attendanceCountEdit,
        attendancePercentEdit: x.attendancePercentEdit,
        attendanceRelativeEdit: x.attendanceRelativeEdit,
        itemsCountEdit: x.itemsCountEdit,
        extraItemsCountEdit: x.extraItemsCountEdit,
        resultEdit: x.resultEdit,
        itemsCalculatedEdit: x.itemsCalculatedEdit
      }]));

      localStorage.setItem(this.lootMasterDataKey, JSON.stringify(data));
    },
    restoreLootMasterData() {
      this.lootEntries.forEach(lootEntry => {
        this.calculateLootMasterValues(lootEntry);
      });

      let data = {
        syncDate: new Date(Date.now()),
        version: this.lootStringVersion,
        data: Object.fromEntries(this.lootEntries.map(x => [x.id, {
          attendanceCountEdit: x.attendanceCountEdit,
          attendancePercentEdit: x.attendancePercentEdit,
          attendanceRelativeEdit: x.attendanceRelativeEdit,
          itemsCountEdit: x.itemsCountEdit,
          extraItemsCountEdit: x.extraItemsCountEdit,
          resultEdit: x.resultEdit,
          itemsCalculatedEdit: x.itemsCalculatedEdit
        }]))
      };

      localStorage.setItem(this.lootMasterDataKey, JSON.stringify(data));
      localStorage.setItem(this.lootMasterTemporaryDataKey, JSON.stringify([]));

      this.temporaryLootEntries = [];
      let tempChars = this.lootEntries.filter(x => x.isTemporary);
      tempChars.forEach(c => {
        let index = this.lootEntries.indexOf(c);
        if (index !== -1) {
          this.lootEntries.splice(index, 1);
        }
      })
    },
    addTemporaryLootEntry() {
      if (this.temporaryLootEntry.name && this.temporaryLootEntry.name.length > 0 && this.temporaryLootEntry.class && this.temporaryLootEntry.class.length > 0) {
        if (this.classList.includes(this.temporaryLootEntry.class)) {
          if (!this.temporaryLootEntries.find(x => x.name === this.temporaryLootEntry.name)) {
            let lootEntry = {
              id: `temporary_${this.temporaryLootEntry.name}`,
              isTemporary: true,
              class: this.temporaryLootEntry.class,
              name: this.temporaryLootEntry.name,
              rosterInformation: {
                [this.roster]: `temporary_${this.temporaryLootEntry.name}`
              },
              state: 'Temporary',
              totalRaidCount: this.maxRaidCount,
              attendanceCount: 0,
              itemsCount: 0,
              extraItemsCount: 0,
              subEntries: []
            };
            this.calculateLootMasterValues(lootEntry);

            this.wishlists[lootEntry.id] = [];
            this.lootEntries.push(lootEntry);
            this.temporaryLootEntries.push(lootEntry);
            this.temporaryLootEntry.name = "";
            this.temporaryLootEntry.class = "";

            localStorage.setItem(this.lootMasterTemporaryDataKey, JSON.stringify(this.temporaryLootEntries));
          } else {
            MessageService.warn("Temporary character with this name already exists.");
          }
        } else {
          MessageService.warn("Please select a valid class.");
        }
      } else {
        MessageService.warn("Please specify an character to add temporary. Both fields must be filled.");
      }
    },
    removeTemporaryLootEntry(item) {
      let id = item.id;

      let indexChars = this.lootEntries.indexOf(item);
      if (indexChars !== -1) {
        this.lootEntries.splice(indexChars, 1);
      }

      let indexTempChars = this.temporaryLootEntries.indexOf(item);
      if (indexTempChars !== -1) {
        this.temporaryLootEntries.splice(indexTempChars, 1);
      }

      delete this.wishlists[id];
      localStorage.setItem(this.lootMasterTemporaryDataKey, JSON.stringify(this.temporaryLootEntries));
    }
  },
  computed: {
    manageLoot() {
      return this.$store.state.auth.user.rights.includes("EDIT_LOOT_DATA");
    },
    showHeaders() {
      let headers;

      if (this.showDetails) {
        headers = this.table.headers;
      } else {
        headers = this.table.headers.filter(x => x.simple);
      }

      if (this.lootMasterMode) {
        return headers.filter(x => x.lootMasterMode === undefined || x.lootMasterMode === true);
      } else {
        return headers.filter(x => x.lootMasterMode === undefined || x.lootMasterMode === false);
      }
    },
    filterLootEntries() {
      let lootEntries = [];
      if (!this.lootMasterMode) {
        lootEntries = this.lootEntries.filter(x => x.isTemporary === false);
      } else {
        lootEntries = this.lootEntries;
      }

      return lootEntries;
    }
  },
  watch: {
    '$i18n.locale': function () {
      let currentVal = this.armorClass.value;
      this.armorClass = {value: currentVal, text: this.$t(`general.armorClass.${currentVal}`)}
      this.armorClasses = [
        {value: "all", text: this.$t('general.armorClass.all')},
        {value: "cloth", text: this.$t('general.armorClass.cloth')},
        {value: "leather", text: this.$t('general.armorClass.leather')},
        {value: "mail", text: this.$t('general.armorClass.mail')},
        {value: "plate", text: this.$t('general.armorClass.plate')}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.upgrade.huge {
  color: orangered;
}

.upgrade.big {
  color: orange;
}

.upgrade.small {
  color: greenyellow;
}

.upgrade.tiny {
  color: grey;
}
</style>