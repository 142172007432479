export default class SimulationTrinkets {
    CHROMATIC_ESSENCE = {
        ID: 203729,
        KEY: "dragonflight.ominous_chromatic_essence_dragonflight",
        VALUE: {
            ID: 402221,
            MEDIA: "inv_bijou_silver",
            LANGUAGE: "chromatic_essence_obsidian",
            VALUE: "obsidian"
        },
        DEFAULT: {
            ID: 402221,
            MEDIA: "inv_bijou_silver",
            LANGUAGE: "chromatic_essence_obsidian",
            VALUE: "obsidian"
        },
        VALUES: [{
            ID: 402221,
            MEDIA: "inv_bijou_silver",
            LANGUAGE: "chromatic_essence_obsidian",
            VALUE: "obsidian"
        },
        {
            ID: 401521,
            MEDIA: "inv_bijou_green",
            LANGUAGE: "chromatic_essence_emerald",
            VALUE: "emerald"
        },
        {
            ID: 401518,
            MEDIA: "inv_bijou_orange",
            LANGUAGE: "chromatic_essence_bronze",
            VALUE: "bronze"
        },
        {
            ID: 401519,
            MEDIA: "inv_bijou_blue",
            LANGUAGE: "chromatic_essence_azure",
            VALUE: "azure"
        },
        {
            ID: 401516,
            MEDIA: "inv_bijou_red",
            LANGUAGE: "chromatic_essence_ruby",
            VALUE: "ruby"
        }],
        MEDIA: "inv_misc_orb_blue",
        ALLY_BUFFS_KEY: "dragonflight.ominous_chromatic_essence_allies",
        USE_ALLY_BUFFS: false,
        ALLY_BUFFS: {
            EMPTY_VALUE: "",
            FILLED_VALUE: "obsidian/emerald/bronze/azure/ruby",
            DEFAULT: false
        }
    };

    constructor() {}

    toJson() {
        // return {
        //     chromaticEssence: {
        //         key: this.CHROMATIC_ESSENCE.KEY,
        //         value: this.CHROMATIC_ESSENCE.VALUE.VALUE,
        //     },
        //     chromaticEssenceAllyBuffs: {
        //         key: this.CHROMATIC_ESSENCE.ALLY_BUFFS_KEY,
        //         value: this.CHROMATIC_ESSENCE.USE_ALLY_BUFFS ? this.CHROMATIC_ESSENCE.ALLY_BUFFS.FILLED_VALUE : this.CHROMATIC_ESSENCE.ALLY_BUFFS.EMPTY_VALUE,
        //         use: this.CHROMATIC_ESSENCE.USE_ALLY_BUFFS
        //     }
        // };
        return {};
    }

    load(jsonObj) {
        // if (jsonObj) {
        //     if(jsonObj.chromaticEssence !== undefined){
        //         let found = Object.values(this.CHROMATIC_ESSENCE.VALUES).find(x => x.VALUE === jsonObj.chromaticEssence.value);
        //         if(found){
        //             this.CHROMATIC_ESSENCE.VALUE = found;
        //         } else {
        //             this.CHROMATIC_ESSENCE.VALUE = this.CHROMATIC_ESSENCE.DEFAULT;
        //         }
        //     } else {
        //         this.CHROMATIC_ESSENCE.VALUE = this.CHROMATIC_ESSENCE.DEFAULT;
        //     }
        //
        //     if(jsonObj.chromaticEssenceAllyBuffs !== undefined){
        //         this.CHROMATIC_ESSENCE.USE_ALLY_BUFFS = jsonObj.chromaticEssenceAllyBuffs.use;
        //     } else {
        //         this.CHROMATIC_ESSENCE.USE_ALLY_BUFFS = this.CHROMATIC_ESSENCE.ALLY_BUFFS.DEFAULT;
        //     }
        // } else {
        //     this.CHROMATIC_ESSENCE.VALUE = this.CHROMATIC_ESSENCE.DEFAULT;
        //     this.CHROMATIC_ESSENCE.USE_ALLY_BUFFS = this.CHROMATIC_ESSENCE.ALLY_BUFFS.DEFAULT;
        // }
    }
}