export default class SimulationBuffs {
    BLOOD_LUST = {
        ID: 2825,
        KEY: "bloodlust",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "spell_nature_bloodlust"
    };
    BATTLE_SHOUT = {
        ID: 6673,
        KEY: "battle_shout",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "ability_warrior_battleshout"
    };
    ARCANE_INTELLECT = {
        ID: 1459,
        KEY: "arcane_intellect",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "spell_holy_magicalsentry"
    };
    MARK_OF_THE_WILD = {
        ID: 1126,
        KEY: "mark_of_the_wild",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "spell_nature_regeneration"
    };
    MYSTIC_TOUCH = {
        ID: 113746,
        KEY: "mystic_touch",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "ability_monk_sparring"
    };
    CHAOS_BRAND = {
        ID: 255260,
        KEY: "chaos_brand",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "ability_demonhunter_empowerwards"
    };
    BLEEDING = {
        ID: 1943,
        KEY: "bleeding",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "ability_rogue_rupture"
    };
    FORTITUDE = {
        ID: 21562,
        KEY: "power_word_fortitude",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "spell_holy_wordfortitude"
    };
    SKYFURY = {
        ID: 462854,
        KEY: "skyfury",
        VALUE: true,
        DEFAULT: true,
        MEDIA: "achievement_raidprimalist_windelemental"
    };

    constructor() {}

    toJson() {
        return {
            bloodlust: this.BLOOD_LUST.VALUE,
            battle_shout: this.BATTLE_SHOUT.VALUE,
            arcane_intellect: this.ARCANE_INTELLECT.VALUE,
            mark_of_the_wild: this.MARK_OF_THE_WILD.VALUE,
            mystic_touch: this.MYSTIC_TOUCH.VALUE,
            chaos_brand: this.CHAOS_BRAND.VALUE,
            bleeding: this.BLEEDING.VALUE,
            power_word_fortitude: this.FORTITUDE.VALUE,
            skyfury: this.SKYFURY.VALUE,
        };
    }

    load(jsonObj) {
        if (jsonObj) {
            this.resolveBuffValue(this.BLOOD_LUST, jsonObj.bloodlust);
            this.resolveBuffValue(this.BATTLE_SHOUT, jsonObj.battle_shout);
            this.resolveBuffValue(this.ARCANE_INTELLECT, jsonObj.arcane_intellect);
            this.resolveBuffValue(this.MARK_OF_THE_WILD, jsonObj.mark_of_the_wild);
            this.resolveBuffValue(this.MYSTIC_TOUCH, jsonObj.mystic_touch);
            this.resolveBuffValue(this.CHAOS_BRAND, jsonObj.chaos_brand);
            this.resolveBuffValue(this.BLEEDING, jsonObj.bleeding);
            this.resolveBuffValue(this.FORTITUDE, jsonObj.power_word_fortitude);
            this.resolveBuffValue(this.SKYFURY, jsonObj.skyfury);
        } else {
            this.BLOOD_LUST.VALUE = this.BLOOD_LUST.DEFAULT;
            this.BATTLE_SHOUT.VALUE = this.BATTLE_SHOUT.DEFAULT;
            this.ARCANE_INTELLECT.VALUE = this.ARCANE_INTELLECT.DEFAULT;
            this.MARK_OF_THE_WILD.VALUE = this.MARK_OF_THE_WILD.DEFAULT;
            this.MYSTIC_TOUCH.VALUE = this.MYSTIC_TOUCH.DEFAULT;
            this.CHAOS_BRAND.VALUE = this.CHAOS_BRAND.DEFAULT;
            this.BLEEDING.VALUE = this.BLEEDING.DEFAULT;
            this.FORTITUDE.VALUE = this.FORTITUDE.DEFAULT;
            this.SKYFURY.VALUE = this.SKYFURY.DEFAULT;
        }
    }

    resolveBuffValue(context, value){
        if(value !== undefined){
            context.VALUE = value;
        } else {
            context.VALUE = context.DEFAULT;
        }
    }
}