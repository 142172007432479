<template>
  <div>
    <div v-if="character.detailsLoaded">
      <v-tabs v-model="tab">
        <v-tab value="detail">{{ $t('char_detail.title') }}</v-tab>
        <v-tab value="loot">{{ $t('char_detail.loot.title') }}</v-tab>
        <v-tab value="result" v-if="allowSim">{{ $t('char_detail.results.title') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="pa-5">
            <v-row class="align-center">
              <v-col>
                <specialization-avatar class="mr-3" :size="60" :type="character.class.toLowerCase()"
                                       :value="character.details.spec?.toLowerCase()"></specialization-avatar>
                <h2 class="d-inline" :class="character.class.toLowerCase().replaceAll(' ', '-')">
                  {{ specMap[character.details?.spec][$i18n.locale]}} {{ classMap[character.class][$i18n.locale]}}
                </h2>
              </v-col>
              <v-col class="text-end">
                <v-btn color="primary" @click="refreshFromArmory()" v-if="character.detailsLoaded">
                  <v-icon>mdi-refresh</v-icon>
                  {{ $t('char_detail.armory_refresh') }}
                </v-btn>
                <p class="mt-2 mr-6"><b>{{ $t('char_detail.last_update') }} </b>
                  {{ new Date(character.details.updatedAt).toLocaleDateString() }}
                  {{ new Date(character.details.updatedAt).toLocaleTimeString() }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-arrow-projectile-multiple</v-icon>
                  <span class="ml-1"><b>{{
                      $t('general.misc.ilvl')
                    }} - </b> {{ character.details.itemLevel }}</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-heart</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.health')
                    }} - </b> {{ character.details.stats.health }}</span>
                </v-row>
                <v-row v-if="character.details.stats.main === 'str'" class="pa-3 align-center">
                  <v-icon color="primary">mdi-arm-flex</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.strength')
                    }} - </b> {{ character.details.stats.strength }}</span>
                </v-row>
                <v-row v-if="character.details.stats.main === 'agi'" class="pa-3 align-center">
                  <v-icon color="primary">mdi-run-fast</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.agility')
                    }} - </b> {{ character.details.stats.agility }}</span>
                </v-row>
                <v-row v-if="character.details.stats.main === 'int'" class="pa-3 align-center">
                  <v-icon color="primary">mdi-lightbulb</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.intellect')
                    }} - </b> {{ character.details.stats.intellect }}</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-flash</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.stamina')
                    }} - </b> {{ character.details.stats.stamina }}</span>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-water</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.lifesteal')
                    }} - </b> {{
                      character.details.stats.lifesteal.rating
                    }}  ({{ Math.round(character.details.stats.lifesteal.value) }} %)</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-shield</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.avoidance')
                    }} - </b> {{ character.details.stats.avoidance.rating }}</span>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-clock-outline</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.haste')
                    }} - </b> {{
                      character.details.stats.haste.rating
                    }} ({{ Math.round(character.details.stats.haste.value) }} %)</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-arrow-collapse-all</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.crit')
                    }} - </b> {{
                      character.details.stats.crit.rating
                    }}  ({{ Math.round(character.details.stats.crit.value) }} %)</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-crown</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.mastery')
                    }} - </b> {{
                      character.details.stats.mastery?.rating
                    }}  ({{ Math.round(character.details.stats.mastery?.value) }} %)</span>
                </v-row>
                <v-row class="pa-3 align-center">
                  <v-icon color="primary">mdi-tools</v-icon>
                  <span class="ml-1"><b>{{
                      $t('char_detail.stats.versatility')
                    }} - </b> {{
                      character.details.stats.versatility.rating
                    }} ({{ Math.round(character.details.stats.versatility.value) }} %)</span>
                </v-row>
              </v-col>
              <v-col cols="6" class="align-center justify-end mt-2">
                <v-row class="justify-center">
                  <v-radio-group v-model="statSuggestion" row @change="updateChartData">
                    <v-radio v-for="(suggestion, index) in statSuggestions" :key="index" :label="suggestion.label"
                             :value="index"></v-radio>
                  </v-radio-group>
                </v-row>
                <chart-wrapper :id="'radar-chart-' + character.id" :type="'radar'" :data="character.details.chart"
                               :options="chartOptions"/>
              </v-col>
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row class="pa-2 align-center">
              <span class="mt-3"><b>{{ $t('general.misc.equipment') }}:</b></span>
            </v-row>
            <v-row class="pa-2 align-center">
              <div class="ml-1 mr-1" style="width: 58px; display: inline-block; text-align: center"
                   v-for="equippedItem of EquipmentHelper().filterEquippedItems(character.details.equipment)"
                   v-bind:key="equippedItem.type + '_' + equippedItem.id">
                <a v-bind:href="equippedItem.href_wowhead" v-bind:data-wowhead="equippedItem.data_wowhead"
                   target="_blank">
                  <img class="equip-image" v-bind:src="equippedItem.media" :style="EquipmentHelper().gearIconStyle(equippedItem)" alt="">
                </a>
                <br>
                <b>{{ equippedItem.level }}</b>
              </div>
            </v-row>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="pa-3">
            <div v-if="character.details.loot.length > 0">
              <v-row class="ma-2">
                <v-text-field v-model="loot.table.search" append-icon="mdi-magnify" :label="$t('general.misc.search')"
                              clearable hide-details></v-text-field>
                <v-autocomplete class="ml-2" :label="$t('char_detail.loot.table.episode')" :items="loot.episodes"
                            v-model="loot.episode" hide-details return-object></v-autocomplete>
                <v-checkbox :label="$t('char_detail.loot.show_copied')" v-model="loot.includeCopiedItems"></v-checkbox>
              </v-row>
              <v-row class="ma-2 mt-2">
                <v-autocomplete :label="$t('char_detail.loot.table.choice')" :items="loot.choices"
                            v-model="loot.choice" clearable multiple small-chips hide-details return-object>
                </v-autocomplete>
                <v-autocomplete class="ml-2" :label="$t('char_detail.loot.table.typ')" :items="loot.types"
                            v-model="loot.type" clearable hide-details return-object></v-autocomplete>

              </v-row>
              <v-row class="ma-2 align-center justify-end">
                <v-btn class="ml-2 primary" @click="resetFilter">
                  <v-icon>mdi-close-circle</v-icon>
                  {{ $t('char_detail.loot.reset_filter') }}
                </v-btn>
              </v-row>
              <v-data-table :headers="filterLootHeader" :items="filterItems" item-key="id"
                            :search="loot.table.search" :sort-by.sync="loot.table.sortBy" :sort-desc="true"
                            :page.sync="loot.table.page" :items-per-page="loot.table.itemsPerPage" hide-default-footer
                            @page-count="loot.table.pageCount = $event"
                            :custom-filter="Utilities().searchNormalized">
                <template v-slot:item.name="{ item }">
                  <div class="ml-1 align-center d-table">
                    <div class="mt-1 mr-3" style="width: 30px; display: inline-block; text-align: center;">
                      <a v-bind:href="'https://www.wowhead.com/item=' + item.itemId"
                         :data-wowhead="EquipmentHelper().getItemContext(item, $i18n.locale)"
                         target="_blank"><img class="raid-item" v-bind:src="item.media" alt="raid-item"></a>
                    </div>
                    <p class="pl-2 font-weight-medium d-table-cell" style="vertical-align: middle">
                      {{ item.name }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.roster="{ item }">
                  <span v-if="item.roster === 'fire'">
                      <v-chip label class="ml-2 noHover" color="primary" outlined small>
                        <v-icon left>mdi-fire</v-icon>
                        Feuer
                      </v-chip>
                    </span>
                  <span v-else>
                    <v-chip label class="ml-2 noHover" color="#298F85" outlined small>
                      <v-icon left>mdi-fire</v-icon>
                      Flamme
                    </v-chip>
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ new Date(item["createdAt"]).toLocaleDateString() }}
                </template>
                <template v-slot:item.visibleItem="{ item }">
                  <v-icon medium :color="item.visibleItem ? 'red' : 'green'">
                    {{ item.visibleItem ? 'mdi-close-circle' : 'mdi-check-circle' }}
                  </v-icon>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <h4 class="ml-5 mt-2">{{ $t("char_detail.loot.total_items") }}: {{loot.itemCount}} | {{ $t("char_detail.loot.filtered_items") }}: {{loot.filteredItemCount}}</h4>
                <v-pagination v-if="(filterItems).length > 0" v-model="loot.table.page"
                              :length="loot.table.pageCount"></v-pagination>
              </div>
            </div>
            <div v-else>
              <v-icon class="mr-2">mdi-close-circle</v-icon>
              {{ $t("char_detail.loot.no_items") }}
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-data-table :headers="results.headers" :items="character.simulation_results" item-key="id"
                        :page.sync="results.page" :items-per-page="results.itemsPerPage" hide-default-footer
                        @page-count="results.pageCount = $event">
            <template v-slot:item.spec="{ item }">
              {{ item.spec.replace(` ${character.class}`, "") }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ new Date(item["createdAt"])?.toLocaleDateString() }}
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-if="character.simulation_results.length > 0" v-model="results.page"
                          :length="results.pageCount"></v-pagination>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-container fluid v-else class="text-center">
    <v-progress-circular :size="50" color="primary" class="pa-4" indeterminate></v-progress-circular>
  </v-container>
  </div>
</template>

<script>
import CharacterService from "@/services/character.service";
import ChartWrapper from "@/components/ChartWrapper";
import SpecializationAvatar from "@/components/SpecializationAvatar";
import ClassUtility from "@/util/ClassUtility";
import EquipmentHelper from "@/util/EquipmentHelper";
import Constants from "@/util/constants";
import Utilities from "@/util/Utilities";

export default {
  name: "CharacterDetailsView",
  props: ['character'],
  components: {SpecializationAvatar, ChartWrapper},
  data() {
    return {
      tab: null,
      statSuggestions: [],
      statSuggestion: 0,
      results: {
        headers: [
          {text: this.$t('general.misc.spec'), value: "spec"},
          {text: "DPS", value: "dps"},
          {text: this.$t('simulation.options.fight_style'), value: "settings.options.settings.fight_style"},
          {text: this.$t('simulation.options.target_count'), value: "settings.options.settings.desired_targets"},
          {text: this.$t('simulation.options.iterations'), value: "settings.options.settings.iterations"},
          {text: this.$t('simulation.options.combat_length'), value: "settings.options.settings.max_time"},
          {text: this.$t('char_detail.results.timestamp'), value: "createdAt"}
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        elements: {
          line: {
            borderWidth: 2
          },
        },
        scales: {
          r: {
            ticks: {
              display: false // hides the labels in the middle (numbers)
            },
            grid: {
              color: ['#434343']
            }
          }
        }
      },
      states: Constants.STATES,
      administration: {
        state: "None",
        defaultRole: "None"
      },
      loot: {
        episodes: ["All"],
        episode: "All",
        currentEpisode: "",
        itemCount: 0,
        filteredItemCount: 0,
        includeCopiedItems: false,
        type: null,
        types: [],
        choice: null,
        choices: [],
        table: {
          search: '',
          page: 1,
          pageCount: 0,
          itemsPerPage: 15,
          sortBy: "createdAt"
        }
      },
      classMap: this.$store.getters["data/classInfo"].map,
      specMap: this.$store.getters["data/specInfo"].map
    }
  },
  mounted() {
    this.character.detailsLoaded = false;

    let appConfig = this.$store.getters["data/appConfig"];
    let lootEpisodes = appConfig.find(x => x.key === "LOOT_EPISODES");
    if (lootEpisodes) {
      let episodeValues = lootEpisodes.value.split(";");
      episodeValues = episodeValues.sort((a, b) => a.localeCompare(b));
      episodeValues.forEach(value => {
        this.loot.episodes.push(value);
      });
    }

    let currentEpisode = appConfig.find(x => x.key === "CURRENT_LOOT_EPISODE");
    if (currentEpisode) {
      this.loot.episode = currentEpisode.value;
      this.loot.currentEpisode = currentEpisode.value;
    }

    if (Object.keys(this.character.details).length === 0) {
      CharacterService.getCharacterDetails(this.character.id).then(data => {
        if(data){
          this.character.details = data;
          this.resolveDetails();
          this.character.detailsLoaded = true;
          this.$forceUpdate();
        }
      });
    } else {
      this.resolveDetails();
      this.character.detailsLoaded = true;
    }
  },
  methods: {
    Utilities() {
      return Utilities
    },
    EquipmentHelper() {
      return EquipmentHelper
    },
    resetFilter(){
      this.loot.table.search = "";
      this.loot.episode = this.loot.currentEpisode;
      this.loot.choice = this.loot.choices;
      this.loot.type = null;
      this.loot.includeCopiedItems = false;
      this.loot.filteredItemCount = this.character.details.loot.length;
    },
    resolveDetails() {
      this.administration.defaultRole = this.character.defaultRole;
      this.administration.state = this.character.state;
      this.character.details.spec = this.character.details.activeSpec.name;
      ClassUtility.resolveStatsForSpecType(this.character);
      this.buildStatDistribution();
      EquipmentHelper.resolveEquipVisualization(this.character.details.equipment, this.$i18n.locale);
      this.character.details.loot = this.character.details.origin.items;
      this.loot.itemCount = this.character.details.loot.length;
      this.loot.itemCount = this.character.details.loot.length;
      this.loot.choices = [...new Set(this.character.details.loot.map(x => x.choice))];
      this.loot.choice = [...new Set(this.character.details.loot.map(x => x.choice))];
      this.loot.types = [...new Set(this.character.details.loot.map(x => x.type))];
    },
    refreshFromArmory() {
      this.character.detailsLoaded = false;
      CharacterService.getCharacterDetails(this.character.id, true).then(data => {
        if(data){
          this.character.details = data;
          this.resolveDetails();
          this.character.detailsLoaded = true;
          this.$forceUpdate();
        }
      });
    },
    getItemLevel() {
      let sum = 0;

      EquipmentHelper.filterEquippedItems(this.character.details.equipment).forEach((item) => {
        sum += ((item.inventory_type === "TWOHWEAPON") ? 2 : 1) * item.level;
      });

      return (sum / 16).toFixed(2);
    },
    buildStatDistribution() {
      this.character.details.distribution = {
        label: "Character",
        data: [
          this.character.details.stats.crit.rating,
          this.character.details.stats.haste.rating,
          this.character.details.stats.mastery.rating,
          this.character.details.stats.versatility.rating,
        ],
        backgroundColor: 'rgba(25,180,162, 0.4)',
        borderColor: 'rgb(25,180,162)'
      };

      this.character.details.chart = {
        labels: ["Crit", "Haste", "Mastery", "Versatility"],
        datasets: [this.character.details.distribution]
      }

      this.character.details.stats.max = this.character.details.stats.crit.rating + this.character.details.stats.haste.rating + this.character.details.stats.mastery.rating + this.character.details.stats.versatility.rating;
      this.statSuggestions = [];

      this.character.details.distributions.forEach(entry => {
        this.statSuggestions.push({
          label: `Optimal - ${entry.title}`,
          data: [
            this.character.details.stats.max * parseInt(entry.distribution[0]) / 100,
            this.character.details.stats.max * parseInt(entry.distribution[1]) / 100,
            this.character.details.stats.max * parseInt(entry.distribution[2]) / 100,
            this.character.details.stats.max * parseInt(entry.distribution[3]) / 100
          ],
          backgroundColor: 'rgba(171,171,171, 0.1)',
          borderColor: 'rgb(171,171,171)'
        });
      });

      if (this.statSuggestions.length > 0) {
        this.character.details.chart.datasets.push(this.statSuggestions[0]);
      }

    },
    updateChartData() {
      this.character.details.chart = {
        labels: ["Crit", "Haste", "Mastery", "Versatility"],
        datasets: [this.character.details.distribution, this.statSuggestions[this.statSuggestion]]
      };
    },
    updateCharacter() {
      CharacterService.updateCharacter(this.character.id, {
        defaultRole: this.administration.defaultRole,
        state: this.administration.state
      }).then(data => {
        if (data !== null) {
          this.character.defaultRole = this.administration.defaultRole;
          this.character.state = this.administration.state;
        } else {
          this.administration.defaultRole = this.character.defaultRole;
          this.administration.state = this.character.state;
        }
      });
    },
    changeFilteredItemCount(count){
      this.loot.filteredItemCount = count;
    }
  },
  computed: {
    filterLootHeader(){
      let headers = [
        {text: this.$t('char_detail.loot.table.name'), value: "name"},
        {text: this.$t('char_detail.loot.table.choice'), value: "choice"},
        {text: this.$t('char_detail.loot.table.typ'), value: "type"},
        {text: this.$t('char_detail.loot.table.episode'), value: "lootEpisode"},
        {text: this.$t('char_detail.loot.table.roster'), value: "roster"},
        {text: this.$t('char_detail.loot.table.date'), value: "createdAt"},

      ]
      if(this.loot.includeCopiedItems){
        headers.push({text: this.$t('char_detail.loot.table.copied'), value: "visibleItem"}) ;
      }

      return headers;
    },
    filterItems() {
      let loot = this.character.details.loot;
      if (this.loot.episode !== "All") {
        loot = loot.filter(x => x.lootEpisode === this.loot.episode);
      }

      if(this.loot.type !== null){
        loot = loot.filter(x => x.type === this.loot.type);
      }

      if(this.loot.choice !== null){
        loot = loot.filter(x => this.loot.choice.includes(x.choice));
      }

      if(!this.loot.includeCopiedItems){
        loot = loot.filter(x => x.visibleItem);
      }

      this.changeFilteredItemCount(loot.length);
      return loot;
    },
    editData() {
      return this.$store.state.auth.user.rights.includes("EDIT_CHARACTER_DATA");
    },
    allowSim() {
      return this.$store.state.auth.user.rights.includes("ALLOW_SIM");
    }
  }
}
</script>
<style scoped>
</style>
