import MessageService from "@/services/message.service";

import axios from 'axios';

const API_URL = '/service/data';

class DataService {
    getBattleNetClientApi() {
        return axios.get(`${API_URL}/b-net-api-id`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to retrieve battle net client id. Error: '${error.response.statusText}'`);
            return "";
        });
    }

    getAllAppConfigs() {
        return axios.get(`${API_URL}/appConfig`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to retrieve app config. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    saveAppConfigs(appConfig) {
        return axios.put(`${API_URL}/appConfig`, appConfig).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to save app config. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getRoles() {
        return axios.get(`${API_URL}/roles`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get roles. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    updateRole(id, rights) {
        return axios.put(`${API_URL}/roles/${id}`, rights).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to update roles. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    getRights() {
        return axios.get(`${API_URL}/rights`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get rights. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getTalentTreeForClass(version, className) {
        return axios.get(`${API_URL}/talent/${className}/${version}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get talent tree for class. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    getInstance(name) {
        return axios.get(`${API_URL}/instance/${name}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to instance. Error: '${error.response.statusText}'`);
            return "";
        });
    }

    getConnections() {
        return axios.get(`${API_URL}/connections`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get connections. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getImages(filterNotArchived) {
        return axios.get(`${API_URL}/images/${filterNotArchived}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get images. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    createImage(description, image) {
        return axios.post(`${API_URL}/images`, {
            description: description,
            image: image
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create image. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    updateImage(id, updateData) {
        return axios.post(`${API_URL}/images/${id}`, updateData).then(() => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to update image. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    deleteImage(id) {
        return axios.delete(`${API_URL}/images/${id}`).then(() => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to delete image. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    createRole(role, description) {
        return axios.post(`${API_URL}/role`, {
            role: role,
            description: description
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create role. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    deleteRole(roleId) {
        return axios.delete(`${API_URL}/role/${roleId}`).then(() => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to delete role. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    getClassList() {
        return axios.get(`${API_URL}/class-list`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get class list. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getSpecializationList() {
        return axios.get(`${API_URL}/spec-list`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get specialization list. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getHeroList() {
        return axios.get(`${API_URL}/hero-list`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get hero choice list. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getRealmList() {
        return axios.get(`${API_URL}/realm-list`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get realm list. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getLogs(body) {
        return axios.post(`${API_URL}/log`,body).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get logs. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    getItemMedia(id) {
        return axios.get(`${API_URL}/item-media/${id}`).then(response => {
            return response.data;
        }).catch(error => {
            return "";
        });
    }
}

export default new DataService();
