<template>
  <v-checkbox v-model="vModel" :disabled="disabled" hide-details @change="changeAction">
    <template v-slot:label>
      <div class="ma-2" style="width: 25px; height:25px; display: inline-block; text-align: center">
        <a v-bind:href="`https://www.wowhead.com/spell=${item.ID}`" :data-wowhead="`domain=${$i18n.locale}`" target="_blank">
          <img v-bind:src="`https://wow.zamimg.com/images/wow/icons/large/${item.MEDIA}.jpg`"
               style="width: 25px; height:25px;" alt="wow-icon">
        </a>
      </div>
      <span>{{ $t(`simulation.${category}.${item.KEY}`) }}</span>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "SimulationCheckbox",
  props: ["identifier", "item", "model", "disabled", "category"],
  data() {
    return {
      vModel: this.model
    }
  },
  methods: {
    changeAction() {
      this.$root.$emit("sim_check_change", {
        identifier: this.identifier,
        value: this.vModel
      });
    }
  },
  watch: {
    model: function() {
      this.vModel = this.model;
    }
  }
}
</script>

<style scoped>

</style>