import MessageService from "@/services/message.service";

import axios from 'axios';
const API_URL = '/service/sync';

class SyncService {
    syncTalents(gameVersion, force) {
        MessageService.info("Synchronisation of talents started");
        return axios.get(`${API_URL}/talents/${gameVersion}/${force}`).then(response => {
            MessageService.success(response.statusText);
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to sync talent trees. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    syncCharacters() {
        MessageService.info("Synchronisation of characters started");
        return axios.get(`${API_URL}/characters`).then(response => {
            MessageService.success(response.statusText);
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to sync characters. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    addInstance(name) {
        MessageService.info("Adding of instance started");
        return axios.get(`${API_URL}/add-instance/${name}`).then(response => {
            MessageService.success(response.statusText);
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to add instance. Error: '${error.response.statusText}'`);
            return [];
        });
    }
}

export default new SyncService();
