<template>
    <span>
        <v-avatar :size="size">
          <img alt="shaman" src="../assets/img/class/shaman.jpg" v-if="value === 'shaman' || value === 'schamane'">
          <img alt="warlock" src="../assets/img/class/warlock.jpg" v-if="value === 'warlock' || value === 'hexenmeister'">
          <img alt="warrior" src="../assets/img/class/warrior.jpg" v-if="value === 'warrior' || value === 'krieger'">
          <img alt="rogue" src="../assets/img/class/rogue.jpg" v-if="value === 'rogue' || value === 'schurke'">
          <img alt="monk" src="../assets/img/class/monk.jpg" v-if="value === 'monk' || value === 'mönch'">
          <img alt="paladin" src="../assets/img/class/paladin.jpg" v-if="value === 'paladin' || value === 'paladin'">
          <img alt="mage" src="../assets/img/class/mage.jpg" v-if="value === 'mage' || value === 'magier'">
          <img alt="druid" src="../assets/img/class/druid.jpg" v-if="value === 'druid' || value === 'druide'">
          <img alt="demonhunter" src="../assets/img/class/demonhunter.jpg" v-if="value === 'demon hunter' || value === 'dämonenjäger'">
          <img alt="deathknight" src="../assets/img/class/deathknight.jpg" v-if="value === 'death knight' || value === 'todesritter'">
          <img alt="priest" src="../assets/img/class/priest.jpg" v-if="value === 'priest' || value === 'priester'">
          <img alt="hunter" src="../assets/img/class/hunter.jpg" v-if="value === 'hunter' || value === 'jäger'">
          <img alt="hunter" src="../assets/img/class/evoker.jpg" v-if="value === 'evoker' || value === 'rufer'">
        </v-avatar>
    </span>
</template>

<script>
export default {
  name: "ClassAvatar",
  props: ['value', 'size']
}
</script>

<style scoped>

</style>
