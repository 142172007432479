import SimulationSettings from "@/simulation/SimulationSettings";
import SimulationBuffs from "@/simulation/SimulationBuffs";
import SimulationConsumables from "@/simulation/SimulationConsumables";
import SimulationTrinkets from "@/simulation/SimulationTrinkets";
import SimulationVersion from "@/simulation/SimulationVersion";
import SimulationService from "@/services/simulation.service";

export default class SimulationOptions{
    SIM_VERSION;
    SETTINGS;
    BUFFS;
    CONSUMABLES;
    TRINKETS;

    constructor(existingData = undefined) {
        this.setToDefault();
        SimulationService.getSimulationCraftBuilds().then(data => {
            this.SIM_VERSION.updateWithData(data);

            if(existingData){
                this.loadFromContext(existingData);
            } else {
                this.loadFromStorage();
            }
        });
    }
    setToDefault(simVersionData){
        this.SIM_VERSION = new SimulationVersion();
        this.SETTINGS = new SimulationSettings();
        this.BUFFS = new SimulationBuffs();
        this.CONSUMABLES = new SimulationConsumables();
        this.TRINKETS = new SimulationTrinkets();
    }

    toJson(){
        return {
            version: this.SIM_VERSION.toJson(),
            settings: this.SETTINGS.toJson(),
            consumables: this.CONSUMABLES.toJson(),
            buffs: this.BUFFS.toJson(),
            trinkets: this.TRINKETS.toJson()
        }
    }

    loadFromStorage(){
        let context = localStorage.getItem("simulation_context");
        if(context){
            let parsed = JSON.parse(context);
            this.loadFromContext(parsed);
        } else {
            localStorage.setItem("simulation_context", JSON.stringify({
                version: this.SIM_VERSION.toJson(),
                settings: this.SETTINGS.toJson(),
                consumables: this.CONSUMABLES.toJson(),
                buffs: this.BUFFS.toJson(),
                trinkets: this.TRINKETS.toJson()
            }));
        }
    }

    loadFromContext(existingData){
        this.SIM_VERSION.load(existingData.version);
        this.SETTINGS.load(existingData.settings);
        this.CONSUMABLES.load(existingData.consumables);
        this.BUFFS.load(existingData.buffs);
        this.TRINKETS.load(existingData.trinkets);
    }

    save(){
        localStorage.setItem("simulation_context", JSON.stringify({
            version: this.SIM_VERSION.toJson(),
            settings: this.SETTINGS.toJson(),
            consumables: this.CONSUMABLES.toJson(),
            buffs: this.BUFFS.toJson(),
            trinkets: this.TRINKETS.toJson()
        }));
    }
}