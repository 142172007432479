import DataService from "@/services/data.service";

class EquipmentHelper{
    resolveWowHeadData(item, locale) {
        let entries = [`domain=${locale}`];
        if (item.sockets) {
            entries.push(`gems=${item.sockets.map(x => x.id).join(":")}`);
        }

        if (item.enchantments) {
            entries.push(`ench=${item.enchantments[0].id}`);
        }

        if (item.bonus_list) {
            entries.push(`bonus=${item.bonus_list.join(":")}`);
        }

        entries.push(`ilvl=${item.level}`);
        return entries.join("&");
    }

    async parseSimcEquipItem(line, locale){
        let wowHeadData = [`domain=${locale}`];
        let values = line.split(",");

        let id = 0;
        let enchant = "";
        let sockets = [];
        let bonusIds = [];

        values.forEach(x => {
            if(x.length > 0 && x.includes("=")){
                let split = x.split("=");

                if(split[0] === "id"){
                    id = split[1];
                }

                if(split[0] === "enchant_id"){
                    enchant = split[1];
                    wowHeadData.push(`ench=${split[1]}`);
                }

                if(split[0] === "bonus_id"){
                    bonusIds = split[1].split("/");
                    wowHeadData.push(`bonus=${bonusIds.join(":")}`);
                }

                if(split[0] === "gem_id"){
                    sockets = split[1].split("/");
                    wowHeadData.push(`gems=${sockets.join(":")}`);
                }
            }
        });

        let media = await DataService.getItemMedia(parseInt(id));

        return {
            id: id,
            enchant: enchant,
            sockets: sockets,
            bonusIds: bonusIds,
            img_wowhead: media,
            data_wowhead: wowHeadData.join("&"),
            href_wowhead: `https://www.wowhead.com/item=${id}`,
            simcEntry: line
        };
    }

    resolveEquipVisualization(equipmentList, locale){
        equipmentList.forEach(equippedItem => {
            equippedItem.img_wowhead = equippedItem.media;
            equippedItem.data_wowhead = this.resolveWowHeadData(equippedItem, locale);
            equippedItem.href_wowhead = `https://www.wowhead.com/item=${equippedItem.id}`
        });
    }

    filterEquippedItems(items){
        if(items){
            return items.filter(x => x.type !== "TABARD" && x.type !== "SHIRT");
        } else {
            return [];
        }
    }

    gearIconStyle(item) {
        let itemColor;

        switch(item.quality){
            case "LEGENDARY":
                itemColor = "darkorange"
                break;
            case "EPIC":
                itemColor = "darkviolet"
                break;
            default:
                itemColor = "grey"
                break;
        }

        return {
            borderColor: itemColor
        }
    }

    getItemContext(item, locale) {
        return `domain=${locale}&bonus=${item.itemInfo.bonusIds.join(":")}`;
    }

}

export default new EquipmentHelper();