<template>
  <div>
    <h3>
      {{ $t('behaviour_log.title') }}
    </h3>
    <v-divider></v-divider>
    <v-row class="pa-3 mt-2 align-center">
      <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.misc.search')" class="mr-2"
                    clearable hide-details></v-text-field>
      <v-autocomplete :items="users" v-model="filterOnUser" :label=" $t('general.misc.user')" hide-details class="mr-1"
                      return-object clearable item-text="username"
                      :filter="Utilities().searchNormalized">
      </v-autocomplete>
      <v-autocomplete :items="types" v-model="filterOnType" :label=" $t('behaviour_log.headers.type')" hide-details
                      class="mr-1"
                      return-object clearable
                      :filter="Utilities().searchNormalized">
        <template v-slot:selection="{ item }">
          <span>{{ $t(`behaviour_log.type.${item.toLowerCase()}`) }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span>{{ $t(`behaviour_log.type.${item.toLowerCase()}`) }}</span>
        </template>
      </v-autocomplete>
      <v-autocomplete :items="activities" v-model="filterOnActivity" :label="$t('behaviour_log.headers.sub_type')"
                      hide-details class="mr-1"
                      return-object clearable
                      :filter="Utilities().searchNormalized">
        <template v-slot:selection="{ item }">
          <span>{{ $t(`behaviour_log.subType.${item.toLowerCase()}`) }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span>{{ $t(`behaviour_log.subType.${item.toLowerCase()}`) }}</span>
        </template>
      </v-autocomplete>
      <v-autocomplete :items="lootEpisodes" v-model="filterOnLootEpisode" :label="$t('general.misc.episode')"
                      hide-details class="mr-1"
                      return-object clearable
                      :filter="Utilities().searchNormalized">
      </v-autocomplete>
      <v-checkbox :label="$t('characters.show_details')" v-model="showDetails" class="ml-2 mr-2"
                  hide-details></v-checkbox>
    </v-row>
    <v-row class="pa-3 mt-2 align-center justify-end">
      <v-btn class="primary" @click="openDeleteConfirmation">
        <v-icon>mdi-delete</v-icon>
        {{ $t('general.actions.delete') }}
      </v-btn>
      <v-btn class="primary ml-2" @click="isOpenCreateBehaviourLogs = true">
        <v-icon>mdi-plus-circle</v-icon>
        {{ $t('general.actions.add') }}
      </v-btn>
    </v-row>
    <v-data-table :headers="getHeaders" :items="behaviourLogs" item-key="id" class="mt-4 pa-1"
                  :search="search" :loading="loading" show-select v-model="selected"
                  :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer
                  @page-count="pageCount = $event" dense
                  :custom-filter="Utilities().searchNormalized">
      <template v-slot:item.character="{ item }">
        <p class="pa-2 pb-0 mb-1">
          <span :class="item.character.class.toLowerCase().replaceAll(' ', '-')"><b>{{ item.character.name }}</b></span><br>
          <span>{{ item.character.realm }}</span>
        </p>
      </template>
      <template v-slot:item.user="{ item }">
        <span v-if="item.user">
          <v-icon class="mr-1 small">mdi-account</v-icon>
          {{ item.user.username }}
        </span>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item.type">{{ $t(`behaviour_log.type.${item.type.toLowerCase()}`) }}</span>
      </template>
      <template v-slot:item.subType="{ item }">
        <span v-if="item.subType">{{ $t(`behaviour_log.subType.${item.subType?.toLowerCase()}`) }}</span>
      </template>
      <template v-slot:item.executionDate="{ item }">
        <span>{{ new Date(item.executionDate).toLocaleDateString() }}</span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleDateString() }} {{ new Date(item.createdAt).toLocaleTimeString() }}</span>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span v-if="getUser(item.createdBy)">
            <v-icon class="mr-1 small">mdi-account</v-icon>
          {{ getUser(item.createdBy).username }}
        </span>
        <span v-else>{{item.createdBy}}</span>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-if="behaviourLogs.length > 0" v-model="page" :length="pageCount"></v-pagination>
    </div>

    <v-dialog transition="dialog-bottom-transition" persistent max-width="1000px" v-model="isOpenCreateBehaviourLogs">
      <v-card class="planner-new-raid">
        <v-card-title>
          <span class="text-h5">{{ $t('behaviour_log.add') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row class="ma-1 pa-1 align-end">
                <v-col cols="6" class="pa-0">
                  <v-autocomplete :label="$t('behaviour_log.headers.type')" v-model="behaviourLogDialog.type"
                                  hide-details @change="changeType"
                                  :items="types" return-object class="mr-2">
                    <template v-slot:selection="{ item }">
                      <span>{{ $t(`behaviour_log.type.${item.toLowerCase()}`) }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{ $t(`behaviour_log.type.${item.toLowerCase()}`) }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-autocomplete :label="$t('behaviour_log.headers.sub_type')" v-model="behaviourLogDialog.subType"
                                  :disabled="behaviourLogDialog.type !== 'BONUS'"
                                  hide-details clearable
                                  :items="activities" return-object class="mr-2">
                    <template v-slot:selection="{ item }">
                      <span>{{ $t(`behaviour_log.subType.${item.toLowerCase()}`) }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{ $t(`behaviour_log.subType.${item.toLowerCase()}`) }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="ma-1 pa-1 align-end">
                <v-col cols="6" class="pa-0">
                  <v-autocomplete class="mr-2"
                                  :label="$t('behaviour_log.execution_date_type')"
                                  v-model="behaviourLogDialog.executionDateType" hide-details
                                  :items="behaviourLogDialog.executionDateTypes" return-object>
                    <template v-slot:selection="{ item }">
                      <span>{{ $t(`general.timestamps.${item.toLowerCase()}`) }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{ $t(`general.timestamps.${item.toLowerCase()}`) }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-autocomplete class="mr-2"
                                  :label="$t('general.timestamps.week')"
                                  v-if="behaviourLogDialog.executionDateType === 'WEEK'"
                                  v-model="behaviourLogDialog.executionWeek" hide-details
                                  :items="weekRanges" return-object></v-autocomplete>
                  <date-input-text-field identifier="executionDate" :model="behaviourLogDialog.executionDate"
                                         :label="$t('general.timestamps.day')"
                                         v-if="behaviourLogDialog.executionDateType === 'DAY'"/>
                </v-col>
              </v-row>
              <v-row class="ma-1 pa-1 align-end">
                <v-text-field :label="$t('behaviour_log.headers.reason')" v-model="behaviourLogDialog.reason"
                              hide-details></v-text-field>
              </v-row>
              <v-row class="ma-1 pa-1 align-end">
                <v-autocomplete :items="filteredCharacters" v-model="behaviourLogDialog.character" class="mr-1"
                                :label="$t('general.misc.character')" hide-details return-object
                                :filter="Utilities().searchNormalizedCharacter" item-text="display">
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-btn @click="addCharacterToEntries()" :disabled="behaviourLogDialog.character === null" class="mr-1"
                       hide-details>
                  <v-icon class="">mdi-plus-circle</v-icon>
                  {{ $t("characters.add_character")}}
                </v-btn>
                <v-btn @click="importRosterFire">
                  <v-icon class="mr-1">mdi-download</v-icon>
                  {{ $t('behaviour_log.import_roster') }}
                </v-btn>
              </v-row>
              <v-divider class="mt-6"></v-divider>
              <v-row class="mt-4 ma-1 pa-1 align-end" v-if="behaviourLogDialog.characters.length > 0">
                <v-btn @click="changeSelection(true)" class="mr-1">
                  <v-icon class="mr-1" color="green">mdi-check-circle</v-icon>
                  {{ $t('behaviour_log.select_all') }}
                </v-btn>
                <v-btn @click="changeSelection(false)" class="mr-1">
                  <v-icon class="mr-1" color="red">mdi-close-circle</v-icon>
                  {{ $t('behaviour_log.deselect_all') }}
                </v-btn>
                <v-btn @click="clearCharacters" class="mr-1">
                  <v-icon class="mr-1" color="grey">mdi-delete</v-icon>
                  {{ $t('behaviour_log.delete_all') }}
                </v-btn>
              </v-row>
              <group-setup class="pa-1 align-end" :entries="behaviourLogDialog.characters" :addition-allowed="false"
                           :selection-change-allowed="true">
              </group-setup>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="isOpenCreateBehaviourLogs = false" class="mb-1">
            {{ $t('general.actions.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="addBehaviourLogs" class="mr-1 mb-1"
                 :disabled="behaviourLogDialog.characters.length === 0 || behaviourLogDialog.type === null ||
                       behaviourLogDialog.executionDateType === 'DAY' && behaviourLogDialog.executionDate === null ||
                        behaviourLogDialog.executionDateType === 'WEEK' && behaviourLogDialog.executionWeek === null ||
                       behaviourLogDialog.type === 'PENALTY' && behaviourLogDialog.reason.length === 0 ||
                       behaviourLogDialog.type === 'BONUS' && behaviourLogDialog.subType === null ||
                       behaviourLogDialog.type === 'STRIKE' && behaviourLogDialog.reason.length === 0">
            {{ $t('general.actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-dialog identifier="deleteBehaviourLogs" :question="$t('behaviour_log.delete_confirmation')"
                         :confirmation-handler="deleteBehaviourLogs"></confirmation-dialog>
  </div>
</template>

<script>
import BehaviourLogService from "@/services/behaviour_log.service";
import Utilities from "@/util/Utilities";
import CharacterService from "@/services/character.service";
import UserService from "@/services/user.service";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import GroupSetup from "@/components/GroupSetup.vue";
import MessageService from "@/services/message.service";
import DateInputTextField from "@/components/controls/DateInputTextField.vue";

export default {
  name: "BehaviourLogManagement",
  components: {DateInputTextField, GroupSetup, ConfirmationDialog},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: '',
      selected: [],
      headers: [
        { text: this.$t('general.misc.character'), value: "character"},
        {
          text: this.$t('general.misc.user'), value: "user",
          filter: value => {
            return this.filterOnUser === null || value !== null && value.username === this.filterOnUser.username;
          }
        },
        {
          text: this.$t('behaviour_log.headers.type'), value: "type",
          filter: value => {
            return this.filterOnType === null || value === this.filterOnType;
          }
        },
        {
          text: this.$t('behaviour_log.headers.sub_type'), value: "subType",
          filter: value => {
            return this.filterOnActivity === null || value === this.filterOnActivity;
          }
        },
        {text: this.$t('behaviour_log.headers.reason'), value: "reason", width: '30%'},
        {text: this.$t('behaviour_log.headers.value'), value: "value"},
        {text: this.$t('behaviour_log.headers.executionDate'), value: "executionDate"},
        {
          text: this.$t('char_detail.loot.table.episode'), value: "lootEpisode",
          filter: value => {
            return this.filterOnLootEpisode === null || value === this.filterOnLootEpisode;
          }
        },
        {text: 'Batch ID', value: "batchId", extended: true},
        {text: this.$t('general.timestamps.created_at'), value: "createdAt", extended: true},
        {text: this.$t('general.misc.created_by'), value: "createdBy", extended: true},
        {
          text: '', value: "character.name", width: "20px", align: " d-none"
        }
      ],
      loading: true,
      showDetails: false,
      characters: [],
      behaviourLogs: [],
      users: [],
      filterOnUser: null,
      weekRanges: [],
      weekRange: null,
      activities: [],
      filterOnActivity: null,
      types: [],
      filterOnType: null,
      lootEpisodes: [],
      filterOnLootEpisode: null,
      isOpenCreateBehaviourLogs: false,
      behaviourLogDialog: {
        executionDateTypes: ["DAY", "WEEK"],
        executionDateType: 'WEEK',
        character: null,
        characters: [],
        type: null,
        subType: null,
        reason: "",
        executionDate: null,
        executionWeek: null
      }
    }
  },
  async mounted() {
    this.$root.$on("date_input_change", data => {
      this.behaviourLogDialog.executionDate = data.value;
    });

    this.loading = true;
    this.weekRanges = this.getWeekRanges();
    this.weekRange = this.weekRanges[0];
    this.behaviourLogDialog.executionWeek = this.weekRanges[0];

    await this.loadBehaviourLogs();
    await this.loadCharacters();
    await this.loadUsers();
    await this.loadEpisodes();
    this.loading = false;
  },
  methods: {
    Utilities() {
      return Utilities
    },

    getUser(createdBy){
      return this.users.find(user => user.id === createdBy);
    },

    addBehaviourLogs() {
      let appConfig = this.$store.getters["data/appConfig"];
      let currentEpisode = appConfig.find(x => x.key === "CURRENT_LOOT_EPISODE");
      let episode = "";
      if (currentEpisode) {
        episode = currentEpisode.value;
      }

      let executionDate = "";
      if(this.behaviourLogDialog.executionDateType === "DAY"){
        executionDate = this.behaviourLogDialog.executionDate;
      } else {
        executionDate = this.behaviourLogDialog.executionWeek.value;
      }

      BehaviourLogService.addBehaviourLogsForCharacterList({
        characters: this.behaviourLogDialog.characters.filter(x => x.selected),
        type: this.behaviourLogDialog.type,
        subType: this.behaviourLogDialog.subType,
        reason: this.behaviourLogDialog.reason,
        executionType: this.behaviourLogDialog.executionDateType,
        executionDate: executionDate,
        lootEpisode: episode,
        author: this.currentUser.data.id
      }).then(res => {
        if (res != null) {
          this.behaviourLogDialog.character = null;
          this.behaviourLogDialog.characters = [];
          this.behaviourLogDialog.reason = "";
          this.behaviourLogDialog.executionWeek = this.weekRanges[0];
          this.behaviourLogDialog.executionDate = null;

          this.selected = [];
          this.$root.$emit("update", this.behaviourLogDialog.characters);

          this.isOpenCreateBehaviourLogs = false;

          this.loading = true;
          this.loadBehaviourLogs().then(() => {
            this.loading = false;
          });
        }
      });
    },
    deleteBehaviourLogs() {
      this.$root.$emit('deleteBehaviourLogs_confirmationOpen', false);

      BehaviourLogService.deleteBehaviourLogs({
        ids: this.selected.map(x => x.id)
      }).then(res => {
        if (res != null) {
          this.selected = [];
          this.loading = true;
          this.loadBehaviourLogs().then(() => {
            this.loading = false;
          });
        }
      });
    },
    openDeleteConfirmation() {
      this.$root.$emit('deleteBehaviourLogs_confirmationOpen', true);
    },

    changeType() {
      if (this.behaviourLogDialog.type === 'BONUS') {
        this.behaviourLogDialog.subType = this.activities[0];
      } else {
        this.behaviourLogDialog.subType = null;
      }
    },
    mapCharacter(character) {
      return {
        id: character.id,
        character: {
          name: character.name,
          realm: character.realm
        },
        class: character.class,
        role: character.defaultRole,
        userId: character.userId,
        selected: true
      }
    },
    addCharacterToEntries() {
      let found = this.behaviourLogDialog.characters.find(x => x.id === this.behaviourLogDialog.character.id);
      if (!found) {
        this.behaviourLogDialog.characters.push(this.mapCharacter(this.behaviourLogDialog.character));
        this.behaviourLogDialog.character = null;
        this.$root.$emit("update", this.behaviourLogDialog.characters);
      } else {
        MessageService.warn("Duplicated entry is not possible");
      }
    },
    importRosterFire() {
      let filteredCharacters = this.characters.filter(x => x.rosterFire);

      filteredCharacters.forEach(newCharacter => {
        let found = this.behaviourLogDialog.characters.find(x => x.id === newCharacter.id);
        if (!found) {
          this.behaviourLogDialog.characters.push(this.mapCharacter(newCharacter));
        }
      });

      this.$root.$emit("update", this.behaviourLogDialog.characters);
    },
    clearCharacters() {
      this.behaviourLogDialog.characters = [];
      this.$root.$emit("update", []);
    },
    changeSelection(isSelected) {
      this.behaviourLogDialog.characters.forEach(char => {
        char.selected = isSelected;
      });
      this.$root.$emit("update", this.behaviourLogDialog.characters);
    },

    async loadBehaviourLogs() {
      let behaviourLogs = await BehaviourLogService.getBehaviourLogs();
      this.behaviourLogs = behaviourLogs.data;
      this.types = behaviourLogs.types;
      this.behaviourLogDialog.type = behaviourLogs.types[0];
      this.activities = behaviourLogs.subTypes;
      this.behaviourLogDialog.subType = behaviourLogs.subTypes[0];
    },
    async loadCharacters() {
      this.characters = await CharacterService.getCharacterList(true);
    },
    async loadUsers() {
      this.users = await UserService.getAll(false, false);
      this.users = this.users.sort((a, b) => a.username.localeCompare(b.username));
    },
    async loadEpisodes() {
      let appConfig = this.$store.getters["data/appConfig"];
      let lootEpisodes = appConfig.find(x => x.key === "LOOT_EPISODES");
      if (lootEpisodes) {
        let episodeValues = lootEpisodes.value.split(";");
        episodeValues = episodeValues.sort((a, b) => a.localeCompare(b));
        episodeValues.forEach(value => {
          this.lootEpisodes.push(value);
        });
      }

      let currentEpisode = appConfig.find(x => x.key === "CURRENT_LOOT_EPISODE");
      if (currentEpisode) {
        this.filterOnLootEpisode = currentEpisode.value;
      }
    },

    getWeekRanges() {
      const today = new Date();
      const currentWeekday = today.getDay();

      // get wednesday of current week (id)
      let startDate = new Date(today);
      if (currentWeekday < 3) { // sunday (0), monday (1) or tuesday (2)
        startDate.setDate(today.getDate() - currentWeekday - 4);
      } else {
        startDate.setDate(today.getDate() - (currentWeekday - 3));
      }

      const result = [];

      for (let i = 0; i < 7; i++) {
        const weekStart = new Date(startDate);
        weekStart.setDate(weekStart.getDate() - (i * 7));
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        let text;
        if (i === 0) {
          text = `${this.$t('behaviour_log.this_week')} (${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)})`;
        } else if (i === 1) {
          text = `${this.$t('behaviour_log.last_week')} (${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)})`;
        } else {
          text = `${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)}`;
        }

        result.push({
          value: this.formatISODate(weekStart),
          text: text
        });
      }

      return result;
    },
    formatDate(date) {
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.`;
    },
    formatISODate(date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    getHeaders() {
      if (this.showDetails) {
        return this.headers;
      } else {
        return this.headers.filter(x => !x.extended);
      }
    },
    filteredCharacters() {
      return this.characters.filter(x => !this.behaviourLogDialog.characters.find(y => y.id === x.id)).sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  beforeDestroy() {
    this.$root.$off('date_input_change');
  }
}
</script>

<style scoped lang="scss">
.small-chip {
  padding: 5px;
}
</style>