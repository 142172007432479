import MessageService from "@/services/message.service";

import axios from 'axios';

const API_URL = '/service/simulation';

class SimulationService {
    getSimulationCraftBuilds(){
        return axios.get(`${API_URL}/builds`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get simulation craft builds. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    executeDataSim(body){
        return axios.post(`${API_URL}/run/data`, body).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to execute data sim. Error: '${error.response.statusText}'`);
        });
    }

    executeArmorySim(body) {
        return axios.post(`${API_URL}/run/armory`, body).then(response => {
            return response;
        }).catch(error => {
            return error.response;
        });
    }

    getResultHistoryForUser(userId) {
        return axios.get(`${API_URL}/history/${userId}`).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get result history for user. Error: '${error.response.statusText}'`);
            return error.response;

        });
    }

    saveResult(result){
        return axios.post(`${API_URL}/result`, result).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to save result. Error: '${error.response.statusText}'`);
            return error.response;
        });
    }

    deleteResult(resultId){
        return axios.delete(`${API_URL}/result/${resultId}`).then(response => {
            return true;
        }).catch(error => {
            MessageService.error(`Failed to delete result. Error: '${error.response.statusText}'`);
            return false;
        });
    }
}

export default new SimulationService();
