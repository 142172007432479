<template>
  <div>
    <canvas :id="id" style="height: 300px"></canvas>
  </div>
</template>

<script>
import { Chart, Legend, RadarController, RadialLinearScale, PointElement, LineElement, Filler} from "chart.js";
Chart.register(Legend, RadarController, RadialLinearScale, PointElement, LineElement, Filler);

export default {
  name: "ChartWrapper",
  props: ["id", "type", "data", "options"],
  data(){
    return {
      chart: null
    }
  },
  mounted() {
    const chartElement = document.getElementById(this.id);
    this.chart = new Chart(chartElement, {
      type: this.type,
      data: this.data,
      options: this.options
    });
  },
  watch: {
    data(newVal){
      this.chart.data = newVal;
      this.chart.update();
    }
  }
}
</script>

<style scoped>
</style>