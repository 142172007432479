<template>
  <div>
    <div>
      <v-row class="mx-2 mb-2 align-center">
        <v-btn class="mr-1" @click="refreshDetails()" :disabled="charactersLoading || detailsLoading" v-if="editData">
          {{ $t("characters.refresh_details") }}
        </v-btn>
        <v-btn @click="simulateCharacterList()" :disabled="rosterSim.loading" v-if="allowRosterSim">
          {{ $t('simulation.sim_roster') }}
        </v-btn>
        <v-progress-circular :size="25" color="primary" class="ml-2" indeterminate
                             v-if="rosterSim.loading || rosterSim.opening"></v-progress-circular>
        <div v-if="rosterSim.executionTime" class="ml-2 d-inline">
          <v-icon>mdi-timer-outline</v-icon>
          <p class="d-inline ml-1">{{ new Date(rosterSim.executionTime).toISOString().slice(14, -1) }}</p>
        </div>
        <v-checkbox :label="$t('simulation.use_chunks')" v-model="rosterSim.useChunks" class="ml-2 mt-0 pt-1"
                    hide-details @change="updateRosterSimSettings" v-if="allowRosterSim"></v-checkbox>
        <v-slider :label="$t('simulation.chunk_size')" thumb-label="always" thumb-size="20" v-model="rosterSim.chunks"
                  hide-details
                  :max="characters.filter(c => c.inRoster).length" min="1" v-if="allowRosterSim && rosterSim.useChunks"
                  class="ml-5 pt-1" @change="updateRosterSimSettings"></v-slider>
      </v-row>
      <v-row class="ma-2" v-if="rosterSim.detail.length > 0">
        <v-tooltip top v-for="(id, index) in rosterSim.detail" :key="index">
          <template v-slot:activator="{ on, attrs }">
            <a :href="`${baseResultUrl}/${id}`" target="_blank">
              <v-btn class="mt-2 mr-2" color="primary" v-bind="attrs" v-on="on"
                     :disabled="rosterSim.loading && rosterSim.opening">
                <v-icon>mdi-magnify</v-icon>
                {{ $t('general.misc.result') }} {{ index + 1 }}
              </v-btn>
            </a>
          </template>
          <span>{{ rosterSim.detailCharacters[id].sort((a, b) => a.localeCompare(b)).join(' | ') }}</span>
        </v-tooltip>
      </v-row>
      <v-row class="ma-2" v-if="rosterSim.skippedCharacters.length > 0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header><h4>{{ $t('simulation.skipped_characters') }}</h4></v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li v-for="character in rosterSim.skippedCharacters" :key="character">{{ character }}</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row class="ma-2">
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.misc.search')" class="mr-2"
                      clearable></v-text-field>
        <v-autocomplete :items="classList" v-model="filterOnClass" :label=" $t('general.misc.class')" hide-details
                        return-object clearable
                        :filter="Utilities().searchNormalizedClass">
          <template v-slot:selection="{ item }">
            <p class="font-weight-medium mb-0">
              <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
              <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
            </p>
          </template>
          <template v-slot:item="{ item }">
            <p class="font-weight-medium mb-0">
              <class-avatar :size="18" :type="'class'" :value="item?.toLowerCase()" class="mr-1"></class-avatar>
              <span :class="item?.toLowerCase().replaceAll(' ', '-')">{{ classMap[item][$i18n.locale] }}</span>
            </p>
          </template>
        </v-autocomplete>
        <v-checkbox :label="$t('characters.roster') + ' Feuer'" v-model="filterOnRosterFire"
                    class="ml-2 mr-2"></v-checkbox>
        <v-checkbox :label="$t('characters.roster') + ' Flamme'" v-model="filterOnRosterFlame"
                    class="ml-2  mr-2"></v-checkbox>
        <v-checkbox :label="$t('characters.my_chars') " v-model="filterOnUserCharacters"
                    v-if="!currentUser.data.isGeneric"
                    class="ml-2  mr-2"></v-checkbox>
      </v-row>
      <v-data-table :headers="headers" :items="characters" show-expand :expanded.sync="expanded" item-key="id"
                    :search="search" :loading="charactersLoading"
                    :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer
                    @page-count="pageCount = $event"
                    :custom-filter="Utilities().searchNormalized">
        <template v-slot:item.class="{ item }">
          <class-avatar v-bind:type="'class'" v-bind:value="item.class.toLowerCase()" v-bind:size="30"/>
        </template>
        <template v-slot:item.name="{ item }">
          <p class="pa-2 pb-0 mb-1">
            <span :class="item.class.toLowerCase().replaceAll(' ', '-')"><b>{{ item.name }}</b></span><br>
            <span>{{ item.realm }}</span>
          </p>
        </template>
        <template v-slot:item.character_detail.activeSpec.name="{ item }">
          <p class="pa-2 pb-0 mb-1" v-if="item['character_detail']?.activeSpec?.name">
            <specialization-avatar class="mr-3" :size="30" :type="item.class.toLowerCase()"
                                   :value="item['character_detail']?.activeSpec?.name.toLowerCase()"></specialization-avatar>
            {{ specMap[item["character_detail"]?.activeSpec?.name][$i18n.locale]}}
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row class="ma-2 align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openLink('armory', item)" v-bind="attrs" v-on="on">
                  <v-avatar size="25">
                    <v-img src="../assets/img/external/armory.png"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <span>Open armory page</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openLink('warcraft-logs', item)" v-bind="attrs" v-on="on">
                  <v-avatar size="25">
                    <v-img src="../assets/img/external/warcraft-logs.png"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <span>Open warcraft logs page</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openLink('raider-io', item)" v-bind="attrs" v-on="on">
                  <v-avatar size="25">
                    <v-img src="../assets/img/external/raider-io.png"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <span>Open raider.io page</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="simulateCharacter(item)" v-if="allowSim && simulationAllowed(item)"
                       :disabled="item.onDpsLoading" v-bind="attrs" v-on="on">
                  <v-avatar size="35">
                    <v-img src="../assets/img/external/sc.png"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <span>Simulate character</span>
            </v-tooltip>
            <a :href="`${baseResultUrl}/${item.simResult}`" target="_blank">
              <v-btn v-if="item.simResult !== null" color="primary" class="ml-2"
                     :disabled="item.onDpsLoading">
                <v-icon>mdi-file-chart-outline</v-icon>
                {{ $t('general.misc.result') }}
              </v-btn>
            </a>

            <v-progress-circular :size="25" color="primary" class="ml-1 pa-0" indeterminate
                                 v-if="item.onDpsLoading"></v-progress-circular>
            <div v-if="item.executionTime" class="ml-2 d-inline">
              <v-icon>mdi-timer-outline</v-icon>
              <p class="d-inline ml-1">{{ new Date(item.executionTime).toISOString().slice(14, -1) }}</p>
            </div>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length" class="pa-3">
            <character-details-view v-bind:character="item"></character-details-view>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-if="characters.length > 0" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "@/util/constants";
import SimulationService from "@/services/simulation.service";
import CharacterService from "@/services/character.service";
import ClassAvatar from "@/components/ClassAvatar";
import CharacterDetailsView from "@/components/CharacterDetailsView";
import MessageService from "@/services/message.service";
import SimulationOptions from "@/simulation/SimulationOptions";
import axios from "axios";
import Utilities from "@/util/Utilities";
import SpecializationAvatar from "@/components/SpecializationAvatar.vue";

export default {
  name: "Characters",
  components: {SpecializationAvatar, ClassAvatar, CharacterDetailsView},
  data() {
    return {
      tab: "details",
      expanded: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: '',
      headers: [
        {
          text: '', value: "class", width: "20px",
          filter: value => {
            return this.filterOnClass === null || value === this.filterOnClass;
          }
        },
        {text: this.$t('general.misc.name'), value: "name"},
        {text: this.$t('general.misc.spec'), value: "character_detail.activeSpec.name"},
        {text: this.$t('general.misc.level'), value: "level"},
        {text: "Itemlevel", value: "character_detail.itemLevel"},
        {text: this.$t('general.misc.rank'), value: "rank"},
        {text: this.$t('general.misc.dps'), value: "dps"},
        {text: this.$t('general.misc.class'), align: " d-none", value: "class"},
        {text: this.$t('general.misc.spec'), align: " d-none", value: "spec"},
        {
          text: 'Feuer', value: "rosterFire", align: " d-none",
          filter: value => {
            return !this.filterOnRosterFire || value === this.filterOnRosterFire;
          }
        },
        {
          text: 'Flamme', value: "rosterFlame", align: " d-none",
          filter: value => {
            return !this.filterOnRosterFlame || value === this.filterOnRosterFlame;
          }
        },
        {
          text: 'User ID', value: "userId", align: " d-none",
          filter: value => {
            return !this.filterOnUserCharacters || value === this.currentUser.data.id;
          }
        },
        {text: this.$t('general.misc.actions'), value: 'actions', sortable: false},
        {text: '', value: 'data-table-expand'}
      ],
      charactersLoading: true,
      detailsLoading: false,
      characters: [],
      simData: [],
      useChunks: true,
      characterSimulationTimers: [],
      rosterSim: {
        useChunks: false,
        chunks: 5,
        timer: null,
        executionTime: 0,
        detail: [],
        detailCharacters: {},
        skippedCharacters: [],
        opening: false,
        loading: false
      },
      filterOnUserCharacters: false,
      filterOnRosterFlame: false,
      filterOnRosterFire: false,
      filterOnClass: null,
      baseResultUrl: `${axios.defaults.baseURL}/service/simulation/result`,
      classList: this.$store.getters["data/classInfo"].list.sort(),
      classMap: this.$store.getters["data/classInfo"].map,
      specMap: this.$store.getters["data/specInfo"].map,
    }
  },
  async mounted() {
    if (!this.allowSim) {
      this.headers[4].align = " d-none";
    }

    let result = await CharacterService.getCharacterList();
    this.charactersLoading = false;
    this.characters = result;

    this.loadRosterSimSettings();
  },
  methods: {
    Utilities() {
      return Utilities
    },
    loadRosterSimSettings() {
      let rosterSim = localStorage.getItem("roster_sim");
      if (rosterSim) {
        let parsed = JSON.parse(rosterSim);
        this.rosterSim.useChunks = parsed.useChunks;

        let characterLength = this.characters.filter(c => c.inRoster).length;
        if (parsed.chunkSize > characterLength) {
          this.rosterSim.chunks = characterLength
        } else {
          this.rosterSim.chunks = parsed.chunkSize;
        }
      } else {
        localStorage.setItem("roster_sim", JSON.stringify({
          useChunks: this.rosterSim.useChunks,
          chunkSize: this.rosterSim.chunks
        }));
      }
    },
    updateRosterSimSettings() {
      localStorage.setItem("roster_sim", JSON.stringify({
        useChunks: this.rosterSim.useChunks,
        chunkSize: this.rosterSim.chunks
      }));
    },
    openLink(type, char) {
      let url;

      if (type === "armory") {
        url = `https://worldofwarcraft.blizzard.com/${this.$i18n.locale}-${this.$i18n.locale}/character/eu/${char.realm.toLowerCase().replaceAll(' ', '-')}/${char.name}`;
      } else if (type === "raider-io") {
        url = `https://raider.io/characters/eu/${char.realm.toLowerCase().replaceAll(' ', '-')}/${char.name}`;
      } else if (type === "warcraft-logs") {
        url = `https://www.warcraftlogs.com/character/eu/${char.realm.toLowerCase().replaceAll(' ', '-')}/${char.name}`;
      }

      if (url) {
        window.open(url, "_blank");
      }

    },
    simulationAllowed(char) {
      let healSpecializations = [
        "Restoration",
        "Holy",
        "Preservation",
        "Discipline",
        "Mistweaver"
      ];

      let maxLevel = Utilities.getAppConfig(this.$store,"MAX_LEVEL");
      let isMaxLevel = char.level.toString() === maxLevel;
      let isHealer = false;

      if(char["character_detail"] && char["character_detail"].activeSpec){
        isHealer = healSpecializations.includes(char["character_detail"].activeSpec.name);
      } else {
        let detailsLoaded = char.details && Object.keys(char.details).length > 0;
        isHealer = (detailsLoaded && healSpecializations.includes(char.spec)) || !detailsLoaded;
      }

      return isMaxLevel && !isHealer;
    },
    simulateCharacter(char) {
      char.executionTime = 0;
      char.executionStartDate = new Date();
      char.onDpsLoading = true;
      let stopwatch = setInterval(() => {
        char.executionTime =  new Date().getTime() - char.executionStartDate.getTime();
        if (!char.onDpsLoading) {
          let time =  new Date().getTime() - char.executionStartDate.getTime();
          console.info(`Simulation for ${char.name} done in ${time}ms.`);

          let index = this.characterSimulationTimers.indexOf(stopwatch);
          if(index !== -1){
            this.characterSimulationTimers = this.characterSimulationTimers.splice(index, 1);
          }

          clearInterval(stopwatch);
        }
      }, Constants.SIM_REFRESH_INTERVAL_IN_MS);

      this.characterSimulationTimers.push(stopwatch);

      let simOptions = new SimulationOptions();
      simOptions.loadFromStorage();

      let body = {
        settings: simOptions.toJson(),
        playerList: [{
          id: char.id,
          blizzardId: char.blizzardId,
          realm: char.realm,
          name: char.name
        }]
      };

      SimulationService.executeArmorySim(body).then(res => {
        let data = res.data;
        if (data.result) {
          this.resolveSimData(data.id, data.result.players);
        } else {
          if (data.skippedCharacters.length > 0) {
            MessageService.warn(`Character '${data.skippedCharacters[0]}' could not be simulated.'`);
          } else {
            MessageService.error(res.statusText);
          }
        }
      }).finally(() => {
        char.onDpsLoading = false;
      });
    },
    simulateCharacterList() {
      this.rosterSim.detail = [];
      this.rosterSim.detailCharacters = {};
      this.rosterSim.skippedCharacters = [];

      this.rosterSim.executionTime = 0;
      this.rosterSim.loading = true;

      let filteredCharacters = this.characters.filter(c => c.inRoster);
      let charactersToSim = filteredCharacters.map(c => ({
        id: c.id,
        blizzardId: c.blizzardId,
        realm: c.realm,
        name: c.name
      })).sort((a, b) => a.name.localeCompare(b.name));

      this.rosterSim.executionStartTime = new Date();
      this.rosterSim.timer = setInterval(() => {
        this.rosterSim.executionTime = new Date().getTime() - this.rosterSim.executionStartTime.getTime();
        if (!this.rosterSim.loading) {
          clearInterval(this.rosterSim.timer);
          let time = new Date().getTime() - this.rosterSim.executionStartTime.getTime();
          MessageService.info(`Amory sim for '${filteredCharacters.length}' characters done in ${time} ms.`);
        }
      }, Constants.SIM_REFRESH_INTERVAL_IN_MS);

      let simObject = new SimulationOptions();
      simObject.loadFromStorage();

      let chunkPromises = [];
      if (this.rosterSim.useChunks) {
        MessageService.info(`Start armory sim with chunks for '${filteredCharacters.length}' characters. Chunk size: '${this.rosterSim.chunks}' - Chunk count: '${Math.ceil(charactersToSim.length / this.rosterSim.chunks)}'`);

        for (let i = 0; i < charactersToSim.length; i += this.rosterSim.chunks) {
          chunkPromises.push(SimulationService.executeArmorySim({
            settings: simObject.toJson(),
            playerList: charactersToSim.slice(i, i + this.rosterSim.chunks)
          }));
        }
      } else {
        MessageService.info(`Start armory sim for '${filteredCharacters.length}' characters.`);
        chunkPromises.push(SimulationService.executeArmorySim({
          settings: simObject.toJson(),
          playerList: charactersToSim
        }));
      }

      Promise.allSettled(chunkPromises).then(values => {
        let simDetails = [];
        let simResults = [];
        let skippedCharacters = [];

        values.forEach(entry => {
          let data = entry.value.data;
          skippedCharacters = skippedCharacters.concat(data.skippedCharacters);

          if (data.result !== null) {
            simResults = simResults.concat(data.result.players);
            simDetails = simDetails.concat(data.id);
            this.rosterSim.detailCharacters[data.id] = data.result.players.map(c => c.name);
          }
        });

        if (skippedCharacters.length > 0) {
          MessageService.warn(`The following characters could not be simulated: [${skippedCharacters.join(",")}]`);
          this.rosterSim.skippedCharacters = skippedCharacters;
        }

        this.rosterSim.loading = false;
        this.rosterSim.detail = simDetails;
        this.resolveSimData(null, simResults);
      });
    },
    resolveSimData(simId, simData) {
      simData.forEach(player => {
        let index = this.characters.findIndex(x => x.name === player.name);
        if (index !== -1) {
          this.characters[index].onDpsLoading = false;
          this.characters[index].dps = Math.round(player.sim["collected_data"].dps.mean);
          this.characters[index].iLvl = this.getItemLevel(player.sim["gear"]);
          this.characters[index].simulation_results.push(player.result);
          if (simId !== null) {
            this.characters[index].simResult = simId;
          }
        }
      });
    },
    getItemLevel(gear) {
      let sum = 0;
      for (const [key, value] of Object.entries(gear)) {
        if (gear["off_hand"] === undefined && key === "main_hand") {
          sum += value["ilevel"];
        }

        sum += value["ilevel"];
      }

      return (sum / 16).toFixed(2);
    },
    refreshDetails(){
      this.detailsLoading = true;
      CharacterService.getCharacterListDetails().then(success => {
        this.detailsLoading = false;
        if(success){
          MessageService.success("Character detail refresh started");
        }
      });
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    allowSim() {
      return this.currentUser && this.currentUser.rights?.includes("ALLOW_SIM");
    },
    allowRosterSim() {
      return this.currentUser && this.currentUser.rights?.includes("ALLOW_ROSTER_SIM");
    },
    editData() {
      return this.$store.state.auth.user.rights.includes("EDIT_CHARACTER_DATA");
    }
  },
  beforeDestroy() {
    clearInterval(this.rosterSim.timer);
    this.characterSimulationTimers.forEach(timer => {
      clearInterval(timer);
    });
  }
}
</script>

<style scoped>
</style>