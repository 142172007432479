import Constants from "@/util/constants";

class BinaryArrayWriter {
    constructor() {
        this.initialize();
    }

    initialize() {
        this.array = [[]];
        this.arrayIndex = 0;
    }

    write(width, value) {
        for (let i = 0; i < width; i++) {
            this.array[this.arrayIndex].unshift(value >> i & 1);
            if (this.array[this.arrayIndex].length === 6) {
                this.arrayIndex += 1;
                this.array[this.arrayIndex] = [];
            }
        }
    }



    toExportString() {
        let string = "";

        // Remove last value if size is 0
        if (this.array[this.arrayIndex].length === 0) {
            this.array.pop();
            this.arrayIndex -= 1;
        }

        // 0-padding the last value.
        while (this.array[this.arrayIndex].length !== 6) {
            this.array[this.arrayIndex].unshift(0);
        }

        for (let i = 0; i < this.array.length; i++) {
            // Computing the value represented by the array of bits
            let value = this.array[i][5] * 1 + this.array[i][4] * 2 + this.array[i][3] * 4 + this.array[i][2] * 8 + this.array[i][1] * 16 + this.array[i][0] * 32;
            string += Constants.BASE_64_TABLE[value];
        }

        return string;
    }
}

export default new BinaryArrayWriter();
