<template>
  <div class="ml-2">
    <h2 class="mb-4">Minecraft</h2>

    <v-tabs right v-model="tab" @change="saveLastActiveTab">
      <v-tab key="installation-tab">Installation</v-tab>
      <v-tab key="server-info-tab">Server Info</v-tab>
      <v-tab key="rcon-tab" v-if="canAdministrate">Administration</v-tab>
      <v-tabs-items v-model="tab" class="mt-3">
        <v-tab-item key="installation-tab">
          <div class="pa-4">
            <h3>Installation</h3>
            <v-divider class="my-1"></v-divider>
            <ol>
              <li>Xbox-App öffnen und Minecraft installieren (Version siehe Server Info)</li>
              <li>Fabric installieren, alles so lassen - <a
                  href="https://maven.fabricmc.net/net/fabricmc/fabric-installer/0.11.2/fabric-installer-0.11.2.exe">Fabric
                Installer</a></li>
              <li>In den Minecraft Ordner navigieren (%appdata%/.minecraft)</li>
              <li>Im Minecraft Ordner den Unterordner "mods" erstellen</li>
              <li>Alle JAR-Dateien für Mods in den erstellten Ordner kopieren - <a target="_blank" href="https://zirkel-des-phoenix.de/files/minecraft">Link zu den Mods</a>
              </li>
              <li>Nun kann im Minecraft Launcher das Fabric-Profil ausgewählt werden -> Spiel starten</li>
              <li>Mehrspieler auswählen und neuen Server hinzufügen - Host: zirkel-des-phoenix.de</li>
              <li>Um spielen zu können, muss der Nutzer freigegeben werden
                <ul>
                  <li>Der Nutzernamen kann man im Minecraft Launcher finden</li>
                  <v-row class="align-center pa-5">
                    <v-img src="../../assets/img/minecraft_username.png"></v-img>
                  </v-row>

                </ul>
              </li>
            </ol>
          </div>

        </v-tab-item>
        <v-tab-item key="server-info-tab">
          <div class="pa-4">
            <h3>Server Info</h3>
            <v-divider class="my-1"></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-minecraft</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Version - {{ version }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ players.length }} /  {{ playerMax }} Spieler</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          </div>
          <div class="pa-4" v-if="players.length > 0">
            <h3>Spieler Liste</h3>
            <v-divider class="my-1"></v-divider>
            <v-list>
              <v-list-item v-for="player in players" :key="player.id">
                <v-list-item-avatar>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="player.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="player.id"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

        </v-tab-item>
        <v-tab-item key="rcon-tab" v-if="canAdministrate">
          <div class="pa-4">
            <h3>RCON</h3>
            <v-divider class="my-1"></v-divider>
            <v-container>
              <v-row class="py-2 align-center">
                <v-col>
                  <v-autocomplete label="Gespeichert" class="mr-1" :items="rconConnections" v-model="rconConnection" return-object
                              hide-details clearable>
                    <template v-slot:selection="{ item }">
                      <p>{{ item.title }} [{{ item.host }}:{{ item.port }}]</p>
                    </template>
                    <template v-slot:item="{ item }">
                      <p>{{ item.title }} [{{ item.host }}:{{ item.port }}]</p>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="py-2 align-center">
                <v-col>
                  <v-text-field class="mr-1" label="Host" type="text" v-model="newRcon.host"
                                hide-details :disabled="this.rconConnection != null"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field class="mr-1" label="Port" type="number" v-model="newRcon.port"
                                hide-details :disabled="this.rconConnection != null"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field class="mr-1" label="Passwort" type="password" v-model="newRcon.password"
                                hide-details autocomplete="new-password" :disabled="this.rconConnection != null"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pa-2 mt-4 justify-end">
                <v-btn class="mr-1 primary" @click="startRcon" v-if="!rconEnabled" :disabled="!enableRconStart">RCON
                  starten
                </v-btn>
                <v-btn class="primary" @click="stopRcon" v-if="rconEnabled">RCON stoppen</v-btn>
              </v-row>

            </v-container>
            <div v-if="rconEnabled" class="pa-2 mt-3">
              <div class="mt-3">
                <h3>Konsole</h3>
                <v-divider class="my-1"></v-divider>
                <div class="console" ref="rconConsole">
                  <p v-for="(entry, index) in rconMessages" :key="index" class="pb-0 mb-0">
                    <b>{{ entry.timestamp.toLocaleDateString() }} {{ entry.timestamp.toLocaleTimeString() }}:</b>
                    {{ entry.message }}
                  </p>
                </div>
                <v-row class="mt-2 pa-4 align-center">
                  <v-text-field label="Message" v-model="rconMessage" hide-details @keydown.enter="sendRconMessage"></v-text-field>
                  <v-btn class="mr-1 primary" @click="sendRconMessage">Send</v-btn>
                </v-row>
                <div class="mt-3">
                  <h3>Help</h3>
                  <v-divider class="my-1"></v-divider>
                  <div>
                    <p class="rconHelpEntry mb-0 pb-0" v-for="(help, index) in rconHelp" :key="index">
                      {{ help.command }}
                      <v-tooltip right v-if="help.options">
                        <template v-slot:activator="{ on, attrs }">
                         <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                        </template>
                        <span>{{ help.options }}</span>
                      </v-tooltip>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

  </div>
</template>

<script>
import {io} from 'socket.io-client';
import DataService from "@/services/data.service";
import MessageService from "@/services/message.service";
//import MessageService from "@/services/message.service";

export default {
  name: "Minecraft",
  data() {
    return {
      tab: 0,
      serverHost: "zirkel-des-phoenix.de",
      serverPort: 25565,
      rconClient: null,
      rconEnabled: false,
      rconConnection: null,
      rconConnections: [],
      rconConnected: false,
      rconMessages: [],
      rconHelp: [
        {command: "advancement", options: "grant | revoke"},
        {command: "attribute <target> <attribute> ()", options: "get | base | modifier"},
        {command: "execute", options: "run | if | unless | as | at | store | positioned | rotated | facing | align | anchored | in | summon | on"},
        {command: "bossbar", options: "add | remove | list | set | get"},
        {command: "clear [<targets>]"},
        {command: "clone", options: "<begin> | from"},
        {command: "damage <target> <amount> [<damageType>]"},
        {command: "data", options: "merge | get | remove | modify"},
        {command: "datapack", options: "enable | disable | list"},
        {command: "debug", options: "start | stop | function"},
        {command: "defaultgamemode <gamemode>"},
        {command: "difficulty [peaceful | easy | normal | hard]"},
        {command: "effect", options: "clear | give"},
        {command: "me <action>"},
        {command: "enchant <targets> <enchantment> [<level>]"},
        {command: "experience", options: "add | set | query"},
        {command: "xp -> experience"},
        {command: "fill <from> <to> <block> [replace | keep | outline | hollow | destroy]"},
        {command: "fillbiome <from> <to> <biome> [replace]"},
        {command: "forceload", options: "add | remove | query"},
        {command: "function <name>"},
        {command: "gamemode <gamemode> [<target>]"},
        {command: "gamerule", options: "announceAdvancements | blockExplosionDropDecay | commandBlockOutput | commandModificationBlockLimit | disableElytraMovementCheck | disableRaids | doDaylightCycle | doDaylightCycle_tc | doEntityDrops | doFireTick | doImmediateRespawn | doInsomnia | doLimitedCrafting | doMobLoot | doMobSpawning | doPatrolSpawning | doTileDrops | doTraderSpawning | doVinesSpread | doWardenSpawning | doWeatherCycle | drowningDamage | fallDamage | fireDamage | forgiveDeadPlayers | freezeDamage | globalSoundEvents | keepInventory | lavaSourceConversion | logAdminCommands | maxCommandChainLength | maxEntityCramming | mobExplosionDropDecay | mobGriefing | naturalRegeneration | playersSleepingPercentage | puffish_skills:announceNewPoints | randomTickSpeed | reducedDebugInfo | sendCommandFeedback | showDeathMessages | snowAccumulationHeight | spawnRadius | spectatorsGenerateChunks | tntExplosionDropDecay | universalAnger | waterSourceConversion"},
        {command: "give <targets> <item> [<count>]"},
        {command: "help [<command>]"},
        {command: "item", options: "replace | modify"},
        {command: "kick <targets> [<reason>]"},
        {command: "kill [<targets>]"},
        {command: "list [uuids]"},
        {command: "locate", options: "structure | biome | poi"},
        {command: "loot", options: "replace | insert | give | spawn"},
        {command: "msg <targets> <message>"},
        {command: "tell -> msg"},
        {command: "w -> msg"},
        {command: "particle <name> [<pos>]"},
        {command: "place", options: "feature | jigsaw | structure | template"},
        {command: "playsound <sound>", options: "master | music | record | weather | block | hostile | neutral | player | ambient | voice"},
        {command: "reload"},
        {command: "recipe", options: "give | take"},
        {command: "return <value>"},
        {command: "ride <target>", options: "mount | dismount"},
        {command: "say <message>"},
        {command: "schedule", options: "function | clear"},
        {command: "scoreboard", options: "objectives | players"},
        {command: "seed"},
        {command: "setblock <pos> <block> [destroy | keep | replace]"},
        {command: "spawnpoint [<targets>]"},
        {command: "setworldspawn [<pos>]"},
        {command: "spectate [<target>]"},
        {command: "spreadplayers <center> <spreadDistance> <maxRange>", options: "<respectTeams> | under"},
        {command: "stopsound <targets> [* | master | music | record | weather | block | hostile | neutral | player | ambient | voice]"},
        {command: "summon <entity> [<pos>]"},
        {command: "tag <targets>", options: "add | remove | list"},
        {command: "team", options: "v"},
        {command: "teammsg <message>"},
        {command: "tm -> teammsg"},
        {command: "teleport", options: "<location> | <destination> | <targets>"},
        {command: "tp -> teleport"},
        {command: "tellraw <targets> <message>"},
        {command: "time ", options: "set | add | query"},
        {command: "title <targets>", options: "clear | reset | title | subtitle | actionbar | times"},
        {command: "trigger <objective> [add | set]"},
        {command: "weather", options: "clear | rain | thunder"},
        {command: "worldborder", options: "add | set | center | damage | get | warning"},
        {command: "jfr", options: "start | stop"},
        {command: "ban-ip <target> [<reason>]"},
        {command: "banlist [ips | players]"},
        {command: "ban <targets> [<reason>]"},
        {command: "deop <targets>"},
        {command: "op <targets>"},
        {command: "pardon <targets>"},
        {command: "pardon-ip <target>"},
        {command: "perf", options: "start | stop"},
        {command: "save-all [flush]"},
        {command: "save-off"},
        {command: "save-on"},
        {command: "setidletimeout <minutes>"},
        {command: "stop"},
        {command: "whitelist", options: "on | off | list | add | remove | reload"},
        {command: "planetgui [<target>]"},
        {command: "bclib", options: "place | print | dump_datapack | request_garbage_collection | debug_ore | sliceZ | sliceX"},
        {command: "be", options: "locate_portal | locate_portal_frame | tpnext"},
        {command: "bookshelf", options: "font | hand"},
        {command: "create", options: "rainbowDebug | overlay | trains | fixLighting | highlight | killTrain | passenger | coupling | config | ponder | clone | glue | util | u"},
        {command: "c", options: "rainbowDebug | overlay | trains | fixLighting | highlight | killTrain | passenger | coupling | config | ponder | clone | glue | util | u"},
        {command: "et [config | reload | help]"},
        {command: "enchanttweaker [config | reload | help]"},
        {command: "fallingtree toggle"},
        {command: "waypoint", options: "create | delete"},
        {command: "wp -> waypoint"},
        {command: "open-patchouli-book <targets> <book> [<entry>]"},
        {command: "polymorph conflicts"},
        {command: "porting_lib quickexport <path>"},
        {command: "puffish_skills", options: "category | skills | points | experience"},
        {command: "timecontrol", options: "day_length_minutes | night_length_minutes | sync_to_system_time | sync_to_system_time_rate"},
        {command: "tb", options: "access | restore | remove | clear | unpack"},
        {command: "ae2", options: "reloadconfig | chunklogger | spatial | channelmode | tickmonitor"}
      ],
      newRcon: {
        host: "",
        port: "",
        password: "",
      },
      rconMessage: "",
      serverInfoLoaded: false,
      serverData: {},
      version: "",
      playerMax: 0,
      players: [],
      socket: null
    }
  },
  mounted() {
    let tabItem = localStorage.getItem("minecraft_tab");
    if (tabItem) {
      this.tab = parseInt(tabItem);
    } else {
      localStorage.setItem("minecraft_tab", this.tab);
    }

    this.socketLogic();

    this.socket.emit('mc ping', {
      server: this.serverHost,
      port: this.serverPort
    });

    if (this.canAdministrate) {
      DataService.getConnections().then(data => {
        this.rconConnections = data.filter(x => x.type === "rcon");
      });
    }
  },
  methods: {
    socketLogic(){
      this.socket = io(`${process.env.VUE_APP_BACKEND_URL}:${process.env.VUE_APP_BACKEND_PORT}`);
      if(this.socket){
        this.socket.on('rcon authed', () => {
          this.rconEnabled = true;
          this.rconMessages.push({timestamp: new Date(Date.now()), message: `Successfully authenticated`});
          this.rconConnected = true;
        });

        this.socket.on('rcon response', res => {
          this.rconMessages.push({timestamp: new Date(Date.now()), message: `Response: '${res.message}'`});
          this.rconMessage = "";
        });

        this.socket.on('rcon server', res => {
          this.rconMessages.push({timestamp: new Date(Date.now()), message: `Server: '${res.message}'`});
        });

        this.socket.on('rcon end', () => {
          this.rconMessages.push({timestamp: new Date(Date.now()), message: `Connection was closed`});
          this.rconConnected = false;
        });

        this.socket.on('rcon error', error => {
          this.rconEnabled = false;

          this.rconMessages.push({timestamp: new Date(Date.now()), message: `Error occurred: '${error.error.code}'`});
          MessageService.error(`Could not establish rcon connection. Code: '${error.error.code}'`);
          this.rconConnected = false;
        });

        this.socket.on('mc ping result', data => {
          this.serverInfoLoaded = true;
          this.version = data.version.name;
          this.playerMax = data.players.max;
          if (data.players.online > 0) {
            this.players = data.players.sample;
          }
        });

        this.socket.on('mc ping error', error => {
          this.serverInfoLoaded = false;
          MessageService.error(`Error on ping to Minecraft server. Code: '${error.code}'`);
        });
      } else {
        MessageService.error("Socket connection could not be established");
      }
    },
    startRcon() {
      if(this.socket){
        let host; let port; let password;
        if(this.rconConnection != null){
          this.socket.emit("rcon close");
          this.serverInfoLoaded = false;

          host = this.rconConnection.host;
          port = this.rconConnection.port;
          password = this.rconConnection.password;
        } else {
          host = this.newRcon.host;
          port = this.newRcon.port;
          password = this.newRcon.password;
        }

        this.rconMessages.push({
          timestamp: new Date(Date.now()),
          message: `Start new rcon session - Host: '${host}' | Port: '${port}'`
        });
        this.socket.emit('rcon auth', {
          host: host,
          port: port,
          password: password,
        });
      } else {
        MessageService.error("Socket connection could not be established");
      }
    },
    stopRcon() {
      this.socket.emit("rcon close");
      this.rconEnabled = false;
    },
    sendRconMessage() {
      this.socket.emit('rcon send', {
        cmd: this.rconMessage
      });
    },
    saveLastActiveTab() {
      localStorage.setItem("minecraft_tab", this.tab);
    },
  },
  computed: {
    enableRconStart() {
      return this.rconConnection != null
          || (this.newRcon.host.length > 0 && this.newRcon.serverPort.length > 0 && this.newRcon.port.length > 0 && this.newRcon.password.length > 0);
    },
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    canAdministrate() {
      return this.currentUser && this.currentUser.rights.includes("ADMINISTRATE_MINECRAFT");
    }
  },
  watch: {
    rconMessages: function (){
      this.$nextTick(function() {
        let container = this.$refs["rconConsole"];
        if(container){
          container.scrollTop = container.scrollHeight;
        }
      });
    }
  }
}
</script>

<style scoped>
.console {
  height: 300px;
  overflow: auto;
  background: #272727;
}

.rconHelpEntry{
  word-break: break-all;
  white-space: normal;
}
</style>
