import MessageService from "@/services/message.service";

import axios from 'axios';
const API_URL = '/service/users';

class UserService {
    getAll(includeRoles, includeCharacters) {
        return axios.post(API_URL, {
            includeRoles: includeRoles,
            includeCharacters: includeCharacters
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to get users. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    updateUserRoles(userId, roles) {
        return axios.put(`${API_URL}/roles/${userId}`, {roles: roles}).then(response => {
            MessageService.success("User successfully updated");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to update user roles. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    changeActivationState(userId, isActive) {
        return axios.post(`${API_URL}/activation-status`, {
            id: userId,
            active: isActive
        }, {}).then(() => {
            MessageService.success("User successfully updated");
            return true;
        }).catch(error => {
            MessageService.error(`Failed to change activation state of user user. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    updatePassword(id, oldPassword, newPassword) {
        return axios.put(`${API_URL}/password/${id}`, {
            oldPassword: oldPassword,
            newPassword: newPassword
        }).then(response => {
            MessageService.success("User successfully updated");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to activate user. Error: '${error.response.statusText}'`);
            return [];
        });
    }


    updateMail(id, email) {
        return axios.put(`${API_URL}/email/${id}`, {
            email: email
        }).then(response => {
            MessageService.success("User successfully updated");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to activate user. Error: '${error.response.statusText}'`);
            return [];
        });
    }

    deleteUser(user) {
        return axios.delete(`${API_URL}/${user.id}`, {}).then(() => {
            MessageService.success("User successfully deleted");
            return true;
        }).catch(error => {
            MessageService.error(`Failed to delete user. Error: '${error.response.statusText}'`);
            return false;
        });
    }

    updateUserCharacters(userId, authorizationCode, redirectUrl) {
        return axios.post(`${API_URL}/update-characters`, {
            userId: userId,
            authorizationCode: authorizationCode,
            redirectUrl: redirectUrl
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to update user characters. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    markCharacterAsMain(userId, characterId) {
        return axios.post(`${API_URL}/main-character`, {
            userId: userId,
            characterId: characterId
        }).then(response => {
            MessageService.success("Character successfully marked as main for user.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to mark character as main for user. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    createTemporaryUser(name, characterList, mainCharacter) {
        return axios.post(`${API_URL}/temporary`, {
            name: name,
            characters: characterList,
            mainCharacter: mainCharacter
        }).then(response => {
            return response.data;
        }).catch(error => {
            MessageService.error(`Failed to create temporary user. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    sendPasswordReset(username, locale) {
        return axios.post(`${API_URL}/reset-password/send`, {
            username: username,
            locale: locale
        }).then(response => {
            MessageService.success("Successfully send password reset request if the user is valid.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Could not send password reset request. Error: '${error.response.statusText}'`);
            return null;
        });
    }

    resolvePasswordReset(token, newPassword) {
        return axios.post(`${API_URL}/resolve-reset/resolve/${token}`, {
            newPassword: newPassword
        }).then(response => {
            MessageService.success("Successfully updated password for user.");
            return response.data;
        }).catch(error => {
            MessageService.error(`Could not update password. Error: '${error.response.statusText}'`);
            return null;
        });
    }
}

export default new UserService();
