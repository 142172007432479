<template>
  <v-dialog v-model="isOpen" max-width="400px">
    <v-card>
      <v-toolbar flat dense color="primary">
        <v-icon class="mr-1">mdi-clipboard-check</v-icon>
        {{$t('general.misc.confirmation')}}
      </v-toolbar>
      <v-card-text class="pa-3">
        <h4>{{ question }}</h4>
      </v-card-text>
      <v-card-actions class="pa-3 justify-end">
        <v-btn class="mr-2" @click="isOpen = false">
          {{ $t('general.actions.cancel') }}
        </v-btn>
        <v-btn class="mr-2" color="primary" @click="confirmationHandler">
          {{ $t('general.actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: ["identifier", "question", "confirmationHandler"],
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    if(this.identifier){
      this.$root.$on(`${this.identifier}_confirmationOpen`, isOpen => {
        this.isOpen = isOpen;
      });
    } else {
      this.$root.$on('confirmationOpen', isOpen => {
        this.isOpen = isOpen;
      });
    }
  },
  beforeDestroy() {
    if(this.identifier){
      this.$root.$off(`${this.identifier}confirmationClose`, isOpen => {
        this.isOpen = isOpen;
      });
    } else {
      this.$root.$off('confirmationClose');
    }
  },
}
</script>

<style scoped>
</style>